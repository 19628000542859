<template>
  <div
    :class="
      user && user.type.toLowerCase() == 'management' ? 'Admin-Scope' : 'Scope'
    "
  >
    <Loading v-if="!(systemParameters && customFilesLoaded)" />
    <!-- <Header /> -->
    <div v-else>
      <ICAPHeader v-if="false" />
      <component
        v-else
        @toggleSidebar="toggleSidebar"
        :is="headerComponent"
        :collapsed="sidebarIsCollapsed"
        @openPDF="toggleModal"
      />
      <transition name="fade">
        <AdminSidebar v-if="isAdmin" :collapsed="sidebarIsCollapsed">
          <router-view class="main admin-main" />
        </AdminSidebar>
        <router-view v-if="!isAdmin" class="main student-main" />
      </transition>
      <vue-progress-bar></vue-progress-bar>

      <a
        v-if="showWhatsApp"
        class="whatsup-button"
        :href="
          'https://api.whatsapp.com/send?phone=' +
          systemParameters.TenantInfo.WhatsAppNumbers[0].replace('+', '')
        "
        target="_blank"
      >
        <i
          class="fab fa-whatsapp"
          style="margin-top: 16px; font-size: 2rem"
        ></i>
        <span class="whatsapp-btn-text hide-text">Whatsapp Us</span>
      </a>

      <Footer v-if="!isAdmin && !isProductContentDashboardPage" />
      <b-modal
        :visible="showDocumentModal"
        centered
        hide-footer
        hide-header
        @change="closeModal()"
        size="xl"
        id="view-study-material-modal"
        content-class="view-study-material-modal"
        body-class="modal-body"
        modal-class="custom-modal"
      >
        <div class="body-container">
          <button @click="closeModal()" class="close-button" role="button">
            <img src="@/assets/images/cancel-icon.svg" />
          </button>
          <embed
            v-if="type == 'pdf'"
            :src="`${iframeLink}#toolbar=0`"
            frameborder="0"
            width="100%"
            height="100%"
          />
        </div>
      </b-modal>
      <FormModal
        v-if="isShowForm && processingFormID"
        :visible="isShowForm"
        :formID="processingFormID"
        @closeModalState="closeFormModal"
      />
      <UploadRecordedLectureInBackgroundModal
        v-if="isAdmin"
      ></UploadRecordedLectureInBackgroundModal>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Header from "./components/Layout/Header.vue";
import AdminHeader from "./components/Admin/Layout/AdminHeader.vue";
import AdminSidebar from "./components/Admin/Layout/AdminSidebar.vue";
import Footer from "./components/Layout/Footer.vue";
import Loading from "./components/Layout/Loading.vue";
import FormModal from "./components/Form/FormModal.vue";
import UploadRecordedLectureInBackgroundModal from "./components/Admin/Content/UploadRecordedLectureInBackgroundModal.vue";
import ICAPHeader from "./custom/icap/ICAPHeader.vue";
import jQuery from "jquery";
import Vue from "vue";

export default {
  name: "App",
  data() {
    return {
      sidebarIsCollapsed: false,
      showDocumentModal: false,
      iframeLink: null,
      type: null,
      isShowForm: false,
      processingFormID: null,
    };
  },
  computed: {
    ...mapState("appState", [
      "loading",
      "systemParameters",
      "amIOnline",
      "customFilesLoaded",
    ]),
    ...mapState("user", ["user"]),
    headerComponent() {
      if (this.$route.meta.isAdmin) {
        return AdminHeader;
      } else if (this.user) {
        if (this.user.type && this.user.type.toLowerCase() != "student") {
          console.log("user is not student");
          return AdminHeader;
        } else return Header;
      } else return Header;
    },
    isAdmin() {
      return this.$route.meta.isAdmin;
    },
    isProductContentDashboardPage() {
      return this.$route.meta.isProductContentDashboardPage;
    },
    showWhatsApp() {
      return (
        this.systemParameters &&
        this.systemParameters.TenantInfo &&
        this.systemParameters.TenantInfo.WhatsAppButton == true &&
        this.systemParameters.TenantInfo.WhatsAppNumbers &&
        this.systemParameters.TenantInfo.WhatsAppNumbers.length > 0 &&
        !this.user
      );
    },
  },
  methods: {
    ...mapMutations("appState", ["setOnlineStatus"]),
    toggleSidebar() {
      this.sidebarIsCollapsed = !this.sidebarIsCollapsed;
    },
    toggleModal(link) {
      this.showDocumentModal = true;
      this.iframeLink = link;
      this.type = "pdf";
    },
    closeModal() {
      this.showDocumentModal = false;
      this.iframeLink = null;
    },
    connect() {
      var url = "https://gocbe-lms-files.b-cdn.net/1x1.png";
      jQuery.ajax({
        url: url,
        type: "GET",
        timeout: 10000,
        success: function () {
          this.setOnlineStatus(true);
        }.bind(this),
        error: function () {
          this.setOnlineStatus(false);
        }.bind(this),
      });
    },

    checkForm() {
      const user = JSON.parse(localStorage.getItem("user"));
      if (
        user &&
        user.type.toLowerCase() == "student" &&
        localStorage.getItem("ProcessingFormID")
      ) {
        this.isShowForm = true;
        this.processingFormID = localStorage.getItem("ProcessingFormID");
      }
    },
    closeFormModal() {
      this.isShowForm = false;
      this.processingFormID = null;
    },
  },
  components: {
    Header,
    Footer,
    Loading,
    AdminHeader,
    AdminSidebar,
    FormModal,
    UploadRecordedLectureInBackgroundModal,
    ICAPHeader,
  },
  created() {
    //const Bowser = require("bowser"); // CommonJS
    //import Bowser from "bowser";
    // var showBrowserUpdateMessage = false;
    const browser = bowser.getParser(window.navigator.userAgent);
    const isValidBrowser = browser.satisfies({
      electron: ">=19",
      chrome: ">=87",
      firefox: ">=87",
      "microsoft edge": ">=87",
      safari: ">=12",
    });

    if (!isValidBrowser) {
      // alert("please update browser");
      $("body").prepend(
        `<div style="width: 100%; height: auto; padding: 8px; background-color: orange; color: black; font-weight: bold; text-align: center;">Your browser is outdated or not completely compatible with this portal. Please update to the latest version of Google Chrome for smooth working of all features.</div>`
      );
    }
    Vue.prototype.$Modal = this.$bvModal;
    setInterval(
      function () {
        this.connect();
      }.bind(this),
      20000
    );
    this.checkForm();
  },
  mounted() {
    this.$Progress.start();

    this.$nextTick(() => {
      setTimeout(() => {
        var svg = document.querySelectorAll(".color-change-svg");
        // console.log("svg", svg);
        for (var j = 0; j < svg.length; j++) {
          var data = svg[j].contentDocument;
          if (data) {
            var primary = data.querySelectorAll(".primary-color");
            var secondary = data.querySelectorAll(".secondary-color");
            // console.log(primary);
            for (var i = 0; i < primary.length; i++) {
              primary[i].attributes["fill"].value =
                this.systemParameters.Theme.PrimaryColor;
            }
            for (var i = 0; i < secondary.length; i++) {
              secondary[i].attributes["fill"].value =
                this.systemParameters.Theme.SecondaryColor;
            }
          }
        }
      }, 300);
    });
  },
  watch: {
    user: function () {
      console.log("user watched");
      this.showWhatsappButton();
    },
    $route() {
      window.dispatchEvent(new Event("popstate"));
      setTimeout(() => (document.querySelector("body").scrollTop = 0), 600);
      if (this.systemParameters.Theme) {
        this.$nextTick(() => {
          setTimeout(() => {
            var svg = document.querySelectorAll(".color-change-svg");
            // console.log("svg", svg);
            for (var j = 0; j < svg.length; j++) {
              var data = svg[j].contentDocument;
              if (data) {
                var primary = data.querySelectorAll(".primary-color");
                var secondary = data.querySelectorAll(".secondary-color");
                // console.log(primary);
                for (var i = 0; i < primary.length; i++) {
                  primary[i].attributes["fill"].value =
                    this.systemParameters.Theme.PrimaryColor;
                }
                for (var i = 0; i < secondary.length; i++) {
                  secondary[i].attributes["fill"].value =
                    this.systemParameters.Theme.SecondaryColor;
                }
              }
            }
          }, 300);
        });
      }
      // console.log("route changes", this.ZoomMtg);
    },
    systemParameters: function () {
      var root = document.querySelector(":root");
      if (this.systemParameters.Theme) {
        root.style.setProperty(
          "--primary-color",
         this.systemParameters.Theme.PrimaryColor
        );
        root.style.setProperty(
          "--secondary-color",
          this.systemParameters.Theme.SecondaryColor
        );
        // console.log(getComputedStyle(root).getPropertyValue("--primary-color"));
        // styles.setProperty("--primary-color", "#b73439");
        this.$nextTick(() => {
          setTimeout(() => {
            var svg = document.querySelectorAll(".color-change-svg");
            // console.log("svg", svg);
            for (var j = 0; j < svg.length; j++) {
              var data = svg[j].contentDocument;
              if (data) {
                var primary = data.querySelectorAll(".primary-color");
                var secondary = data.querySelectorAll(".secondary-color");
                // console.log(primary);
                for (var i = 0; i < primary.length; i++) {
                  primary[i].attributes["fill"].value =
                    this.systemParameters.Theme.PrimaryColor;
                }
                for (var i = 0; i < secondary.length; i++) {
                  secondary[i].attributes["fill"].value =
                    this.systemParameters.Theme.SecondaryColor;
                }
              }
            }
          }, 300);
        });
      } else {
        root.style.setProperty("--primary-color", "#CB222B");
        root.style.setProperty("--secondary-color", "#000000");
      }

      var link = document.querySelector("link[rel~='icon']");
      if (this.systemParameters.FaviconPath) {
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = this.systemParameters.FaviconPath;
      } else {
        link.href = require("@/assets/images/gocbe_logo_small.png");
      }
    },
    amIOnline: function () {
      if (!this.amIOnline) {
        this.$toasted.error(
          "Looks like you are not connected to the internet. Please check your connection and try again.",
          {
            icon: "exclamation-triangle",
            theme: "outline",
            position: "bottom-right",
            singleton: true,
            // action: {
            // 	text: "RETRY",
            // 	onClick: (e, toastObject) => {
            // 		toastObject.goAway(0);
            // 		window.location.reload();
            // 	},
            // },
          }
        );
        return;
      }
      this.$toasted.clear();
      this.$toasted.success("Internet connection restored!", {
        icon: "check",
        theme: "outline",
        position: "bottom-right",
        duration: 3000,
        // singleton: true,
      });
    },
  },
};
</script>

<style>
.Admin-Scope .student-main {
  padding-top: 0px !important;
}

/*.student-main {
  padding-top: var(--header-height) !important;
} */


.whatsapp-btn-text {
  font-size: 1.25rem;
  vertical-align: text-bottom;
  margin-left: 0.5rem;
}

#zmmtg-root {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out !important;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0 !important;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1 !important;
}

.color-change-svg {
  pointer-events: none;
}

.whatsup-button:hover {
  color: white;
  text-decoration: none;
}

.whatsup-button {
  position: fixed;
  width: 200px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

@media screen and (max-width: 520px) {
  .hide-text {
    display: none;
  }

  .whatsup-button {
    width: 63px;
  }

  .fa-whatsapp {
    margin-top: 18px;
  }
}
</style>
