<template>
  <b-modal
    id="edit-content-modal"
    @change="$emit('updateModalState', false)"
    :dialog-class="'animate-modal-dialog '+(isExpanded?'expanded':'shrink')"
    :visible="visible"
    hide-header
    centered
    content-class="edit-content-modal"
    size="xl"
    body-class="modal-body"
    hide-footer
    modal-class="custom-modal"
    no-close-on-backdrop
  >
    <div class="body-container">
     
      <div class="right-close-panel">
				<button @click="()=>{ isExpanded=!isExpanded; }" class="close-button expanded-btn mr-3" role="button">
							<i v-if="!isExpanded" class="fas fa-expand-alt"></i>
							<i v-if="isExpanded" class="fas fa-compress-alt"></i>
				</button>
        <button @click="closeModal" class="close-button" role="button">
        <img src="@/assets/images/cancel-icon.svg" />
      </button>
			</div>
			
      <h1 class="edit-content-title">
        {{ isEditMode ? "Edit" : "Create" }} Question
      </h1>
      <div :class="['info-container mb-3']">
        <div class="inline-group">
          <div class="col">
            <label class="main-label mb-0" for="title">Title (required)</label>
            <input placeholder="Enter question title here..." class="form-input"  name="title" id="title"
						v-model="question.title" required>
            
          </div>
        </div>

        <div class="inline-group">
          <div class="col">
            <label class="main-label" for="title"
              >Question Text (required)</label
            >
            <QuillRichTextEditor
              :text="question.questionText"
              :isInline="false"
              :editorID="'editor-questiontext'"
              placeholder="Enter question text here..."
              v-model="question.questionText"
            />
          </div>
        </div>
        <div>
          <div class="inline-group">
            <div class="col">
              <label class="main-label" for="marks">Marks (required)</label>
              <div>
                <input
                  class="form-input"
                  style="display: inline"
                  type="number"
                  name="marks"
                  id="marks"
                  placeholder="Enter marks here"
                  min="0"
                  v-model="question.marks"
                />
              </div>
            </div>

            <div class="col">
              <label class="main-label" for="questionType"
                >Question Type (required)</label
              >
              <div>
                <v-select
                  class="form-input pt-0"
                  v-model="question.type"
                  :reduce="(option) => option.value"
                  :options="[
                    { label: 'Textbox', value: 'textbox' },
                    { label: 'Select (dropdown)', value: 'select' },
                    { label: 'Radio (single-select)', value: 'radio' },
                    { label: 'Checkbox (multi-select)', value: 'checkbox' },
                  ]"
                  placeholder="Select question type"
                  :appendToBody="true"
                  :clearable="true"
                >
                </v-select>
              </div>
            </div>
          </div>

          <div class="inline-group">
            <div class="col">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="isRandom"
                  v-model="question.randomizeAnswerOptions"
                />
                <label class="form-check-label" for="isRandom"
                  >Randomize Answer Options
                </label>
              </div>
            </div>
          </div>

          <div v-if="question.type">
            <h3 class="main-label" for="marks">Answer Options</h3>
          </div>

          <div v-if="question.type">
            <button class="add-btn" role="button" @click="addOption()">
              <img
                src="@/assets/images/add-border-icon.svg"
                class="add-btn-icon"
              />
              Add Option
            </button>
          </div>
          <div v-if="question.type" class="mt-3">
            <RadioButtonQuestion
              v-if="
                question.type.toLowerCase() == 'radio' ||
                question.type.toLowerCase() == 'select'
              "
              :answerOptions="question.answerOptions"
              @removeOpt="removeOption"
              @setAnswer="setAnswer"
              @updateAnswerOptions="updateAnswerOptions"
            ></RadioButtonQuestion>
            <CheckboxButtonQuestion
              v-if="question.type.toLowerCase() == 'checkbox'"
              :answerOptions="question.answerOptions"
              @removeOpt="removeOption"
              @setAnswer="setAnswer"
            ></CheckboxButtonQuestion>
            <TextboxButtonQuestion
              v-if="question.type.toLowerCase() == 'textbox'"
              :answerOptions="question.answerOptions"
              @removeOpt="removeOption"
              @setAnswer="setAnswer"
            ></TextboxButtonQuestion>
          </div>
        </div>
      </div>

      <!-- <div>{{ question.answerOptions }}</div> -->

      <div class="step-btn-container">
        <button
          @click="create"
          role="button"
          class="step-btn"
          :disabled="disableSubmitBtn || loading"
        >
          <b-spinner v-if="loading" small></b-spinner>
          {{ btnText }}
          <img src="@/assets/images/arrow-right.svg" />
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
// import { VueEditor } from "vue2-editor";
import { getFileType } from "../../../helpers/Converters";
import RadioButtonQuestion from "./QuizCreationQuestionTypes/RadioButtonQuestion.vue";
import CheckboxButtonQuestion from "./QuizCreationQuestionTypes/CheckboxButtonQuestion.vue";
import TextboxButtonQuestion from "./QuizCreationQuestionTypes/TextboxButtonQuestion.vue";
import QuillRichTextEditor from "../../Editors/QuillRichTextEditor.vue";

export default {
  name: "QuizQuestionsModal",
  props: {
    visible: Boolean,
    item: Object,
    mode: String,
    questionData: Object,
  },
  data() {
    return {
      question: {},
      loading: false,
      isExpanded: false
    };
  },
  methods: {
    ...mapActions("content", ["getContentById", "uploadContentFile"]),
    ...mapActions("quiz", ["createQuestion", "updateQuestion"]),
    async create() {
      
          let correctAnswersExists = this.question && this.question.answerOptions &&
          this.question.answerOptions.length > 0 &&
          this.question.answerOptions.some((answer) => answer?.isCorrect == true );
          
          if(this.question.type && !correctAnswersExists)
          {
            this.$toasted.error("There must be atleast 1 correct answer", {
              icon: "exclamation-triangle",
              theme: "outline",
              position: "bottom-center",
              singleton: true,
            });
            return;
          }

      this.question.marks = parseInt(this.question.marks);

      this.loading = true;
      this.question.marks = parseInt(this.question.marks);

      if (this.isEditMode) {
        this.question.id = this.questionData.id;
        await this.updateQuestion(this.question).then((res) => {
          if (res && res.status.toLowerCase() == "success") {
            this.$emit("getData");
            this.closeModal();
          } else if (res && res.status.toLowerCase() == "error") {
            this.$toasted.error(res.error?.message, {
              icon: "exclamation-triangle",
              theme: "outline",
              position: "bottom-center",
              singleton: true,
            });
          }
        });
      } else if (!this.isEditMode) {
        await this.createQuestion(this.question)
          .then((res) => {
            if (res && res.status.toLowerCase() == "success") {
              this.$toasted.success("Question Created Successfully!", {
                icon: "check",
                theme: "outline",
                position: "bottom-right",
                duration: 3000,
                // singleton: true,
              });
              this.closeModal();
            } else if (res && res.status.toLowerCase() == "error") {
              this.$toasted.error(res.error?.message, {
                icon: "exclamation-triangle",
                theme: "outline",
                position: "bottom-center",
                singleton: true,
              });
            }
          })
          .catch((err) => {
            if (err) {
              this.$toasted.error(err?.message, {
                icon: "exclamation-triangle",
                theme: "outline",
                position: "bottom-center",
                singleton: true,
              });
            }
          });
      }

      this.loading = false;
    },

    resizeTextarea(event) {
      const textarea = event.target;
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    },

    closeModal() {
      this.$emit("updateModalState", false);
    },

    addOption() {
      if (this.question.type == "textbox") {
        this.question.answerOptions.push({ isCorrect: true, option: "" });
        return;
      }
      this.question.answerOptions.push({ isCorrect: false, option: "" });
    },

    removeOption(index) {
      if (index > -1) {
        this.question.answerOptions.splice(index, 1);
      }
    },

    setAnswer(options) {
      this.question.answerOptions.map((x) => {
        x.isCorrect = false;
      });

      for (let i = 0; i < options.length; i++) {
        const index = options[i].index;
        this.question.answerOptions[index].isCorrect = true;
        this.question.answerOptions[index].identifier = options[i].identifier
          ? options[i].identifier
          : null;
      }
    },

    updateAnswerOptions(options) {
      this.question.answerOptions = options;
    },
  },
  components: {
    RadioButtonQuestion,
    CheckboxButtonQuestion,
    TextboxButtonQuestion,
    QuillRichTextEditor,
  },
  created() {
    if (this.item && this.item.id) {
      this.question.contentID = this.item.id;
    }
  },
  computed: {
    btnText: function () {
      return this.mode?.toLowerCase() == "edit" ? "Edit" : "Add";
    },

    isEditMode: function () {
      return this.mode?.toLowerCase() == "edit" ? true : false;
    },

    disableSubmitBtn: function () {
      if (
        !this.question ||
        !this.question.title ||
        !this.question.questionText ||
        !this.question.contentID ||
        !this.question.type ||
        !this.question.marks ||
        this.question.marks < 1 ||
        (this.question.type != "checkbox" &&
          this.question.type != "radio" &&
          this.question.type != "select" &&
          this.question.type != "textbox")
      ) {
        return true;
      }

      return false;
    },

    // questionText: function () {
    //   return this.quillEditor?.getText();
    // },
  },
  watch: {
    visible: function () {
      if (this.visible && !this.isEditMode) {
        this.question = {
          title: null,
          questionText: null,
          contentID: null,
          answerOptions: [],
          extraProperties: null,
          type: null,
          marks: 1,
          randomizeAnswerOptions: true,
        };
        this.question.contentID = this.item?.id;
        // setTimeout(this.initQuillEditorForQuestionText, 500);
        // setTimeout(this.initQuillEditorForQuestionTitle, 500);
      } else if (this.visible && this.isEditMode) {
        this.question = {};
        this.question.title = this.questionData.title;
        this.question.questionText = this.questionData.questionText;
        this.question.contentID = this.questionData.contentID;
        this.question.extraProperties = this.questionData.extraProperties;
        this.question.answerOptions = this.questionData.options;
        this.question.type = this.questionData.type;
        this.question.marks = this.questionData.marks;
        this.question.randomizeAnswerOptions =
          this.questionData.randomizeAnswerOptions;

        this.question.answerOptions.forEach((x, index) => {
          x.index = index;
        });
      }
    },

    "question.type": function () {
      this.question.answerOptions = this.question.answerOptions
        ? this.question.answerOptions
        : [];

      if (
        this.question.type &&
        (!this.question.answerOptions ||
          this.question.answerOptions.length <= 0)
      ) {
        if (
          this.question.type == "radio" ||
          this.question.type == "select" ||
          this.question.type == "checkbox"
        ) {
          this.question.answerOptions = [
            { isCorrect: false, option: "" },
            { isCorrect: false, option: "" },
          ];
        } else if (this.question.type == "textbox") {
          this.question.answerOptions = [{ isCorrect: true, option: "" }];
        }
      }
    },
  },
};
</script>

<style>
.exam-questions-modal {
  min-height: 45.375rem;
  /* width: 90vw; */
  position: relative;
}

/* .custom-modal .modal-dialog {
	max-width: 70vw;
} */
.load-head {
  position: absolute;
}

.exam-questions-modal .modal-body {
  padding: 0 !important;
  font-size: 1rem;
  min-height: 43rem;
}

.exam-questions-modal .modal-body .modal-iframe {
  width: 100%;
  position: absolute;
  height: 100%;
}

.exam-questions-modal .body-container {
  display: flex;
  flex-direction: column;
  padding: 2.5rem 2.5rem 1.5rem;
  position: absolute;
  z-index: 999999;
  right: 0;
}

.exam-questions-modal .body-container .header {
  display: flex;
  justify-content: space-between;
  margin-right: 30px;
}

.create-meeting-modal .exam-questions-title {
  font-weight: 450;
  font-size: 2rem;
  line-height: 0.75;
  margin: 0.5rem 0 2rem;
  color: #000000;
}

#edit-content-modal .expanded
{
	max-width: 96%;
	max-height: 96%;

}

#edit-content-modal .expanded .edit-content-modal
{
	height: 90vh;
}

</style>

<style scoped>
.info-container {
  max-height: 470px;
  overflow: scroll;
}

	.right-close-panel
	{
		position: absolute;
    	top: 2.5rem;
    	right: 2rem;
		display: flex;
	}

	.right-close-panel .close-button
	{
		position: initial;
	}
	
	.expanded-btn{
		color: black;
	    font-size: 24px;
	    margin-right: 15px;
		padding-top: 4px;
	}
</style>

