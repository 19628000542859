<template>
	<div class="eb-lc-box" >
		<div class="eb-lc-head d-flex justify-content-between">
			<div class="eb-lc-label label-live">
				<img src="/img/signal-red-icon.8d2f387a.svg">
				{{ badgeText }}
			</div>
			<div class="eb-lc-time">
				<i class="far fa-clock me-1"></i>{{ time }} 
				<span data-v-03a82b79="" v-if="isDisabled"><br data-v-03a82b79=""> {{ date }}</span>
			</div>
		</div>
		<div class="eb-lc-content d-flex justify-content-between align-items-center">
			<div class="eb-lc-titles pe-2">
				<h5 data-v-03a82b79="">{{ title }}</h5>
				<h6 data-v-03a82b79="">{{ subTitle }}</h6>
			</div>
			<div class="eb-lc-action">
				<button role="button" @click="handleContentClick" :class="'btn btn-primary '+(isDisabled?'disabled':'')">{{ ctaText }}</button>
			</div>
		</div>
	</div>
	
</template>

<script>
import { mapState } from "vuex";
import { getCookie } from "../../helpers/CookieHelpers";
import {
	getTimeIn12Hours,
	toShortDateString,
} from "../../helpers/FormatHelpers";

export default {
	name: "ReminderListItem",
	data() {
		return {
			id: this.reminderItem.id,
			productVariantId: this.reminderItem.productVariantID,
			type: this.reminderItem.type.toLowerCase(),
			title: this.reminderItem.title,
			subTitle: this.reminderItem.productVariantTitle,
			//time: " 1:00 PM to 5:00 PM",
		};
	},
	props: { reminderItem: Object },
	computed: {
		...mapState("user", ["user"]),
		hasContentStarted() {
			return (
				new Date(
					new Date(this.reminderItem.availableFrom).getTime() - 5 * 60 * 1000
				) < new Date()
			);
		},
		// check if meeting has ended and 15 minutes have passed
		hasContentEnded() {
			return (
				new Date() >
				new Date(
					new Date(this.reminderItem.availableTo).setTime(
						new Date(this.reminderItem.availableTo).getTime() + 900000
					)
				)
			);
		},
		badgeText() {
			switch (this.type) {
				case "liveclass":
					return "Live Class";
				case "recordedvideos":
					return "Recorded Video";
				case "assignment":
					return "Assignment";
				case "test":
					return "Test";
			}
		},
		ctaText() {
			switch (this.type) {
				case "liveclass":
					return this.isDisabled && this.hasContentEnded
						? "Class ended"
						: "Join Now";
				case "recordedvideos":
					return "Watch Now";
				case "assignment":
					return "Submit";
				case "test":
					return "Submit";
			}
		},
		contentLink() {
			switch (this.type) {
				case "recordedvideos":
					return {
						path:
							"/content/video/" +
							this.title
								.toLowerCase()
								.replace(/[^a-zA-Z0-9 ]/g, "")
								.split(" ")
								.join("-") +
							"-" +
							this.id +
							"_" +
							this.productVariantId,
						// query: { productVariantId: this.productVariantId },
					};
				case "liveclass":
					return {
						path:
							"/content/liveclass/" +
							this.title
								.toLowerCase()
								.replace(/[^a-zA-Z0-9 ]/g, "")
								.split(" ")
								.join("-") +
							"-" +
							this.id +
							"_" +
							this.productVariantId,
						// query: { productVariantId: this.productVariantId },
					};
				case "assignment":
					return {
						path:
							"/content/assignment/" +
							this.title
								.toLowerCase()
								.replace(/[^a-zA-Z0-9 ]/g, "")
								.split(" ")
								.join("-") +
							"-" +
							this.id +
							"_" +
							this.productVariantId,
						// query: { productVariantId: this.productVariantId },
					};
			}
		},
		time() {
			return (
				getTimeIn12Hours(this.reminderItem.availableFrom) +
				" - " +
				getTimeIn12Hours(this.reminderItem.availableTo)
			);
		},
		date() {
			return toShortDateString(this.reminderItem.availableFrom);
		},
		isDisabled() {
			// console.log(new Date(this.reminderItem.availableFrom).getDate());
			if (!this.hasContentStarted || this.hasContentEnded) return true;
			else return false;
		},
	},
	methods: {
		// ...mapActions("content", ["getContentById"]),
		handleContentClick() {
			switch (this.type) {
				case "liveclass":
					var joinURLFromCookies = getCookie(
						`${this.reminderItem.id}_${this.user.id}`
					);
					if (joinURLFromCookies) {
						window.location.href = joinURLFromCookies;
						return;
					}
					this.$router.push(this.contentLink);
					return;
				default:
					this.$router.push(this.contentLink);
					return;
			}
		},
	},
};
</script>

<style scoped></style>
