<template>
	<div>
	<aside :class="`sidebar_navigation ${upHere ? 'sidebar_opened' : ''}`">
		<!-- @mouseover="upHere = true"
		@mouseleave="upHere = false"
		> -->
		<b-tooltip target="home-link" triggers="hover" placement="right">Home</b-tooltip>
		<nav class="sidebar_navigation_container">
			<ul class="sidebar_nav nav flex-column" id="sidebar_nav">
				<li id="home-link" class="nav-item sidebar-nav-item" @click="navigateToDashboard">
					<Link href="" class="nav_link nav-link">
						<i class="fas fa-home nav_icon"></i>
						<span class="nav_title">Home</span>
					</Link>
				</li>
				<!-- <li v-if="this.$route.query.categoryId" id="back-link" class="nav-item sidebar-nav-item" @click="navigateToCourseList">
					<Link href="" class="nav_link nav-link">
					<i class="fas fa-arrow-left nav_icon"></i>
					<span class="nav_title">Back</span>
					</Link>
				</li> -->
				<li v-if="getThemeTabs && getThemeTabs.length > 0" v-for="tab in getThemeTabs" :key="tab.name"
					:id="`tab-link-${tab.name}`"
					:class="`nav-item ${($route.params.page && $route.params.page.toLowerCase() == tab.name) ? 'active' : ''}`"
					@click="navigate(tab.name)">
					<Link href="" class="nav_link nav-link">
						<i :class='tabIconClass(tab)'></i>
						<span class="nav_title">{{ tab.label }}</span>
					</Link>
					<b-tooltip :target="`tab-link-${tab.name}`" triggers="hover" :placement="'auto'">{{ tab.label }}</b-tooltip>
				</li>
			</ul>
		</nav>
	</aside>

	<!-- Mobile Bottom Navigation -->
	<nav class="bottom-navigation">
      <ul class="bottom_nav_list">
        <li id="home-link-mobile" class="nav-item bottom-nav-item" @click="navigateToDashboard">
          <Link href="" class="nav_link nav-link">
            <i class="fas fa-home nav_icon mt-2"></i>
            <span class="nav_title">Home</span>
          </Link>
        </li>
        <li v-if="getThemeTabs && getThemeTabs.length > 0" v-for="tab in getThemeTabs" :key="tab.name"
          :id="`tab-link-mobile-${tab.name}`"
          :class="`nav-item bottom-nav-item ${($route.params.page && $route.params.page.toLowerCase() == tab.name) ? 'active' : ''}`"
          @click="navigate(tab.name)">
          <Link href="" class="nav_link nav-link">
            <i :class='tabIconClass(tab)'></i>
            <span class="nav_title">{{ tab.label }}</span>
          </Link>
        </li>
      </ul>
    </nav>
</div>
</template>


<script>
import { mapState, mapActions } from "vuex";
import { ProductVariantStatus, ProductVariantSubscriptionStatus } from "../../constants/productVariant";

export default {
	name: "EbookContentSidebar",
	data() {
		return {
			selectedProductVariantId: null, upHere: false
		};
	},
	computed: {
		...mapState("user", ["user", "trainers"]),
		...mapState("productVariant", ["productVariants", "productVariantsProgress"]),

		userProducts() {
			var myProducts =
				(this.user && this.user.productVariants
					? this.user.productVariants.filter(
						(productVariant) =>
							productVariant.status != ProductVariantStatus.DRAFT &&
							productVariant.product &&
							productVariant.product.title &&
							productVariant.subscriptionStatus == ProductVariantSubscriptionStatus.ALLOWED &&
							(!productVariant.endDate || new Date(productVariant.endDate) >= new Date()) &&
							(!productVariant.expiresAt || new Date(productVariant.expiresAt) >= new Date())
					)
					: []).map(x => {
						x.name = x.product.title + "  " + x.title;
						return x;
					});
			if (this.$route.params.id && myProducts.find(x => x.id == this.$route.params.id)) {
				this.selectedProductVariantId = this.$route.params.id;
			} else {
				this.selectedProductVariantId = myProducts && myProducts.length > 0 ? myProducts[0].id : null;
			}
			return myProducts;
		},
		progress() {
			if (this.productVariantsProgress && this.productVariantsProgress[this.selectedProductVariantId]) {
				var progressOB = this.productVariantsProgress[this.selectedProductVariantId];
				return Math.round((progressOB.completedTotalContent / progressOB.totalContent) * 100);
			}
			return 0;
		},
		productTrainers() {
			if (this.selectedProductVariantId && this.productVariants && this.productVariants.items && this.productVariants.items[this.selectedProductVariantId] && this.productVariants.items[this.selectedProductVariantId].properties && this.productVariants.items[this.selectedProductVariantId].properties.trainers) {
				var trainerList = JSON.parse(this.productVariants.items[this.selectedProductVariantId].properties.trainers);
				if (trainerList && trainerList.length > 0) {
					var self = this;
					var trainerList = trainerList.map(function (val, ind, arr) {
						return self.trainers ? self.trainers.find(x => x.id == val) : null;
					}).filter(x => x);
					if (trainerList.length > 0) {
						var names = trainerList[0].firstName + " " + trainerList[0].lastName;
						if (trainerList.length > 1) {
							names += " + " + (trainerList.length - 1) + " trainers" + (trainerList.length > 2 ? "s" : "");
						}
						return names;
					}
				}
			}
			return "";

		},
		isTableOfContentVisible() {
			console.log(this.$route.query)
			return this.$route.query.tableOfContent === 'true';
		},

		getSelectedProductVariant() {
			return this.productVariants?.items?.[String(this.selectedProductVariantId)] || null;
		},

		getThemeTabs() {
			return JSON.parse(this.getSelectedProductVariant?.properties?.themeTabs || '[]');
		},



	},
	watch: {
		selectedProductVariantId: function () {
			if (this.$route.params.id != this.selectedProductVariantId) {
				this.$router.push({ params: { id: this.selectedProductVariantId } });

			}
			if (!this.productVariantsProgress || !this.productVariantsProgress[this.selectedProductVariantId]) {
				this.getProductVariantProgress(this.selectedProductVariantId);
			}
		}
	},
	created() {
		if (this.$route.params.id) {
			this.selectedProductVariantId = this.$route.params.id;
		}

	},
	mounted() {
	},
	methods: {
		...mapActions("productVariant", ["getProductVariantProgress"]),
		navigate(page) {
			var params = { ...this.$route.params };
			var queries = page === 'chapters' ? { ...this.$route.query } : 
			this.$route.query.categoryId ? { categoryId: this.$route.query.categoryId } : {};
			params.page = page;

			// Use the router to navigate to the new route with the updated parameters and queries
			// Catch the navigation duplication error and handle it by doing nothing

			this.$router
				.push({ params, query: queries })
				.catch((error) => { })
				.finally(() => {
					if (page === 'chapters') this.toggleTableOfContent();
				});
		},
		toggleTableOfContent() {
			var params = { ...this.$route.params };
			var queries = { ...this.$route.query };
			queries["tableOfContent"] = !((queries["tableOfContent"]+"") === 'true');
			this.$router
				.push({ params, query: queries })
				.catch((error) => { });
		},

		navigateToDashboard() {
			this.$router.push('/user/dashboard');
		},

		navigateToCourseList(){
			this.$router.push({ path: '/user/dashboard', 
			query: { 
				categoryId: this.$route.query.categoryId,
			 } })
			.catch((error) => { });
		},

		tabIconClass(tab) {
			return [
				'fas',
				'nav_icon',
				tab.name === 'chapters' ? 'fa-list-alt' : '',
				tab.name === 'flashcards' ? 'fa-book-open' : '',
				tab.name === 'assignments' ? 'fa-tasks' : '',
				tab.name === 'exams' ? 'fa-file-alt' : '',
				tab.name === 'liveclasses' ? 'fa-play-circle' : ''
			];
		}

	},

};
</script>

<style scoped>
Link img {
	height: 20px;
	width: 20px;
}

.sidebar_navigation {
	--red: var(--primary-color);
	--border-color: rgb(216, 219, 224);
	--sidebar-occupy-start: 265px;
	--sidebar-width-hover: 280px;
	/* --header-height: 56px; */
	--input-gray: #fafafa;
	--sidebar-width: 60px;
	--sidebar-icon-color: #555;
}

.sidebar_navigation {
	left: 0;
	position: absolute;
	z-index: 9999;
	width: var(--sidebar-width);
	background-color: #fff;
	border-right: solid 1px var(--border-color);
	transition: width .12s ease;
}

.sidebar_opened.sidebar_navigation {
	width: var(--sidebar-width-hover);
	box-shadow: 0 4px 6px 5px rgba(0, 0, 0, 0.08);
}

.sidebar_navigation_container {
	height: calc(100vh - var(--header-height));
	overflow: hidden;
}

.sidebar_nav .nav-group {
	position: relative;
	transition: background .15s ease-in-out;
}

.sidebar_nav {
	position: relative;
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-bottom: 0;
	overflow-x: hidden;
	overflow-y: auto;
	list-style: none;
	padding: 0;
}

.sidebar_nav .nav_link {
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	justify-content: center;
	padding: 8px 15px;
	color: var(--primary-link-color);
	text-decoration: none;
	white-space: nowrap;
	border-left: solid 4px;
	transition: background .15s ease, color .15s ease;
	border-left-color: transparent;
	font-weight: 500;
	height: 56px;
	max-width: 30px;
	padding-left: 25px;
	margin: 10px 0px;
}

.sidebar_nav .active .nav_link,
.sidebar_nav .nav_link:hover {
	color: var(--red);
	border-left-color: var(--red);
	background-color: var(--sidebar-link-hover-background);
}

.sidebar_nav .active .nav_link {
	border-color: var(--red);
}

.sidebar_nav .nav_title {
	/* display: inline; */
	display: block;
	white-space: normal;
	font-size: 10px;
	padding-top: 3px;
	padding-bottom: 4px;
	padding-left: 6px;
	text-align: center
}

.sidebar_opened .sidebar_nav .nav_title {
	/* display: inline-block; */
}

.sidebar_nav .nav_icon {
	margin-left: 7px;
	font-size: 1.25rem;
	margin-right: 0.2rem;
	text-align: center;
	width: 18px;
	height: auto;
	color: var(--sidebar-icon-color);
}

.sidebar_nav .nav_link:hover{
	cursor: pointer;
}

.sidebar_nav .active .nav_link .nav_icon,
.sidebar_nav .nav_link:hover .nav_icon {
    color: var(--primary-color);
}




.bottom-navigation .active .nav_icon
.sidebar_nav .active .nav_icon {
	color: var(-red);
}

.b-tooltip {
	margin-left: 20px;
	margin-bottom: 20px;
	bottom: 20;
	z-index: 9999; 
}

.bottom-navigation {
	background-color: white;
	display: flex;
}


@media (max-width: 768px) {
	.sidebar_navigation {
		display: none;
	}

	.bottom-navigation {
		display: flex;
		height: 60px;
		align-items: center;
		justify-content: space-around;
		overflow-x: auto;
		overflow-y: hidden !important;
	}

	.bottom_nav_list {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		width: 100%;
		justify-content: space-around;
	}

	.bottom-nav-item {
		flex: 1;
		text-align: center;
	}

	.nav_link {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		color: #333;
	}

	.nav_icon {
		font-size: 18px;
	}

	.nav_title {
		font-size: 12px;
		line-height: 15px;
	}

	.nav-item.active .nav_icon {
		color: var(--primary-color)
	}

	.nav-item.active .nav_title {
		color: var(--primary-color)
	}

}
</style>