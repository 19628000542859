<template>
	<div>
		<div>
			<ProductPageBanner :class="{ purchased: true }" :productVariantData="productVariantData" :purchased="true" />
			<ProductContent :productVariantData="productVariantData" :purchased="true">
				<!-- <ProductActivityCard /> -->
			</ProductContent>
			<ProductVariantFeedbackModal :showModal="showProductVariantFeedback" :productVariant="productVariantData"
				@updateModalState="toggleProductVariantFeedback" />

			<div class="container">
				<button class="add-btn mt-2 mb-2" role="button" @click="showProductVariantFeedbackModal()">
					<i class="fas fa-star star-icon"></i>
					Provide Your Feedback
				</button>
			</div>
		</div>
	</div>
</template>

<script>
// import productVariantData from "../constants/tempProductsData";
import { mapActions, mapState } from "vuex";
// import { contentData } from "../constants/tempProductsData";
import ProductPageBanner from "../components/Product/ProductPageBanner.vue";
import ProductContent from "../components/Product/ProductContent.vue";
import ProductActivityCard from "../components/Product/ProductActivityCard.vue";
import ProductVariantFeedbackModal from "../components/ProductVariant/ProductVariantFeedbackModal.vue";

export default {
	name: "ProductContentPage",
	components: { ProductPageBanner, ProductContent, ProductActivityCard, ProductVariantFeedbackModal },
	data() {
		return {
			productVariantData: null,
			showProductVariantFeedback: false,
			// contentData: contentData,
		};
	},
	methods: {
		...mapActions("productVariant", ["getProductVariantById"]),
		async getProductData() {
			const productId = this.productIdFromUrl;
			// console.log("id from url", productId);
			const data = await this.getProductVariantById(productId);
			// const data = this.allProductVariants.find((item) => {
			// 	if (item.productVariantID == productId) return item;
			// });
			this.productVariantData = data;
			// this.contentData =
			window.document.title = data.product.title;
		},

		showProductVariantFeedbackModal() {
			this.showProductVariantFeedback = true;
		},

		toggleProductVariantFeedback(state) {
			if (state != undefined) {
				this.showProductVariantFeedback = state;
			} else this.showProductVariantFeedback = !this.showProductVariantFeedback;
		},
	},
	computed: {
		...mapState("productVariant", ["loading"]),
		...mapState("appState", ["isMobile"]),
		...mapState("user", ["user"]),
		productIdFromUrl() {
			const urlID = this.$route.params.id.split("-");
			var productID = urlID[urlID.length - 1];
			return productID;
		},
	},
	created() {
		if (this.user) {
			var isEnrolled = this.user.productVariants.filter(
				(variant) => variant.id == this.productIdFromUrl
			);
			console.log({ isEnrolled });
			if (!isEnrolled?.length) {
				this.$router.push("/product/details/" + this.$route.params.id);
			}
		}
		this.getProductData();

	},

};
</script>

<style scoped>
@media screen and (max-width: 996px) {
	.purchased {
		margin-bottom: 8rem !important;
	}
}

.purchased {
	margin-bottom: 7.5rem;
}

.star-icon {
	color: #ffba00;
	margin-right: 10px;
}
</style>
