<template>
	<div class="sidebar-mini layout-fixed">
		<div class="page-wrapper ebook-digital">
			<EbookContentSidebar class="sidebar" />

			<div :class="`content-area ${isOpenTableOfContent ? 'content_sidebar_opened' : ''}`">
				<aside :class="`content_sidebar_navigation ${isOpenTableOfContent ? 'content_sidebar_opened' : ''}`">
					<div class="sidebar_navigation_container ebook-digital">
						<EbookContentSection v-if="currentPage.toLowerCase() === 'chapters'" />
					</div>
				</aside>

				<div :class="['main-content']">
					<EbookContentWindow v-if="currentPage.toLowerCase() === 'chapters'" />
					<ProductContentClasses v-if="currentPage.toLowerCase() === 'liveclasses'" /> 
					<ProductContentFlashCards v-if="currentPage.toLowerCase() === 'flashcards'" />
					<ProductContentExams v-if="currentPage.toLowerCase() === 'exams'" />
					<ProductContentAssignments v-if="currentPage.toLowerCase() === 'assignments'" />
				</div>
			</div>

		</div>
	</div>
</template>



<script>
import { mapActions, mapState } from "vuex";
import EbookContentSidebar from "../components/EbookContentDashboard/EbookContentSidebar.vue"
import EbookContentSection from "../components/EbookContentDashboard/EbookContentSection.vue"
import ProductContentClasses from '../components/ProductContentDashboard/ProductContentClasses.vue';
import ProductContentFlashCards from "../components/ProductContentDashboard/ProductContentFlashCards.vue";
import ProductContentAssignments from "../components/ProductContentDashboard/ProductContentAssignments.vue";
import ProductContentExams from "../components/ProductContentDashboard/ProductContentExams.vue";
import EbookContentWindow from "../components/EbookContentDashboard/EbookContentWindow.vue";
import { ProductVariantStatus, ProductVariantSubscriptionStatus } from "../constants/productVariant";

import { ContentType } from "../constants/content";
import Swal from "sweetalert2";
export default {
	name: "EbookContentDashboard",
	components: {
		EbookContentSidebar,
		EbookContentSection,
		ProductContentClasses,
		ProductContentFlashCards,
		ProductContentExams,
		ProductContentAssignments,
		EbookContentWindow
	},
	data() {
		return {
			currentPage: "",
			isOpenTableOfContent:false
		};
	},
	methods: {
		...mapActions("productVariant", ["getProductVariantById", "getProductVariantProgress"]),
		...mapActions("user", ["fetchCurrentUser", "fetchTrainersList"]),
		...mapActions("content", ["getUserContentAction"]),
		checkProductvariant: function () {
			if (this.productVariantID) {
				var userProductVariant = (this.user && this.user.productVariants
					? this.user.productVariants.filter(
						(productVariant) =>
							productVariant.status != ProductVariantStatus.DRAFT &&
							productVariant.product &&
							productVariant.product.title &&
							productVariant.subscriptionStatus == ProductVariantSubscriptionStatus.ALLOWED &&
							(!productVariant.endDate || new Date(productVariant.endDate) >= new Date()) &&
							(!productVariant.expiresAt || new Date(productVariant.expiresAt) >= new Date())
					)
					: []).find(x => x.id == this.productVariantID);

				// if (userProductVariant) {

				// 	if (!userProductVariant.properties || !userProductVariant.properties.contentViewTheme || userProductVariant.properties.contentViewTheme.toLowerCase() != 'ebook') {
				// 		this.$router.push("/product/details/" + userProductVariant.id);
				// 	}
				//  } 
				// else {
				//  	this.$router.push("/product/details/" + this.productVariantID);

				// }
				// console.log(userProductVariant,"userProductVariant");	
			}
		}
	},
	computed: {

		...mapState("user", ["user"]),
		...mapState("content", ["content", "contentActions"]),

	},
	created() {
		this.fetchCurrentUser();
		this.fetchTrainersList().then(() => {
			console.log("trainers fetched");
		});
		if (this.$route.params.page && this.$route.params.page != this.currentPage) {
			this.currentPage = this.$route.params.page;
		}
		if (this.$route.params.id && this.productVariantID != this.$route.params.id) {
			this.productVariantID = this.$route.params.id;
			console.log("Part 1");
			this.getProductVariantById(this.productVariantID);
			this.checkProductvariant();
		}
		if (this.$route.query && this.$route.query.tableOfContent?.toString()?.toLowerCase() == "true") {
			this.isOpenTableOfContent = true;
		} else {
			this.isOpenTableOfContent = false;
		}
	},

	watch: {
		$route: function (to, from) {
			if (from.query.contentId && from.query.contentId != to.query.contentId) {

				if (this.content[from.query.contentId] &&
					this.content[from.query.contentId].type == ContentType.RECORDED_VIDEOS &&
					!this.contentActions[from.query.contentId]?.find(x => x.dataType.toLowerCase() == 'completionstatus' && x.data.toLowerCase() == 'completed')) {
					var oldCID = from.query.contentId;
					this.getUserContentAction({ contentId: oldCID }).then((actions) => {
						if (actions?.find(x => x.dataType.toLowerCase() == 'completionstatus')) {
							this.getProductVariantProgress(this.$route.params.id);
						}
					});
				}
			}

			if (this.$route.params.page && this.$route.params.page != this.currentPage) {
				this.currentPage = this.$route.params.page;
			}

			if (this.$route.params.id && this.productVariantID != this.$route.params.id) {
				this.productVariantID = this.$route.params.id;
				if (!this.productVariants || !this.productVariants.items[this.productVariantID]) {
					this.getProductVariantById(this.productVariantID);
				}
				this.checkProductvariant();

			}
			if (this.$route.query && this.$route.query.tableOfContent?.toString()?.toLowerCase()=="true") {
				this.isOpenTableOfContent = true;
			}else{
				this.isOpenTableOfContent = false;
			}
		},
		productVariantID: function () {

		}
	}
};
</script>

<style scoped>
.page-wrapper {
  background-color: #E7E7E7;
  height: calc(100vh - var(--header-height));
  display: grid;
  grid-template-areas: "sidebar content";
  grid-template-columns: 60px 1fr;
  overflow: hidden;
}

.sidebar {
  grid-area: sidebar;
  background-color: #fff;
  border-right: 1px solid var(--border-color);
}

.content-area {
  grid-area: content;
  display: grid;
  grid-template-areas: "toc main";
  grid-template-columns: auto 1fr;
  height: 100%;
}

.content_sidebar_navigation {
  grid-area: toc;
  background-color: #fff;
  transition: width 0.12s ease;
  width: 0;
  overflow-x: hidden;
  border-right: 1px solid var(--border-color);
}

.content_sidebar_opened.content_sidebar_navigation {
  width: 400px;
  overflow: hidden;
}

.main-content {
  grid-area: main;
  display: flex; 
  flex-direction: column; 
  padding: 0;
  overflow-y: hidden;
  background-color: #fff;
  min-height: 0;
}


@media (max-width: 768px) {
	.page-wrapper {
		height: calc(100dvh - var(--header-height));
		grid-template-areas: 
			"content"
			"sidebar";
		grid-template-rows: 1fr 60px;
		grid-template-columns: 1fr;
		padding-top: env(safe-area-inset-top, 0px);  /* Fallback to 0px if env() is not supported */
		padding-right: env(safe-area-inset-right, 0px);
		padding-bottom: env(safe-area-inset-bottom, 16px); /* Fallback to 16px to ensure content is visible */
		padding-left: env(safe-area-inset-left, 0px);
		box-sizing: border-box;
		overflow: hidden; /* Prevent content overflow */
	}

	/* Fallback for older browsers or if env() is not supported */
	@supports not (height: 100dvh) {
		.page-wrapper {
			height: calc(100vh - var(--header-height));
		}
	}


	.sidebar_navigation {
		display: none;
	}

	.content-area {
		grid-template-areas: 
			"main";
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
	}

	.content-area.content_sidebar_opened {
		grid-template-areas: 
			"toc";
		grid-template-columns: 1fr;
	}

	.content_sidebar_navigation {
		width: 100% !important;
		overflow: hidden;
	}

	.content-area.content_sidebar_opened .main-content {
		display: none;
	}

	.scrollable {
		overflow-y: auto !important; /* Use auto to manage scrollbars dynamically */
		-webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
	}
}

</style>
