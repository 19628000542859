<template>
	<div class="window-container">
	  <header class="header">
		<div class="header-item">
		  <span v-if="this.$route.query.categoryId" id="back-link" class="nav-item sidebar-nav-item" @click="navigateToCourseList">
			<i class="fas fa-arrow-left nav_icon"></i>
			<span class="category-label">{{ selectedCategory && selectedCategory.title || '' }}</span>
		  </span>
		</div>
		<div class="header-middle">
		  <div class="cd-chapter-selection">
			<button class="btn-arrow" @click="previousContent()" :disabled="loading || !getPrevContent">
			  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" width="35px" height="35px" fill="white">
				<path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6z" />
			  </svg>
			</button>
			<div>
			  <span v-if="selectedContent && selectedContent.label" :class="['content-label', {'mt-3': !selectedChapter || !selectedChapter.title }]">
				{{ selectedContent.label || ''}}
			  </span>
			  <div v-if="selectedChapter && selectedChapter.title" :class="['chapter-subtitle', {'mt-3': !selectedContent || !selectedContent.label }]">
				{{ selectedChapter.title || '' }}
			  </div>
			</div>
			<button @click="nextContent()" :disabled="loading || !getNextContent" class="btn-arrow">
			  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" width="35px" height="35px" fill="white">
				<path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z" />
			  </svg>
			</button>
		  </div>
		</div>
		<div class="header-item"></div>
	  </header>
	  <div class="content">
		<div v-if="selectedProductVariant && contentId">
		  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
		  <div :class="['eb-content', { 'half-width': isTableOfContentVisible, 'full-width': !isTableOfContentVisible }]">
			<ProductContentView></ProductContentView>
		  </div>
		</div>
		<div v-else>
		  <div :class="['eb-content', { 'half-width': isTableOfContentVisible, 'full-width': !isTableOfContentVisible }]">
			<center><b-spinner class="loader"></b-spinner></center>
		  </div>
		</div>
	  </div>
	</div>
  </template>
  
  
  


<script>
import { mapState, mapActions, mapMutations } from "vuex";
import ProductContentChaptersContentList from "../ProductContentDashboard/ProductContentChaptersContentList.vue";
import Treeselect from "@riophae/vue-treeselect";
import { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'
import ProductContentView from "../ProductContentDashboard/ProductContentView.vue";
import { ContentType } from "../../constants/content";
import Swal from "sweetalert2";

export default {
	name: "EbookContentWindow",
	data() {
		return {
			ContentType,
			productVariantID: null,
			selectedProductVariant: null,
			selectedChapterID: null,
			selectedContent: null,
			selectedChapter: null,
			contentId: null,
			selectedCategory: null,
			options: [],
			confidenceLevel: {},
			completionStatus: {},
			filters: {},
			accordionState: {},
			isFiltering: false
		};
	},
	components: {
		ProductContentChaptersContentList, Treeselect, ProductContentView, ContentType
	},
	methods: {
		...mapActions("productVariant", ["getProductVariantById", "getProductVariantProgress"]),
		...mapActions("content", ["getContentById", "setUserContentAction", "getUserContentAction", "getUserContentByProductVariant", "fetchUserAllContentActionsByID"]),
		...mapActions("content", ["updateAction"]),
		...mapActions("category", ["fetchCategories"]),

		getProductVariant: async function () {
			this.selectedChapterID = null;
			if (this.productVariants && this.productVariants.items && this.productVariants.items[this.productVariantID + ""] && !this.selectedProductVariant) {
				this.selectedProductVariant = this.productVariants.items[this.productVariantID + ""];
				if (this.selectedProductVariant && this.selectedProductVariant.productVariantItems && this.selectedProductVariant.productVariantItems.length > 0) {
					var self = this;

					setTimeout(function () {

						if (self.$route.query.chapterId) {
							self.accordionState[self.$route.query.chapterId] = true;
						} else {
							self.accordionState[self.selectedProductVariant.productVariantItems[0].id] = true;
						}


					}, 800);
				}

				if(!this.isContentsExistsAgainstProductVariant(this.productVariantID)) {
					// if once the contents fetched means no need to load again actions too cause they are managed in state
					await this.getUserContentByProductVariant({ productVariantID: this.productVariantID });
					await this.fetchUserAllContentActionsByID({ productVariantID: this.productVariantID });
				}

				if (this.$route.query.chapterId && this.selectedProductVariant.productVariantItems.find(x => x.id == this.$route.query.chapterId)) {
					{

					}
					this.options = this.selectedProductVariant.productVariantItems.map(
						x => {
							x = this.content && this.content[x.id + ""] ? this.content[x.id + ""] : x;
							return {
								id: x.id, label: x.title, type: x.type,
								children: this.mapNestedChild(x)
							};
						});
					return;
				}

				this.loadInitialContent();
			}
		},
		isContentsExistsAgainstProductVariant(productVariantID) {
			// debugger
			if (this.productVariants && this.productVariants.items && Object.keys(this.productVariants.items).length > 0 && this.productVariants.items[productVariantID]) {
				const subjects = this.productVariants.items[productVariantID]?.productVariantItems || [];
				const allSubjectsContentExist = subjects.every(subject => this.content.hasOwnProperty(subject.id));
				return allSubjectsContentExist;
			}
			
			return false;
		},
		selectChapter: function (id) {
			if (id) {
				var self = this;
				setTimeout(function () {
					if (self.selectedChapterID != id) {
						self.selectedChapterID = id;
						if (self.$route.query.chapterId != id) {
							self.$router.push({ query: { ...self.$route.query, ["chapterId"]: id, tableOfContent: undefined } });
						}
					} else {
						self.selectedChapterID = null;
						if (self.$route.query.chapterId == id && self.accordionState[id] == false) {
							self.$router.push({ query: { ...self.$route.query, ["chapterId"]: null, tableOfContent: undefined } });
						}
					}

					self.loadSelectedChapter();

					

				}, 200);
			}
			// if(!this.content[id+""]){
			// 	this.getContentById({id:id,extraParams:{ "NLevel":3}}).then((val)=>{
			// 		this.options=this.selectedProductVariant.productVariantItems.map(
			// 			x=>{ 
			// 				if(this.content && this.content[x.id+""]){
			// 					x=this.content[x.id+""];
			// 				}
			// 				return { id:x.id,label:x.title,type:x.type,
			// 					children:this.mapNestedChild(x)
			// 			};
			// 		});
			// 	});
			// }
		},
		optionContentClass(type) {
			if (type.toLowerCase() == ContentType.RECORDED_VIDEOS.toLowerCase()) {
				return 'video';
			}
		},
		loadChapterOptions({ action, parentNode, callback }) {
			if (action === LOAD_CHILDREN_OPTIONS) {
				if (this.content && this.content[parentNode.id]) {
					parentNode.children = this.mapNestedChild(this.content[parentNode.id]);
					callback();
				} else {
					var self = this;
					this.getContentById({ id: parentNode.id, extraParams: { "NLevel": 3 } }).then(x => {
						parentNode.children = this.mapNestedChild(x);
						callback();
					});
				}
			}
		},
		isunderSelected(children) {
			if (children && children.length > 0 && children.find(x => x.isSelected == true)) {
				this.selectedContent = children.find(x => x.isSelected == true);
				return true;
			}
			return false;
		},
		upper(text) {
			return text?.replace(
				/\w\S*/g,
				function (txt) {
					return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
				}
			);
		},
		mapNestedChild: function (content) {
			return content.type.toLowerCase() == 'directory' ?
				(content.children &&
					content.children.length > 0 ?
					content.children.map(x => { return { id: x.id, isSelected: (x.id == this.contentId), label: x.title, isDefaultExpanded: this.isunderSelected(this.mapNestedChild(x)), children: this.mapNestedChild(x), type: x.type } }) : null) : undefined;
		},
		setContentConfidenceLevel: function (confidenceLevel) {
			if (this.contentId) {
				if (this.confidenceLevel[this.contentId] && this.confidenceLevel[this.contentId].toLowerCase() == confidenceLevel.toLowerCase()) {
					confidenceLevel = 'Unrated';
				}
				this.confidenceLevel[this.contentId] = confidenceLevel;
				var self = this.confidenceLevel;
				this.confidenceLevel = null;
				this.confidenceLevel = self;
				var contentAction = {
					ContentID: this.contentId,
					DataType: "confidence_level",
					Data: confidenceLevel
				}
				this.setUserContentAction(contentAction).then((action) => {
					if (action && action.dataType && action.dataType.toLowerCase() == "confidence_level") {
						this.confidenceLevel[action.contentID] = action.data;
						var self = this.confidenceLevel;
						this.confidenceLevel = null;
						this.confidenceLevel = self;
						this.updateAction(action);
					}
				});
			}
		},
		previousContent: function (isForce = false) {
			if (!isForce && !this.isAbleToSwitchContent) {
				this.askToConfirmToSwitch(this.nextContent);
				return;
			}

			const prevData = this.getPrevContent;
			
			if(!prevData) return; 
   
			this.selectedChapterID = this.$route.query.chapterId;
			this.contentId = prevData.content.id

			if(this.selectedChapterID+"" !== prevData.chapter.id){
				this.selectChapter(prevData.chapter.id);
			}
		},
	
		nextContent: function (isForce = false) {
			if (!isForce && !this.isAbleToSwitchContent) {
				this.askToConfirmToSwitch(this.nextContent);
				return;
			}

			const nextData = this.getNextContent;
			
			if(!nextData) return; 
   
			this.selectedChapterID = this.$route.query.chapterId;
			this.contentId = nextData.content.id

			if(this.selectedChapterID+"" !== nextData.chapter.id){
				this.selectChapter(nextData.chapter.id);
			}
		},
		getConfidenceLevel: function () {
			if (!this.confidenceLevel[this.contentId]) {
				this.getUserContentAction({ contentId: this.contentId, dataType: "confidence_level" }).then((userActions) => {
					var userAction = userActions ? userActions.find(x => x.dataType == "confidence_level") : null;
					if (userAction) {
						this.confidenceLevel[userAction.contentID] = userAction.data;
					}
				});
			}
		},
		getCompletedStatus: function () {
			if (!this.completionStatus[this.contentId] && (!this.content[this.contentId] || (this.content[this.contentId].type && this.content[this.contentId].type.toLowerCase() != 'recordedvideos' && this.content[this.contentId].type.toLowerCase() != 'liveclass' && this.content[this.contentId].type.toLowerCase() != 'flashcard'))) {
				this.getUserContentAction({ contentId: this.contentId, dataType: "completionStatus" }).then((userActions) => {
					var userAction = userActions ? userActions.find(x => x.dataType == "completionStatus") : null;
					if (userAction) {
						this.completionStatus[userAction.contentID] = userAction.data;
						var self = this.completionStatus;
						this.completionStatus = null;
						this.completionStatus = self;
					}
				});
			}
		},
		markCompleted: function (isCompleted) {
			if (this.contentId) {

				var contentAction = {
					ContentID: this.contentId,
					DataType: "completionStatus",
					Data: isCompleted ? "completed" : "uncompleted"
				}
				
				this.completionStatus[this.contentId] = isCompleted ? "completed" : "uncompleted";
				var self = this.completionStatus;
				this.completionStatus = null;
				this.completionStatus = self;

				this.setUserContentAction(contentAction).then((action) => {
					if (action && action.dataType && action.dataType.toLowerCase() == "completionstatus") {
						this.completionStatus[action.contentID] = action.data;
						var self = this.completionStatus;
						this.completionStatus = null;
						this.completionStatus = self;
						this.getProductVariantProgress(this.productVariantID);

						this.updateAction(action);
					}

				});
			}
		},
		hasContent() {
			return this.selectedProductVariant && this.selectedProductVariant.productVariantItems && this.selectedProductVariant.productVariantItems.find(x => this.content[x.id]);
		},
		setOrRemoveFilter(filter, filterType) {
			var currentFilters = this.filters;

			if (!currentFilters[filterType]) {
				currentFilters[filterType] = [filter];
			} else {
				const filterIndex = currentFilters[filterType].indexOf(filter);

				if (filterIndex === -1) {
					currentFilters[filterType].push(filter);
				} else {
					currentFilters[filterType].splice(filterIndex, 1);

					if (currentFilters[filterType].length === 0) {
						delete currentFilters[filterType];
					}
				}
			}

			this.filters = {};
			var self = this;
			setTimeout(function () {

				self.filters = currentFilters;
			}, 5);

		},
		isFilterSelected(filter, filterType) {
			if (this.filters[filterType]) {
				const lowerCaseFilter = filter.toLowerCase();
				return this.filters[filterType].some(f => f.toLowerCase() === lowerCaseFilter);
			}

			return false;
		},
		showSwalErrorMessage({ title, message, buttonText = null }) {
			const swalOptions = {
				title: title,
				text: message,
				icon: "success",
				dangerMode: true,
				allowOutsideClick: true,
				allowEscapeKey: true,
			};

			if (buttonText !== null) {
				swalOptions.confirmButtonText = buttonText;
				swalOptions.confirmButtonColor = "#FF0000";
			}

			Swal.fire(swalOptions).then((result) => {
			});
		},
		handleInput() {
			if (this.filters.searchText === '') {
				delete this.filters.searchText;
			}
		},
		resetFilters() {
			this.filters = {}
			// this.showSwalErrorMessage({
			// 	title: "Filters Cleared",
			// 	message: "All the configured filters has been cleared..",
			// 	buttonText: "OK"
			// })
		},
		contentUnderChapterCount(contentId) {
			if (this.content[contentId] && this.content[contentId].children && this.content[contentId].children.length > 0) {
				return this.content[contentId].children.map(x => x.children && x.children.length > 0 ? this.applyFilter(this.filterChildrens(x.children)).length : 0).reduce((partialSum, a) => partialSum + a, 0);
			}
			return 0;
		},
		filterChildrens(list) {
			return list.filter((item) => {
				if (item.type !== 'RecordedVideos') {
					return item;
				}
				else if (item.extraProperties && item.extraProperties.Status && item.extraProperties.Status.toLowerCase() === 'processed') {
					return item
				}
			});
		},
		completedContentUnderChapterCount(contentId) {
			if (this.content[contentId] && this.content[contentId].children && this.content[contentId].children.length > 0) {
				return this.content[contentId].children.map(x => x.children && x.children.length > 0 ? this.applyFilter(this.filterChildrens(x.children))
					.filter(j => this.contentActions[j.id]?.find(x => x.dataType == "completionStatus" && x.data.toLowerCase() == 'completed') || this.isLiveClassContentAndEnded(j))
					.length : 0).reduce((partialSum, a) => partialSum + a, 0);
			}
			return 0;
		},
		isLiveClassContentAndEnded(item) {
			var currentDate = new Date();
			const end = new Date(item.availableTo);
			return item.type === ContentType.LIVE_CLASS && item.availableTo && currentDate > new Date(item.availableTo);
		},
		applyFilter(list) {
			const filters = this.filters;
			var self = this;
			if (filters.confidence_level && filters.confidence_level.length > 0) {
				list = list.filter(x =>
					(filters.confidence_level.indexOf("unrated") > -1 && !(self.contentActions[x.id]?.find(x => x.dataType == "confidence_level"))) ||
					(filters.confidence_level.indexOf("high") > -1 && self.contentActions[x.id]?.find(x => x.dataType == "confidence_level" && x.data.toLowerCase() == 'high')) ||
					(filters.confidence_level.indexOf("medium") > -1 && self.contentActions[x.id]?.find(x => x.dataType == "confidence_level" && x.data.toLowerCase() == 'medium')) ||
					(filters.confidence_level.indexOf("low") > -1 && self.contentActions[x.id]?.find(x => x.dataType == "confidence_level" && x.data.toLowerCase() == 'low'))
				);
			}


			if (filters.completionStatus && filters.completionStatus.length > 0) {
				list = list.filter(x =>
					(filters.completionStatus.indexOf("not_started") > -1 && !(self.contentActions[x.id]?.find(x => x.dataType == "completionStatus" && x.data.toLowerCase() == 'completed'))) ||
					(filters.completionStatus.indexOf("completed") > -1 && self.contentActions[x.id]?.find(x => x.dataType == "completionStatus" && x.data.toLowerCase() == 'completed'))
				);
			}

			if (filters.bookmark_content && filters.bookmark_content.length > 0) {
				list = list.filter(x =>
					(filters.bookmark_content.indexOf("bookmark") > -1 && self.contentActions[x.id]?.find(x => x.dataType == "bookmark" && x.data.toLowerCase() == 'true'))
				);
			}

			if (filters.searchText) {
				list = list.filter(x => x.title.toLowerCase().indexOf(filters.searchText.toLowerCase()) > -1);
			}

			return list;

		},
		isAccordionVisible(id) {

			if (this.accordionState[id] != null) {
				return this.accordionState[id];
			}

			if ((this.$route.query.chapterId != null && this.$route.query.chapterId == id) || (this.$route.query.chapterId == null && this.accordionState[id] == null)) {
				this.accordionState[id] = true;
				return true;
			}
			return false;
		},
		showAttachments: function () {
			//var el = document.getElementById('instructorAttachmentsBtn');
			var el = this.$refs.showAttachmentsBtn;
			if (el.classList.contains('active')) {
				document.getElementById('attachments-modal').classList.remove('active');
				el.classList.remove('active');
			} else {
				document.getElementById('attachments-modal').classList.add('active');
				el.classList.add('active');
			}


		},
		askToConfirmToSwitch(onSwitch) {
			Swal.fire({
				title: "Are you sure?",
				text: "You won't be able to resume this!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Yes"
			}).then((result) => {
				if (result.isConfirmed) {
					onSwitch(true);
				}
			});

		},

		loadInitialContent() {
			const savedProductVariantID = localStorage.getItem('activeProductVariantId') + "";

			// Load saved content only when product varant is same
			if(savedProductVariantID && (this.$route.params.id + "") === savedProductVariantID){

			let chapterId = localStorage.getItem('activeChapterId');
			let contentId = localStorage.getItem('activeContentId');

				if(chapterId && contentId){
					this.$router.push({ query: { ...this.$route.query, chapterId: chapterId, contentId: contentId, tableOfContent: undefined  } });
					return;
				}
			}

			if (this.selectedProductVariant &&
				this.selectedProductVariant.productVariantItems &&
				this.selectedProductVariant.productVariantItems.length > 0) {

				// Get Chapter to load initially
				const firstChapterId = this.selectedProductVariant.productVariantItems[0].id;

				if (firstChapterId && this.content && this.content[firstChapterId]) {
					let chapter = this.content[firstChapterId];

					let topic = chapter.children.find(x => x.children && x.children.length > 0);

					if (topic && topic.children && topic.children.length > 0) {
						let content = topic.children[0];
						const firstContentId = content.id

						this.$router.push({ query: { ...this.$route.query, chapterId: firstChapterId, contentId: firstContentId, tableOfContent: undefined  } });


					} else {
						console.log("No valid chapter content found.");
					}

				} else {
					console.log("No items found or productVariantItems is null.");
				}
			}

		},

		loadSelectedChapter() {
			const chapterId = this.$route.query.chapterId;

			if (this.selectedProductVariant &&
				this.selectedProductVariant.productVariantItems &&
				this.selectedProductVariant.productVariantItems.length > 0) {

				this.selectedChapter = this.selectedProductVariant.productVariantItems.find(item => item.id === chapterId);
			}
    	},

		handleContentChange(){
			const productVariantID = this.$route.params.id;
			if(productVariantID) localStorage.setItem('activeProductVariantId', productVariantID);
			if(this.$route.query.chapterId) localStorage.setItem('activeChapterId', this.$route.query.chapterId);
			if(this.contentId) localStorage.setItem('activeContentId', this.contentId);
			this.loadSelectedChapter();
		},

		navigateToCourseList(){
			this.$router.push({ path: '/user/dashboard', 
			query: { 
				categoryId: this.$route.query.categoryId,
			 } })
			.catch((error) => { });
		},
	},
	computed: {
		...mapState("user", ["user"]),
		...mapState("content", ["content", "loading", "contentActions"]),
		...mapState("productVariant", ["productVariants"]),
		...mapState("category", ["categories"]),

		showPreviousButton() {
			try {
				if (this.contentId && this.selectedChapterID && this.content[this.selectedChapterID]) {
					let firstChapter = Object.entries(this.productVariants.items)[0][1].productVariantItems[0];
					let firstContent = this.content[firstChapter.id].children[0].children[0]

					return firstContent.id !== this.contentId;
				}
			}
			catch (e) {
				return true;
			}
			return true;
		},
		isAbleToSwitchContent() {
			if (this.contentId && this.content[this.contentId] && this.content[this.contentId].type.toLowerCase() == 'quiz' && this.$route.query.action?.toLowerCase() == 'attempt') {
				return false;
			}
			return true;
		},

		isTableOfContentVisible() {
			return (this.$route.query.tableOfContent + "") === 'true';
		},

		getPrevContent() {
			const chapterId = this.$route.query.chapterId;
			const contentId = this.$route.query.contentId;

			if (this.selectedProductVariant && this.selectedProductVariant.productVariantItems && chapterId && contentId) {
				const currentChapterIndex = this.selectedProductVariant.productVariantItems.findIndex(item => item.id === chapterId);
				if (currentChapterIndex === -1) return null;

				const chapter = this.content[chapterId];
				if (!chapter || !chapter.children || chapter.children.length < 1) return null;

				const currentTopicIndex = chapter.children.findIndex(t => t.children && t.children.findIndex(c => (c.id +"") === contentId) !== -1);
				if (currentTopicIndex === -1) return null;

				const topic = chapter.children[currentTopicIndex];
				if (!topic || !topic.children || topic.children.length < 1) return null;

				const currentContentIndex = topic.children.findIndex(c => c.id === contentId);
				if (currentContentIndex === -1) return null;

				// find prev content same topic
				if (currentContentIndex > 0) {
					const prevTopicContent = topic.children[currentContentIndex - 1];
					return { chapter: chapter, content: prevTopicContent };
				}

				// find last content prev topic same chapter
				if (currentTopicIndex > 0) {
					const prevTopic = chapter.children[currentTopicIndex - 1];
					if (prevTopic.children && prevTopic.children.length > 0) {
						return { chapter: chapter, content: prevTopic.children[prevTopic.children.length - 1] };
					}
				}

				// find last content last topic prev chapter
				if (currentChapterIndex > 0) {
					const prevChapterId = this.selectedProductVariant.productVariantItems[currentChapterIndex - 1].id;
					const prevChapter = this.content[prevChapterId];
					if (prevChapter && prevChapter.children && prevChapter.children.length > 0) {
						const lastTopic = prevChapter.children[prevChapter.children.length - 1];
						if (lastTopic.children && lastTopic.children.length > 0) {
							return { chapter: prevChapter, content: lastTopic.children[lastTopic.children.length - 1] };
						}
					}
				}
			}
			return null;
    	},

		getNextContent() {
			const chapterId = this.$route.query.chapterId;
			const contentId = this.$route.query.contentId;

			if (this.selectedProductVariant && this.selectedProductVariant.productVariantItems && chapterId && contentId) {
				const currentChapterIndex = this.selectedProductVariant.productVariantItems.findIndex(item => item.id === chapterId);
				if (currentChapterIndex === -1) return null;
				
				const chapter = this.content[chapterId];
				if (!chapter || !chapter.children || chapter.children.length < 1) return null;

				const currentTopicIndex = chapter.children.findIndex(t => t.children && t.children.findIndex(c => (c.id +"") === contentId) !== -1);
				if (currentTopicIndex === -1) return null;
			
				const topic = chapter.children[currentTopicIndex];
				
				if (!topic || !topic.children || topic.children.length < 1) return null;

				const currentContentIndex = topic.children.findIndex(c => c.id === contentId);
				if (currentContentIndex === -1) return null;

				// find next content of same topic
				if (currentContentIndex < topic.children.length - 1) {
					const nextTopicContent = topic.children[currentContentIndex + 1];
					return {chapter: chapter, content: nextTopicContent};
				}

				// find first content of next topic
				if (currentTopicIndex < chapter.children.length - 1) {
					const nextTopic = chapter.children[currentTopicIndex + 1];
					if (nextTopic.children && nextTopic.children.length > 0) {
						return {chapter: chapter, content: nextTopic.children[0]};
					}
				}

				// find next chapter first topic first content
				if (currentChapterIndex < this.selectedProductVariant.productVariantItems.length - 1) {
					const nextChapterId = this.selectedProductVariant.productVariantItems[currentChapterIndex + 1].id;
					const nextChapter = this.content[nextChapterId];
					if (nextChapter && nextChapter.children && nextChapter.children.length > 0) {
						const firstTopic = nextChapter.children[0];
						if (firstTopic.children && firstTopic.children.length > 0) {
							return {chapter: nextChapter, content: firstTopic.children[0]};
						}
					}
				}
			}
			return null;
		},


	},
	watch: {
		productVariants: function () {
			if (!this.selectedProductVariant) {
				this.getProductVariant();
			}

			this.loadSelectedChapter()
		},
		selectedProductVariant: function () {

		},
		// '$route.query.contentId': 'handleContentChange',
		filters: function () {
			var self = this;

			Object.keys(this.accordionState).forEach(function (val, ind, arr) {
				self.accordionState[val] = true;
			});
			var temp = self.accordionState;
			self.accordionState = null;
			self.accordionState = temp;

		},
		selectedChapterID() {
			var self = this;


			Object.keys(this.accordionState).forEach(function (val, ind, arr) {
				self.accordionState[val] = false;
				if (val == self.selectedChapterID) {
					self.accordionState[val] = true;
				}
			});
		},
		$route(to, from) {
			if (this.$route.params.id && this.productVariantID != this.$route.params.id) {
				this.productVariantID = this.$route.params.id;
				this.selectedProductVariant = null;
				this.getProductVariant();
			}

			if(!this.$route.query.chapterId || !this.$route.query.contentId) {
				this.loadInitialContent();
			}


			if (this.contentId != this.$route.query.contentId) {
				this.contentId = this.$route.query.contentId;
				
				this.handleContentChange()
				
				if (this.contentId && this.selectedProductVariant) {
					var self = this;
					this.options = this.selectedProductVariant.productVariantItems.map(
						x => {
							x = this.content && this.content[x.id + ""] ? this.content[x.id + ""] : x;
							return {
								id: x.id, label: x.title, type: x.type,
								children: self.mapNestedChild(x)
							};
						});
				}
			}

		},
		contentId: function () {
			if (this.$route.query.contentId != this.contentId) {
				this.$router.push({ query: { ...this.$route.query, chapterId: this.selectedChapterID, contentId: this.contentId, tableOfContent: undefined  } })
				
				// load only if current contentId is actually changed 
				this.getConfidenceLevel();
				this.getCompletedStatus();
			}

			var self = this;
			this.options = this.selectedProductVariant.productVariantItems.map(
				x => {
					x = this.content && this.content[x.id + ""] ? this.content[x.id + ""] : x;
					return {
						id: x.id, label: x.title, type: x.type,
						children: self.mapNestedChild(x)
					};
				});
		}
	},
	async created() {
		
		if (this.$route.params.id && this.productVariantID != this.$route.params.id) {
			this.productVariantID = this.$route.params.id;
			this.getProductVariant();
		}


		if (this.$route.query.contentId && this.contentId != this.$route.query.contentId) {
			this.contentId = this.$route.query.contentId;
		}	
	},
	async mounted() {
		if(this.$route.query.categoryId && this.categories.length < 1){
			await this.fetchCategories()
		}

		this.selectedCategory = (this.categories || []).find(
				(x) => x.id + "" === this.$route.query.categoryId + ""
		);
	}
};
</script>

<style scoped>
.window-container {
  display: grid;
  grid-template-areas: 
    "header"
    "content";
  grid-template-rows: 56px 1fr;
  /* overflow: scroll; */
  overflow: hidden;
  height: 100%;
}

.header {
  grid-area: header;
  background-color: var(--primary-color);
  color: var(--primary-link-color);
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 56px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.08);
}

.header .header-item {
  flex: 1;
}

.header .header-middle {
  flex: 6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  grid-area: content;
  overflow-y: hidden;
  background-color: #ffffff;
  height: 100%;
}

.content > div {
	height: 100% !important;
}

.eb-content {
height: 100% !important;
}

.eb-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.btn-arrow {
  background: none;
  border: none;
  cursor: pointer;
  width: 35px;
  height: 35px;
}

.btn-arrow:disabled {
  color: lightgray;
  cursor: not-allowed;
}

#back-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
}

#back-link .nav_icon {
  margin-right: 5px;
  font-size: 1.25rem;
}

.category-label {
  margin-left: 10px;
}

.cd-chapter-selection {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  width: 100%;
}

.content-label,
.chapter-subtitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content-label {
  font-size: 16px;
  padding-top: 8px;
  font-weight: 500;
  max-width: 500px;
}

.chapter-subtitle {
  font-size: 15px;
  color: white;
  margin-top: 0;
  padding-top: 0px;
  max-width: 500px;
}


@media (max-width: 768px) {
	.window-container{
		/* height: calc(100vh - var(--header-height)) */
	}

  #back-link span {
    display: none;
  }

  .content-label {
    max-width: 200px;
  }

  .chapter-subtitle {
    max-width: 200px;
  }

  .content {
	height: 100%;
  }
}

</style>


