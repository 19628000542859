<template>
	<div :class="['body-content', { 'transparent': loadingExam || loadingResult }]">
		<center v-if="((loadingExam || loadingResult) && !isDetailedView)
			|| ((loadingExam || loadingResult) && !question && isDetailedView)" class="loading">
			<b-spinner large></b-spinner>
		</center>
		<div v-else-if="!loadingExam && !loadingResult && examList.length > 0" class="eb-content-wrapper">
			<div class="eb-body">
				<div class="eb-header d-flex mb-1">
					<a role="button" @click="toggleView()"> <i v-if="isDetailedView" id="backButton"
							class="fas fa-arrow-left"></i>
					</a>
					<h5>
						Exams
					</h5>
					<!-- <button class="btn-link ms-auto text-decoration-none red me-2">Reset All Questions</button> -->
				</div>

				<div class="eb-content eb-practice-content">
					<!-- <div class="cd-body round"> -->
					<!-- <div class="eb-progress eb-head-progress px-4 my-4">
							<label class="progress-label mb-1">Completion</label>
							<div class="progress rounded-5">
								<div class="progress-bar" role="progressbar" style="width: 35%" aria-valuenow="25"
									aria-valuemin="0" aria-valuemax="100"></div>
							</div>
						</div>

						<div class="eb-summary-row px-4 pt-3 mb-4">
							<div class="eb-summary mt-1 ms-1">
								<div class="summary-item">
									<div class="summ-stat">1 of 87</div>
									<div class="summ-label">Questions Taken</div>
								</div>
								<div class="summary-item">
									<div class="summ-stat">00:00:34</div>
									<div class="summ-label">Avg. Answer Time</div>
								</div>
								<div class="summary-item">
									<div class="summ-stat">1 of 87</div>
									<div class="summ-label">Questions Taken</div>
								</div>
							</div>
						</div> -->

					<div v-if="!isDetailedView" class="practice-table">
						<div class="cd-body round">

							<div class="pc-table-wrapper">
								<ul class="eb-table pc-table">
									<li class="thead">
										<div class="t-cell" style="fflex: 0.25"><b>#</b></div>
										<div class="t-cell" style="fflex: 2;"><b>Exam</b></div>
										<div class="t-cell text-center " style="fflex: 0.5;"><b> Attempted Date</b></div>
										<div class="t-cell text-center" style="fflex: 0.8;"><b>Score</b></div>
										<div class="t-cell text-center" style="fflex: 0.8;"><b>Percentage Completed</b></div>
										<!-- <div class="t-cell text-center" style="fflex: 0.8;"><b>Attempted</b></div> -->
										<div class="t-cell text-center"
											style="fflex: 1; display: flex; justify-content: center; flex-direction: row; align-items: center;">
											<b class="mx-2">Exam Actions</b>
										</div>
									</li>

									<li class="t-row" @click="changeView({ exam: exam })" v-for="(exam, index) in examList"
										:key="exam.id + '' + index">
										<div class="t-cell" style="fflex: 0.25">
											{{ index + 1 }}
										</div>
										<div class="t-cell" style="display: block; fflex: 2;" >
											<span @click.stop>
											<a role="button" @click="()=>{ $router.push({ params:{ page:'chapters', id:productVariantID }, query:{ chapterId:exam.topLevelContentID }}) }"	 class="red">{{ exam.chapterName }}</a>
										    </span>
											<br /><span  @click="()=>{ $router.push({ params:{ page:'chapters', id:productVariantID }, query:{ chapterId:exam.topLevelContentID, contentId:exam.id }}) }" class="font-medium red">{{ exam.title ? exam.title : '--' }}</span>

										</div>
										<div class="t-cell" style="fflex: 0.5;">
											<span class="font-medium">{{ exam.result && exam.result.examDate ?
												formatDateWithTime(exam.result.examDate) : "--" }}</span>
										</div>
										<!-- Percentage -->
										<div class="t-cell text-center" style="fflex: 0.8;">
											<span v-if="exam.result && exam.result.passingPercentage && exam.result.percentage >= 0 && completionPercentage(exam) > 0" id="percentage"
												:class="['font-medium',
													{ pass: getResultStatus(exam.result.passingPercentage, exam.result.percentage) },
													{ fail: !getResultStatus(exam.result.passingPercentage, exam.result.percentage) }
												]">{{ exam.result.percentage }}% ({{ getResultStatus(exam.result.passingPercentage, exam.result.percentage)
	? "Pass" : "Fail" }})

												<!-- <div class="dropdown-content">
													<p>
														Passing Percentage: {{ exam.result.passingPercentage }}
													</p>
												</div> -->
											</span>

											<span v-else>N/A</span>
										</div>

										<div  class="t-cell" style="fflex: 0.8;">
											<span class="font-medium">
												{{ completionPercentage(exam) > 0 ? formatProgressPercentage(exam) : 'N/A' }}
												<br />
											</span>
										</div>

										<!-- Attempted -->
										<!-- <div class="t-cell text-center" style="fflex: 0.8;">
											<span
												v-if="exam.result && exam.result.attemptedCorrectly && exam.result.totalQuestions"
												class="font-medium">
												{{ exam.result.attemptedCorrectly }} of {{ exam.result.totalQuestions }}
												<br />
												{{ getNotAttemptedCount(exam.result.attemptedQuestions,
													exam.result.totalQuestions) }}
											</span>
											<span v-else>--</span>
										</div> -->

										<div class="t-cell" style="flex: 1;">
											<button v-if="showAttemptButton(exam)" @click.stop
												@click="handleAttemptClick(exam.id, exam.type)" class="action-button mb-1">Attempt</button>
											<div class="mx-1"></div>

											<button v-if="exam.result && exam.result.id" @click.stop
												@click="review(exam.id, exam.result.id, undefined, exam.type)"
												class="action-button mb-1">Review</button>

										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<!-- </div>/cd-body -->
					<div v-else-if="isDetailedView" class="cd-body round">
						<div class="qr-table-wrapper">
							<ul class="eb-table qr-table">
								<li class="thead">
									<div class="t-cell" style="width: 5%;"><b>#</b></div>
									<div class="t-cell" style="width: 15%;"></div>
									<div class="t-cell" style="width: 40%;"><b>Question</b></div>
									<div class="t-cell text-center" style="width: 20%;"><b>Type</b></div>
									<div class="t-cell text-center" style="width: 10%;"><b>Marks</b></div>
									<div class="t-cell text-center" style="width: 10%;">Action</div>
								</li>
								<li v-for="(detail, index) in examDetail" :key="index + 1" class="t-row">
									<div class="t-cell" style="width: 5%;">{{ index + 1 }}</div>
									<div class="t-cell" style="width: 15%;">
										<img :src="getStatusIconPath(detail.action)" class="status-icon">
									</div>
									<div class="t-cell normal-font" style="width: 40%;">
										<p class="question-text">{{ detail.question.text }} </p>
									</div>
									<div class="t-cell text-center" style="width: 20%;">{{ detail.question.type }}</div>
									<div class="t-cell text-center" style="width: 10%;">
										<b>{{ detail.marksObtained }}</b> / <b>{{ detail.question.marks }}</b>
									</div>
									<div class="t-cell text-center" style="width: 10%;">
										
											<button
												@click="review(examDetailContent.content.id, examDetailContent.id, detail.question.id, detail.type)"
												class="col-7 eb-notes-view">
												<i class="fas fa-eye"></i>
											</button>
									</div>
								</li>
							</ul>
						</div>
					</div>


					<b-modal v-if="showModal" id="modal" @change="toggleModal({ state: false, question: null })"
						:visible="showModal" hide-header centered content-class="modal" size="xl" body-class="modal-body"
						hide-footer modal-class="custom-modal" no-close-on-backdrop>
						<button @click="toggleModal({ state: false, question: null })" class="close-button" role="button">
							<img src="@/assets/images/cancel-icon.svg" />
						</button>

						<div class="eb-modal-content px-3">
							<h1 class="main-heading">Review Question # {{ question.question.caseNo }}</h1>
							<p>
								<span class="col-auto" v-html="question.question.text"></span>
							</p>
							<p>
								Marks: <b>{{ question.marksObtained }}</b> out of <b>{{ question.question.marks }}</b>
							</p>
							<p>
								Type: {{ question.question.type }}
							</p>
							<div class="card keypoint mb-3">
								<div class="card-header"><i class="fa fa-check"></i> Answer</div>
								<div class="card-body mt-3 mb-3">
									<img :src="getStatusIconPath(question.action)" class="status-icon">
									{{ question.action }}
								</div>
							</div>


						</div>
					</b-modal>

				</div><!-- /eb-content -->
			</div><!-- /eb-body -->
		</div><!-- /eb-content-wrapper -->
		<center v-else class="no-content text-center">
			<h4 class="mt-4">No Exams Found</h4>
		</center>
	</div>
</template>


<script>

import { mapActions, mapState } from 'vuex';

import { LegacyExam } from "../../constants/exam";
import Swal from "sweetalert2";
import { toShortDateString } from "../../helpers/FormatHelpers";

export default {
	name: "ProductContentExams",
	data() {
		return {
			isDetailedView: false,
			loadingExam: false,
			loadingResult: false,
			examList: [],
			examDetailContent: null,
			examDetail: [],
			showModal: false,
			question: null,
			productVariantID: null
		};
	},
	methods: {
		...mapActions('exam', ['getExamResultByProductVariant', 'getExamContentResult']),
		...mapActions('content', ['getContentById', 'getUserContentByProductVariant', 'getQuizResultByProductVariantId']),
		...mapActions('quiz', ['getQuizDetail']),
		changeView({ exam = null }) {
			if (!exam) {
				this.isDetailedView = false;
				this.question = null
			}

			if (exam && exam.result && exam.result.id && exam.result.content && exam.result.content.id) {

				let examID = exam.result.id;
				let contentID = exam.result.content.id;

				this.isDetailedView = true

				if (this.isDetailedView) {
					this.fetchExamContentResult({ examID: examID, contentID: contentID, type: exam.type })
				}
			}
			else if(!this.showAttemptButton(exam)) {
				this.showSwalErrorMessage({
					title: `${exam.type.toUpperCase()} Not Attempted!`,
					message: `The ${exam.type} you are trying to view has not been attempted yet`,
				    contentId: exam.id,
					examType: exam.type
				})
			}
			else{	
				this.$router.push({ params:{ page:'chapters', id:this.productVariantID }, query:{ chapterId:exam.topLevelContentID, contentId:exam.id }})
				// this.showSwalErrorMessage({
				// 	title: "Exam Not Attempted!",
				// 	message: "The exam you are trying to view has not been attempted yet",
				// 	buttonText: "Attempt", contentId: exam.id
				// })
			}
		},
		toggleView() {
			this.isDetailedView = !this.isDetailedView
		},
		review(contentId, examId, questionId = undefined, type) {

			if(type == 'quiz') {
				let url = `/product/dashboard/chapters/${this.productVariantID}?contentId=${contentId}&isReviewMode=true&attemptId=${examId}`;
				
				let chapterId = this.$route.query.chapterId;
				if(chapterId) {
					url += `&chapterId=${chapterId}`;
				}
				if(questionId) {
					url += `&questionId=${questionId}`;
				}

				this.$router.push(url);
				return;
			}

			var data = { action: LegacyExam.GET_EXAM_ACTION.REVIEW_EXAM, examid: examId };
			if (questionId) {
				data = { action: LegacyExam.GET_EXAM_ACTION.REVIEW_EXAM, examid: examId, questionid: questionId };
			}
			this.getContentById({
				id: contentId,
				extraParams: data,
			}).then((content) => {
				if (content) {
					var examAttemptUrl =
						content.data.redirectUrl + "&RedirectUrl=" + window.location.href;
					window.open(examAttemptUrl, "_blank");
					return;
				}
			});
		},
		async fetchResultProductVariant(type) {
			if(type == 'exam') {
				this.loadingResult = true;
				await this.getExamResultByProductVariant({ productVariantID: this.$route.params.id })
					.then((data) => {
						const tempExamList = this.examList.map(exam => ({ ...exam }));

						for (const result of data) {
							for (const exam of tempExamList) {
								if (result.content.id === exam.id) {
									exam.result = result;
								}
							}
						}
						tempExamList.sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10));
						this.examList = tempExamList;
						this.loadingResult = false;
					})
					.catch((error) => {
						console.error("Error fetching result:", error);
						this.loadingResult = false;
					});
			}
			else if(type == 'quiz') {
				await this.getQuizResultByProductVariantId(this.$route.params.id).then((res) => {
					
					const tempExamList = this.examList.map(exam => ({ ...exam }));

					for (const result of res) {
						for (const exam of tempExamList) {
							if (result.content.id === exam.id) {
								result.percentage = result.overallPercentage;
								result.examDate = result.attemptedAt;
								result.attemptedCorrectly = result.noOfCorrectQuestions ? result.noOfCorrectQuestions : "0";
								result.totalQuestions = result.noOfQuestions ? result.noOfQuestions : "0";
								result.attemptedQuestions = result.noOfAttemptedQuestions ? result.noOfAttemptedQuestions : "0";

								exam.result = result;
							}
						}
					}

					this.examList = tempExamList;
					this.loadingResult = false;
				})
			}
			
			
		},

		fetchExamContentResult({ examID, contentID, type }) {
			this.loadingExam = true;

			if(type == "quiz") {
				this.getQuizDetail(examID).then((res) => {
					if(res && res.length > 0) {
						this.examDetailContent = res[0];
						this.examDetailContent.content = {};
						this.examDetailContent.content.id = res[0].contentID;
						this.examDetail = res[0].quizAttemptDetails;

						for (let detail of this.examDetail) {
							var tempContainer = document.createElement('div');
							tempContainer.innerHTML = detail.questionText;
							var tempQuill = new Quill(tempContainer);
							var text = tempQuill.getText();
							detail.question = {};
							detail.question.text = text.substring(0, text.length > 140 ? 140 : text.length);
							detail.marksObtained = detail.action && detail.action.toLowerCase() == 'correct' ? detail.obtainedMarks : 0;
							detail.question.type = detail.type;
							detail.question.marks = detail.marks;
							detail.question.id = detail.id;
							detail.type = type;
						}
					}
					this.loadingExam = false;
				}).catch((err) => {
					this.loadingExam = false;
				});
				return;
			}

			this.getExamContentResult({ examID: examID, contentID: contentID }).then((data) => {

				this.examDetailContent = data;
				this.examDetail = data.examDetail;
				this.examDetail.sort(this.compareQuestionCaseNo);

				for (let detail of this.examDetail) {
					var tempContainer = document.createElement('div');
					tempContainer.innerHTML = detail.question.text;
					var tempQuill = new Quill(tempContainer);
					var text = tempQuill.getText();
					detail.question.text = text.substring(0, text.length > 140 ? 140 : text.length);
				}

				this.loadingExam = false;
			}).catch((error) => {
				console.error("Error fetching result:", error);
				this.loadingExam = false;
			});;
		},
		getStatusIconPath(action) {
			return action === 'Correct' ? require('@/assets/images/check-icon-green-circle.svg') : require('@/assets/images/cross-icon.svg');
		},
		toggleModal({ state, question }) {
			this.question = question;
			if (state !== undefined) {
				this.showModal = state;
			} else {
				this.showModal = !this.showModal
			}
		},
		getNotAttemptedCount(attemptedQuestions, totalQuestions) {
			var attempted = parseInt(attemptedQuestions);
			var total = parseInt(totalQuestions);

			var notAttempted = total - attempted;

			return notAttempted > 0 ? `(${notAttempted} Not Attempted)` : '';
		},
		getResultStatus(passingPercentage, percentage) {
			if(!passingPercentage || percentage == null || percentage == undefined) {
				return false;
			}
			 
			var current = parseInt(percentage);
			var total = parseInt(passingPercentage);

			var pass = current >= total;

			return pass;
		},
		compareQuestionCaseNo(a, b) {
			return parseInt(a.questionCaseNo) - parseInt(b.questionCaseNo);
		},
		async getExamList() {

			this.loadingExam = true;
			this.examList = [];

			await this.getUserContentByProductVariant({
				productVariantID: this.$route.params.id,
				dataType: 'exam'
			}).then((data) => {
				if (this.productVariants && this.productVariants.items && this.productVariants.items[this.$route.params.id + ""]) {
					var selectedProductVariant = this.productVariants.items[this.$route.params.id + ""];
					for (let item of data) {
						if (selectedProductVariant.productVariantItems) {
							var title = selectedProductVariant.productVariantItems.find((x) => x.id == item.topLevelContentID);
							title = title ? title.title : "Unknown";
						} else {
							title = "Unknown";
						}
						debugger
						var exam = {
							chapterName: title,
							...item,
						};

						exam.type = "exam";
						this.examList.push(exam);
					}
				}

				this.fetchResultProductVariant('exam');

				this.loadingExam = false;

			}).catch((error) => {
				console.error("Error fetching result:", error);
				this.loadingExam = false;
			});;

			await this.getUserContentByProductVariant({
				productVariantID: this.$route.params.id,
				dataType: 'quiz'
			}).then((data) => {
				if (this.productVariants && this.productVariants.items && this.productVariants.items[this.$route.params.id + ""]) {
					var selectedProductVariant = this.productVariants.items[this.$route.params.id + ""];
					for (let item of data) {
						if (selectedProductVariant.productVariantItems) {
							var title = selectedProductVariant.productVariantItems.find((x) => x.id == item.topLevelContentID);
							title = title ? title.title : "Unknown";
						} else {
							title = "Unknown";
						}
						var quiz = {
							chapterName: title,
							...item,
						};

						quiz.type = "quiz";
						this.examList.push(quiz);
					}
				}
			
				this.fetchResultProductVariant('quiz');
				
				this.loadingExam = false;
				
			}).catch((error) => {
				console.error("Error fetching result:", error);
				this.loadingExam = false;
			});
		},
		showAttemptButton(exam) {
			if (!exam) {
				return true;
			}

			const { availableFrom, availableTo } = exam;

			if (!availableFrom || !availableTo) {
				return true;
			}

			const currentDate = Date.now();
			return this.isBetweenDates(currentDate, availableFrom, availableTo);
		},
		isBetweenDates(checkDate, startDate, endDate) {

			const start = new Date(startDate)
			const end = new Date(endDate)

			return checkDate > start && checkDate < end
		},
		showSwalErrorMessage({ title, message, buttonText = null, contentId = null, examType }) {
			const swalOptions = {
				title: title,
				text: message,
				icon: "error",
				showCancelButton: true,
				cancelButtonText: "Cancel",
				cancelButtonColor: "#D3D3D3",
				dangerMode: true,
				allowOutsideClick: true,
				allowEscapeKey: true,
			};

			if (buttonText !== null) {
				swalOptions.confirmButtonText = buttonText;
				swalOptions.confirmButtonColor = "#FF0000";
			} 

			Swal.fire(swalOptions).then((result) => {
				if (contentId && result.isConfirmed && buttonText != null) {
					this.handleAttemptClick(contentId, examType);
				}
			});
		}

		,


		handleAttemptClick(contentId, type) {
			if(type == "quiz") {
				this.getContentById({
					id: contentId
				}).then((content) => {
					if (content) {
						let url = `/product/dashboard/chapters/${this.productVariantID}?contentId=${contentId}`;
						let chapterId = this.$route.query.chapterId;

						if(chapterId) {
							url += `&chapterId=${chapterId}`;
						}
						this.$router.push(url);
					}
				});
				return;
			}

			this.getContentById({
				id: contentId,
				extraParams: { action: LegacyExam.GET_EXAM_ACTION.ATTEMPT },
			}).then((content) => {
				if (content) {
					var examAttemptUrl =
						content.data.redirectUrl +
						"&RedirectUrl=" +
						window.location.href;
					window.open(examAttemptUrl, "_blank");
					return;
				}
			});
		},
		formatDateWithTime(datetime) {
			if(!datetime) {
				return "--"
			}

			let date = new Date(datetime);
			var hours = date.getHours();
			var minutes = date.getMinutes();
			var ampm = hours >= 12 ? "PM" : "AM";
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			minutes = minutes < 10 ? "0" + minutes : minutes;
			var strTime = hours + ":" + minutes + " " + ampm;
			return toShortDateString(date) + "  " + strTime;
		},

		completionPercentage(exam) {
			if (
				exam.result &&
				exam.result.attemptedCorrectly &&
				exam.result.totalQuestions 
			) {
				const percentage = (exam.result.attemptedCorrectly / exam.result.totalQuestions) * 100;
				return percentage;
			}
			return 0;
			},

		formatProgressPercentage(exam) {
			if (
				exam.result &&
				exam.result.attemptedCorrectly &&
				exam.result.totalQuestions 
			) {
				const percentage = (exam.result.attemptedCorrectly / exam.result.totalQuestions) * 100;
				return percentage.toFixed(2) + '%';
			}
			return '--';
			},
	},
	computed: {
		...mapState("productVariant", ["productVariants"]),
	},
	watch: {
		$route(to, from) {
			if (this.$route.params.id && this.productVariantID != this.$route.params.id) {
				this.productVariantID = this.$route.params.id;
				this.getExamList();
			}

		}
	},

	components: {},
	created() {
		if (this.$route.params.id && this.productVariantID != this.$route.params.id) {
			this.productVariantID = this.$route.params.id;
			this.getExamList();
		}
	},
	mounted() {
	}
};
</script>

<style scoped>
.cd-body{
	height: auto;
}

.eb-content-wrapper {
        background-color: var(--white);
        margin-bottom: 0px !important;
		padding: 20px 0px 0px 20px;
        height: calc(100dvh - var(--header-height));
        overflow-y: scroll;
		overflow-x: hidden;
}

.pc-table-wrapper{
	font-size: 1rem;
}
.eb-head-progress .progress {
	height: 14px;
}

.progress-bar {
	background-color: var(--color-red);
}

.pc-table .t-row {
	cursor: pointer;
}

.pc-table .t-row:hover {
	background-color: var(--gray-light-hover);
}

.pc-table>li>.t-cell {
	flex: 1 1 100;
	align-items: center;
	flex-direction: column;
	display: flex;
	padding: 15px 5px;
	border-bottom: 1px solid var(--border-color);
}


.pc-table>li>.t-cell:nth-child(1) {
	flex: 0.2;
	padding-left: 10px;
}

.pc-table>li>.t-cell:nth-child(2) {
	flex: 2;
	align-items: flex-start;
}

.pc-table>li>.t-cell:nth-child(3) {
	flex: 0.75;
}

.pc-table>li>.t-cell:nth-child(4) {
	padding-left: 10px;
}

.pc-table>li>.t-cell:last-child {
	flex: 1.2;
}

.t-cell a {
	font-size: 12px;
}

.t-cell b {
	font-size: 13px;
}


.pass {
	color: var(--color-success);
}

.fail {
	color: var(--color-red);
}


.action-button {
	font-size: 12px;
	padding: 3px 10px;
	border: 1px solid var(--color-red);
	background-color: transparent;
	border-radius: 10px;
	color: var(--color-text-default);
	cursor: pointer;
	transition: background-color 0.3s, color 0.3s, transform 0.2s;
}

.action-button:hover {
	background-color: var(--color-red);
	color: #fff;
	transform: scale(1.1);
}

.eb-table-arr .t-row {
	position: relative;
}

.eb-table-arr .t-row:before {
	position: relative;
	content: "\f101";
	font-family: "Font Awesome 5 Free";
	-webkit-font-smoothing: antialiased;
	display: block;
	font-style: normal;
	font-variant: normal;
	position: absolute;
	top: 50%;
	margin-top: -15px;
	right: 10px;
	font-size: 20px;
	font-weight: 700;
	-webkit-text-stroke: 1px #fff;
	opacity: 0;
	visibility: hidden;
}

.eb-table-arr .t-row:hover:before {
	opacity: 1;
	visibility: visible;
}

.qr-table>li>.t-cell {
	flex: 0.8;
	padding: 15px 5px;
	border-bottom: 0.3px solid var(--border-color);
	border-top: 0.3px solid var(--border-color);
}

.qr-table>li>.t-cell:nth-child(1),
.qr-table>li>.t-cell:nth-child(2) {
	flex: 0.15;
	text-align: center;
}

.qr-table>li>.t-cell:nth-child(2) {
	flex: 0.5;
}

.qr-table>li>.t-cell:nth-child(3) {
	flex: 5;
}

.qr-table>li>.t-cell:last-child {
	flex: 1.1;
}

.qr-table>li>.t-cell:nth-last-child(2) {
	flex: 0.6;
}

.practice-tabs .eb-header {
	height: auto;
}

.eb-table .status-icon {
	width: 24px;
	height: auto;
}


.no-content {
	margin-top: 50px;
	margin-bottom: 50px;
}

.loading {
	margin-top: 250px;
	margin-bottom: 250px;
}

.ebook-digital .transparent {
	background: transparent;
}

.question-text {
	width: 95%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	border-bottom: none !important;
}

.card-body img {
	width: 50px;
	height: 30px;
}

#backButton {
	margin-top: 4px;
	margin-right: 20px;
	font-size: 14px;
}

.normal-font {
	font-weight: normal !important;
}

/* dropdown */
#percentage {
	position: relative;
	/* Add relative positioning to the parent */
}

#percentage .dropdown-content {
	display: none;
	position: absolute;
	background-color: #f1f1f1;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 999;
	top: 100%;
	border-radius: 12px;
	right: 0;
	left: -40px;
}

#percentage .dropdown-content p {
	color: black;
	padding: 13px 16px;
	margin: 0;
	text-decoration: none;
	display: block;
}

#percentage:hover .dropdown-content {
	display: block;
	/* Show the dropdown when hovering over #percentage */
}

.swal2-primary {
	border: 1px solid black;
	background: var(--color-red);
	color: var(--color-red) !important;
}

.swal2-outline {
	border: 1px solid var(--color-red);
	background: transparent !important;
	color: var(--color-red) !important;
}

@media (max-width:768px){
	.eb-header{
		height: auto;
	}

	.ebook-digital .cd-body  {
		min-height: auto;
	}
	.pc-table-wrapper{
		overflow: auto;
	}
	.pc-table{
		min-width: 600px;
	}
	.pc-table>li.thead>.t-cell{
		white-space: nowrap;
	}
	.pc-table>li>.t-cell:nth-child(2) {
		flex: 1.6;
	}

    .ebook-digital .eb-content-wrapper {
        background-color: var(--white);
        padding: 20px 0px 0px 0px;
        margin-bottom: 0px !important;
        min-height: calc(100dvh - var(--header-height) - 60px);
        overflow: scroll;
    }
}
</style>
