<template>
  <div class="vue-maindiv">
    <div v-if="!$route.query.categoryId">
      <div class="content">
        <div class="banner">
          <div>
            <h1 class="bannerheading">ICAP Digital Study Material</h1>
          </div>
          <div class="listmain">
            <div
              class="accordion accordion-flush accordiancustom"
              id="accordionFlushExample"
            >
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed accordianlist"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#myModal"
                  >
                    How to use the digital Study Material
                  </button>
                </h2>
              </div>

              <!-- Modal -->
              <div
                class="modal fade"
                id="myModal"
                tabindex="-1"
                aria-labelledby="myModalLabel"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
                >
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="myModalLabel">
                        Digital Study Material
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <!-- Your content here -->
                      <!-- <p>This is placeholder content for the modal. You can replace this with your actual study material
                    instructions or information.</p> -->
                      <div class="ratio ratio-16x9">
                        <iframe
                          src="https://www.youtube.com/embed/_oeXSSK47OY"
                          title="YouTube video"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed accordianlist"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Digital Study Material
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div
                    v-for="item in categories"
                    :key="item.id"
                    class="accordion-body"
                  >
                    <div class="mx-2" style="font-size: 1.2rem" @click="onCategoryClick(item.id, item.title)">
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed accordianlist"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    FAQs
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Placeholder content for this accordion
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="dummycarousel mt-5">
        <div class="carousel-container">
        
          <div class="carousel__list mt-4">
            <div class="carousel__item wide" style="--position: 1"></div>
            <div class="carousel__item rivh" style="--position: 2"></div>
            <div class="carousel__item optimal" style="--position: 3"></div>
            <div class="carousel__item effortless" style="--position: 4"></div>
            <div class="carousel__item multicolor" style="--position: 5"></div>
            <div class="carousel__item note" style="--position: 6"></div>
            <div class="carousel__item audio" style="--position: 7"></div>
            <div class="carousel__item progresss" style="--position: 8"></div>
            <div class="carousel__item offline" style="--position: 9"></div>
            <div class="carousel__item future" style="--position: 10"></div>
            
          </div>
        </div>
      </div> -->
      <div class="dummycarousel mt-5">
        <vue-slick-carousel v-bind="settings" class="carousel-container">
          <div
            v-for="(item, index) in carouselItems"
            :key="index"
            :class="item.class"
            class="carousel__item"
          >
            <span>{{ item.text }}</span>
          </div>
        </vue-slick-carousel>
      </div>
    </div>
    <div v-else>
      <div class="dashboard">
        <div class="dashboard-content">
          <div class="d-flex align-items-center gap-3 backGoCategory">
            <i
              @click="toggle"
              class="fas fa-arrow-circle-left pageBackDashboard"
            ></i>
            <h1 class="bannerheading dashboardheading">{{ title }}</h1>
          </div>
          <div class="tablecards">
            <div class="table-container" style="flex-grow: 3;">
              <table>
                <thead>
                  <tr class="tableheading">
                    <th>Subject</th>
                    <th style="width:30%;">Progress</th>
                  </tr>
                </thead>
                <center v-if="loading" class="loading">
                  <b-spinner large></b-spinner>
                </center>
                <h2 v-else-if="getProductVariants <= 0" class="mt-2 ms-1">
                  No Ebooks found
                </h2>
                <tbody v-else>
                  <tr
                    v-for="item in getProductVariants"
                    :key="item.id"
                    class="spaceUnder"
                  >
                    <td @click="handleClick($event, item)" class="subject">
                      {{ item.product.title }} ({{ item.title }})
                      <span>
                        <!-- {{ item.subscriptionStatus }} -->
                        <i
                          v-if="item.subscriptionStatus === 'Unenrolled'"
                          class="fas fa-lock"
                        ></i>
                      </span>
                    </td>
                    <td class="time">
                      <!-- <span class="hours">08</span> <span>Hours</span> -->
                      <center v-if="loadingProgress">
                        <b-spinner medium></b-spinner>
                      </center>
                      <div v-if="!loadingProgress && (checkTheme('ebook', item) || checkTheme('ebookdigital', item))" class="eb-item-progress">
                        <div class="eb-item-progress-label mb-1">
                          <span id="progressVal">{{ progress(item.id) }}</span>%
                        </div>
                        <div class="progress mb-1" style="border-radius: 1rem;">
                          <div class="progress-bar" role="progressbar" :style="`height: 100%; width: ${progress(item.id)}%`" aria-valuenow="25"
                            aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <!-- <tr class="spaceUnder">
                    <td class="subject">Quantitative Methods</td>
                    <td class="time">
                      <span class="hours"> 05</span> <span>Hours</span>
                    </td>
                  </tr>
                  <tr class="spaceUnder">
                    <td class="subject">Principles of Economics</td>
                    <td class="time">
                      <span class="hours"> 01</span> <span>Hours</span>
                    </td>
                  </tr>
                  <tr class="spaceUnder">
                    <td class="subject">Introduction to Accounting</td>
                    <td class="time">
                      <span class="hours"> 07</span> <span>Hours</span>
                    </td>
                  </tr>
                  <tr class="spaceUnder">
                    <td class="subject">Introduction to Business</td>
                    <td class="time">
                      <span class="hours"> 07</span> <span>Hours</span>
                    </td>
                  </tr> -->
                </tbody>
              </table>
            </div>
            <div class="icons" style="flex-grow: 1;">
              <div class="NewsIcon tableicon">
                <img
                  src="../../../public/Icap/Images/Assets_Updated/Mobile_Horizantal/Other Btn/News_&_Updates_Updated_1.svg"
                  alt="News Icon"
                  class="NewsIconSvg"
                />
                <h6 class="text-center font-weight-bold">Coming Soon...</h6>
              </div>
              <div class="PlannerIcon tableicon">
                <img
                  src="../../../public/Icap/Images/Assets_Updated/Mobile_Horizantal/Other Btn/PlannerUpdated.svg"
                  alt=""
                  class="PlannerIconSvg"
                />
                <h6 class="text-center font-weight-bold">Coming Soon...</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions, mapState } from "vuex";
import { parseForURL } from "../../helpers/FormatHelpers";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

import {
  ProductVariantStatus,
  ProductVariantSubscriptionStatus,
} from "../../constants/productVariant";
import { arrow } from "@popperjs/core";


export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      title: "",
      categoryId: "",
      loading: true,
      loadingProgress: true,
      ProductVariantSubscriptionStatus,
      settings: {
        arrow: true,
        // dot: true,
        infinite: true,
        // initialSlide: 2,
        speed: 500,
        slidesToShow: 10,
        slidesToScroll: 1,
        swipeToSlide: true,
        autoplay: true,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 5,
            },
          },
        ],
      },
      carouselItems: [
        { class: "wide", text: "Wide Compatibility" },
        { class: "rivh", text: "Rich Multimedia Content" },
        { class: "optimal", text: "Optimal Readability" },
        { class: "effortless", text: "Effortless Navigation" },
        { class: "multicolor", text: "Multi-Color Highlighting" },
        { class: "note", text: "Notes-Taking" },
        { class: "audio", text: "Audio Read-Out" },
        { class: "progresss", text: "Progress Tracking" },
        // { class: "offline", text: "Offline Capability" },
        { class: "future", text: "Future Updates" },
        // { class: "optimal", text: "Optimal Readability" },
      ],
    };
  },

  watch: {
    $route(to, from) {
			if (!this.categoryId || this.$route.query.categoryId) {
				this.initializePage();
			}
    }
  },
 
  methods: {
    ...mapActions("category", ["fetchCategories"]),
    ...mapActions("productVariant", ["getProductVariantByCategoryId","getProductVariantProgress"]),
    ...mapActions("user", ["fetchCurrentUser"]),
    
    toggle() {
      this.$router
        .push({
          path: "/user/dashboard",
          query: this.$route.query.categoryId
            ? {}
            : {
                categoryId: this.categoryId
                  ? this.categoryId
                  : this.$route.query.categoryId,
              },
        })
        .catch((error) => {});
    },
    async onCategoryClick(id, title) {
      console.log(id);
      this.title = title;
      this.categoryId = id;

      if (this.categoryId) this.toggle();
    },

    isProductVariantBlocked(productVariant) {
      if(productVariant?.subscriptionStatus == null) return true;

      return (
        productVariant?.subscriptionStatus ==
        ProductVariantSubscriptionStatus.BLOCKED
      );
    },
    isProductVariantEnded(productVariant) {
      return productVariant.endDate
        ? new Date(productVariant.endDate) < new Date()
        : false;
    },

    isProductVariantExpired(productVariant) {
      const actualExpiry = this.getExpiryDate(productVariant);
      return actualExpiry ? actualExpiry < new Date() : false;
    },

    getExpiryDate(productVariant) {
      let actualExpiry = null;
      const endDate = productVariant?.endDate;
      const expiresAt = productVariant?.expiresAt;

      if (expiresAt) {
        actualExpiry = new Date(expiresAt);
      }

      if (
        (endDate && expiresAt && new Date(endDate) < new Date(expiresAt)) ||
        (!expiresAt && endDate)
      ) {
        actualExpiry = new Date(endDate);
      }

      return actualExpiry;
    },

    handleClick(e, productVariant) {
      productVariant = this.getUserProductVariant(productVariant.id);

      if (
        this.isProductVariantBlocked(productVariant) ||
        this.isProductVariantEnded(productVariant) ||
        this.isProductVariantExpired(productVariant)
      ) {
        e.stopPropagation();
        e.preventDefault();
        Swal.fire({
          title: this.isProductVariantBlocked(productVariant)
            ? "Course Blocked!"
            : this.isProductVariantEnded(productVariant)
            ? "Course ended!"
            : this.isProductVariantExpired(productVariant) &&
              productVariant.enrollmentType &&
              productVariant.enrollmentType.toLowerCase() == "directenrollment"
            ? "Course Access Expired!"
            : this.isProductVariantExpired(productVariant)
            ? "Payments due!"
            : "",
          text: this.isProductVariantBlocked(productVariant)
            ? "Your course has been blocked. Please contact admin for more information"
            : this.isProductVariantEnded(productVariant)
            ? "This course has ended, you can no longer view its contents"
            : this.isProductVariantExpired(productVariant) &&
              productVariant.enrollmentType &&
              productVariant.enrollmentType.toLowerCase() == "directenrollment"
            ? "Your course access has been expired. Please contact admin for more information "
            : this.isProductVariantExpired(productVariant)
            ? "You have pending unpaid invoices for this course. Please clear your dues to access this course "
            : "",
          icon: "error",
          type: "error",
          preConfirm:
            this.isProductVariantExpired(productVariant) &&
            productVariant.enrollmentType.toLowerCase() == "pricingplan"
              ? () => {
                  this.$router.push("/user/invoices");
                }
              : null,
          confirmButtonText:
            this.isProductVariantExpired(productVariant) &&
            productVariant.enrollmentType.toLowerCase() == "pricingplan"
              ? "View Invoices"
              : "OK",
          showCancelButton: this.isProductVariantExpired(productVariant),
        });
        return;
      }

      this.$router.push({
        path: this.getProductDetailsUrl(productVariant) + productVariant.id,
        query: { categoryId: this.categoryId },
      });
    },

    getUserProductVariant(productVariantID) {
      return (
        this.user.productVariants &&
        this.user.productVariants.find((x) => x.id == productVariantID)
      );
    },

    purchased(productVariant) {
      if (
        this.user &&
        ((this.user.type && this.user.type.toLowerCase() == "student") ||
          (this.user.role && this.user.role.toLowerCase() == "student")) &&
        this.user.productVariants.length > 0
      ) {
        var found = this.user.productVariants.find(
          (p) => p.id == productVariant.id
        );
        return found ? true : false;
      }
    },

    getProductDetailsUrl(productVariant) {
      if (this.validateThemeRoute("ebookdigital", productVariant)) {
        return "/ebook/dashboard/chapters/";
      } else if (this.validateThemeRoute("ebook", productVariant)) {
        return "/product/dashboard/chapters/";
      } else if (this.purchased(productVariant)) {
        return (
          "/product/content/" +
          parseForURL(productVariant.product.friendlyTitle) +
          "-"
        );
      } else {
        return (
          "/product/details/" +
          parseForURL(productVariant.product.friendlyTitle) +
          "-"
        );
      }
    },

    validateThemeRoute(themeName, productVariant) {
      return (
        productVariant &&
        this.purchased(productVariant) &&
        productVariant.properties &&
        productVariant.properties.contentViewTheme &&
        productVariant?.properties?.contentViewTheme?.toLowerCase() ===
          themeName?.toLowerCase()
      );
    },

    progress(productVariantID) {

			if (this.productVariantsProgress && this.productVariantsProgress[productVariantID]) { 
				var progressOB = this.productVariantsProgress[productVariantID];
				return Math.round((progressOB.completedTotalContent / progressOB.totalContent) * 100);
			}
      else{
       const localProgresses = localStorage.getItem(`progresses-${this.user.id}`)
       
       if(localProgresses){ 
        const localProgressesParsed = JSON.parse(localProgresses || '{}');
        var progressOB = localProgressesParsed[productVariantID];
        
        if(progressOB && progressOB.completedTotalContent && progressOB.totalContent){
				  return Math.round((progressOB.completedTotalContent / progressOB.totalContent) * 100);
        }
      }
      }
			return 0;
		},

    checkTheme(themeName, productVariant) {
      return (
        productVariant &&
        productVariant.properties &&
        productVariant.properties.contentViewTheme &&
        productVariant?.properties?.contentViewTheme?.toLowerCase() ===
          themeName?.toLowerCase()
      );
    }
  },

  computed: {
    ...mapState("category", ["categories"]),
    ...mapState("productVariant", ["productVariantsByCategory", "productVariantsProgress"]),
    ...mapState("user", ["user"]),

    getProductVariants() {
      return (
        this.$route.query.categoryId &&
        this.productVariantsByCategory[this.$route.query.categoryId]
      );
    },

      async loadProductVariants() {
        this.loading = true;
        this.loadingProgress = true;

        try {
          const categoryId = this.$route.query.categoryId;

          if (!this.productVariantsByCategory[categoryId] || this.productVariantsByCategory[categoryId].length === 0) {
            await this.getProductVariantByCategoryId(categoryId);
          }

          const localProgresses = localStorage.getItem(`progresses-${this.user.id}`);
          this.loadingProgress = localProgresses === null || localProgresses === undefined;

          // fetch even if we have progress locally save for upddated progress
          this.getProductVariantProgress().then(() => { 
            localStorage.setItem(`progresses-${this.user.id}`, JSON.stringify(this.productVariantsProgress));
            this.loadingProgress = false; 
          });

        } catch (error) {
        } finally {
          this.loading = false;
        }
      },

    async initializePage(){

    if(!this.user){ await this.fetchCurrentUser(); }

    if (this.categories.length < 1) { await this.fetchCategories() }   
    

    if(this.$route.query.categoryId){
      const category = (this.categories || []).find(
        (x) => x.id + "" === this.$route.query.categoryId + ""
      );
      
      this.categoryId = category.id;
      this.title = category.title;

      this.loadProductVariants();
    }
    }

    // isCourseListVisible() {
    // 	return (this.$route.query.courseList + "") === 'true';
    // },
  },
  async mounted() {

  },
  created() {
    this.initializePage();
  },
};
</script>

<style>
footer {
  display: none;
}
.student-main {
  padding-top: 0 !important;
}
.carousel-container .slick-arrow:before {
  font-size: 20px;
  opacity: 1;
  color: #185698;
}
.carousel-container span {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: #333;
}
.slick-prev {
  left: 12px !important;
}
.slick-slider {
  padding-left: 60px;
  padding-right: 60px;
}
.slick-slide {
  width: 80px;
}
@media (max-width: 1200px) {
  .slick-arrow {
    top: 20%;
  }
}
@media (max-width: 1170px) {
  .carousel-container span {
    font-size: 0.65rem;
  }
}
@media (max-width: 1100px) {
  .slick-slider {
    padding-left: 40px;
  }
  .carousel-container span {
    font-size: 0.6rem;
  }
}
@media (max-width: 992px) {
  .carousel__item {
    font-size: 4rem !important;
  }
  .carousel-container span {
    display: -webkit-inline-box;
  }
}
@media (max-width: 950px) {
  .slick-slider {
    padding-left: 35px;
    padding-right: 35px;
  }
  .slick-arrow {
    top: 30%;
  }
}
@media (max-width: 767px) {
  .slick-slider {
    padding-left: 40px;
  }
}
</style>

<style scoped>
.slick-prev {
  /* font-size: 20px !important;
  opacity: 1 !important;
  color: red !important; */
  border: 1px solid black !important;
  border-radius: 10px;
  background: url(http://localhost:8080/img/Effortless_Navigation.9e013247.svg);
}
@font-face {
  font-family: "Montserrat Variable";
  src: url("../../../public/Icap/font/Montserrat-VariableFont_wght.ttf")
    format("truetype");
  font-style: normal;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat Variable" !important;
}

.header {
  border-bottom: 1px solid silver;
}
.logo {
  height: 56px;
}
.search-form {
  width: 74vw;
  /* border: 1px solid gray; */
  padding: 5px;
  border-radius: 10px;
  /* background-color: #d8dbe0 !important; */
  background-color: #fafafa;
  box-shadow: 0 3px 6px #dbdbdb29 !important;
}
.search-header {
  border: none !important;
  outline: none;
  background-color: transparent !important;
  font-weight: 500 !important;
}
.search-icon {
  border: none !important;
  outline: none;
  background-color: transparent !important;
}
.user-icon {
  color: #fff;
  background-color: #185698;
  /* border-radius: 18px; */
  padding: 8px;
}
.welcome-user {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  /* width: 15vw; */
}
.welcome-user1 {
  display: none;
}
.welcome-message p {
  margin: 0;
  margin-left: 0.5rem;
  font-weight: bold;
  color: #185698;
}
.welcome-message :nth-child(2) {
  font-weight: normal;
  color: #000;
}
.toggle {
  border: none !important;
}
.header-upside {
  display: flex;
}
.mainheading {
  display: none;
}

@media (max-width: 994px) {
  .logo {
    margin-left: 10px;
  }
  .tablet-header {
    display: flex;
    align-items: center;
  }
  .welcome-user {
    display: none;
  }
  .welcome-user1 {
    display: flex;
  }
  .search-form {
    flex-direction: row-reverse;
    /* width: 62vw; */
    height: 35px;
    width: 38vw;
  }
  .header-upside {
    margin: 0;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
  }
  .heading_search {
    display: flex;
    flex-direction: row;
  }
  .mainheading {
    margin-bottom: 0.5rem;
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
    margin-left: 10px;
    font-family: "Montserrat Variable" !important;
  }
  .mainheading p {
    text-align: center;
    margin: 0;
    color: #185698;
    font-size: 22px;
    font-weight: 500;
    line-height: 0.75;
    font-family: "Montserrat Variable" !important;
  }
  .content {
    height: 35vh !important;
  }

  .banner {
    position: relative;
  }
  .listmain {
    position: absolute;
    bottom: 0;
  }
  .accordianlist {
    font-size: 1rem !important;
  }
  .accordion-item {
    background-color: #f4f7f8 !important;
    margin-bottom: 5px !important;
  }
}

@media (max-width: 1365px) {
  .welcome-message p {
    font-size: 12px;
  }
}
/* @media (max-width: 1230px) {
  .welcome-message p {
    font-size: 1px;
  }
} */

@media (max-width: 767px) {
  .logo {
    margin: 0.5rem;
  }
  .toggle {
    margin-bottom: 32px;
  }
  .search-form {
    padding: 0px;
    border: 1px solid silver;
    background-color: #fafafa;
    align-self: center;
    margin: 1rem 0;
    width: 80vw;
  }
  .users-icon {
    width: 6px;
    margin-left: 0.5rem;
  }
  .user-icon {
    padding: 5px;
  }
  .welcome-user1 {
    display: none;
  }
  .welcome-user {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 2rem !important;
    margin-top: -32px;
  }
  .welcome-message p {
    text-align: right;
  }
  .header-upside {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .heading_search {
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  .mainheading {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
}

/* Content */
.content {
  width: 100vw;
  height: 60vh;
}
.banner {
  width: 100%;
  height: 100%;
  background: url('https://lms-icap.b-cdn.net/assets/website_images/hero_banner_big.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bannerheading {
  color: #185698;
  font-family: "Montserrat Variable" !important;
  font-size: 2.85rem !important;
}
.mainlist {
  width: max-content;
}

.listbutton {
  text-align: left;
  background-color: transparent !important;
  border: transparent !important;
  font-size: 1.75rem !important;
  color: gray !important;
}
.listbutton + ul {
  background-color: transparent !important;
}
.mainlist .listbutton::before {
  content: "•"; /* Unicode character for bullet */
  position: absolute;
  left: -20px; /* Adjust as necessary to align the bullet */
  font-size: 1.2em; /* Adjust bullet size as needed */
}

.mainlist .btn-group .listbutton {
  flex: none;
}

.dropend:hover .dropdown-toggle::after {
  visibility: visible;
}

.dropend .dropdown-toggle::after {
  visibility: hidden;
}

/* Accordian */

.accordion-item {
  background-color: transparent;
  font-family: "Montserrat Variable" !important;
}

.accordion-button {
  background-color: transparent !important;
}

.accordianlist {
  font-size: 1.6rem !important;
  color: gray !important;
}
.accordion {
  --bs-accordion-bg: transparent !important;
  background-color: transparent !important;
  width: max-content;
}
.accordion-body {
  cursor: pointer;
}
.accordion-button:not(.collapsed)::after {
  transform: rotate(-90deg);
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-button::before {
  content: "•"; /* Unicode character for bullet */
  position: absolute;
  left: -20px; /* Adjust as necessary to align the bullet */
  font-size: 1.2em; /* Adjust bullet size as needed */
}
.accordion-button::after {
  margin-left: 10px !important;
}
.accordion {
  padding: 0.2rem 0.3rem !important ;
  --bs-border-color: none !important;
}
.accordion-body {
  padding: 0.2rem 1.25rem !important ;
  font-size: 1.2rem;
}
.accordion-header {
  font-weight: 400;
}
.accordion-button:not(.collapsed) {
  background-color: transparent !important;
}
.accordianlist::after {
  background-image: url("https://icons.getbootstrap.com/assets/icons/chevron-right.svg") !important;
}
.accordion-collapse {
  max-height: 15vh;
  overflow-y: auto;
}

@media (max-width: 992px) {
  .accordianlist {
    font-size: 1rem !important;
  }
  .banner {
    position: relative;
    background-position: top right;
    background-size: cover;
  }
  .bannerheading {
    display: none;
  }
  .dashboardheading {
    display: block;
  }
  .accordiancustom {
    position: absolute;
    top: 20px;
  }
  .accordion {
    width: 80vw;
    --bs-accordion-btn-padding-y: 0.5rem !important;
  }
  .accordion-button {
    font-size: 1.2rem !important;
  }
  .accordion-button::before {
    background-color: #f4f7f8 !important;
    /* padding: 0.42rem 0.5rem; */
    padding: 0.85rem 0.75rem;
  }
  .accordion-collapse {
    max-height: 12vh;
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .accordiancustom {
    left: -30px;
  }
  .content {
    height: 30vh !important;
  }
  .accordion-body {
    font-size: 1rem;
  }
}
@media (max-width: 470px) {
  .accordion-button::before {
    background-color: transparent !important;
  }
}

.carousel-container {
  width: 100vw;
}

.carousel__item {
  width: 4rem;
  height: 3rem;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 4rem;
  text-align: center;
  background-position: center;
}

.wide {
  background-image: url(../../../public/Icap/Images/Assets_Updated/Mobile_Horizantal/Navigation_Icon/Wide_Compatability.svg);
}

.rivh {
  background-image: url(../../../public/Icap/Images/Assets_Updated/Mobile_Horizantal/Navigation_Icon/Rivh_multimedia_content.svg);
}

.optimal {
  background-image: url(../../../public/Icap/Images/Assets_Updated/Mobile_Horizantal/Navigation_Icon/Optimal_Readability.svg);
}

.effortless {
  background-image: url(../../../public/Icap/Images/Assets_Updated/Mobile_Horizantal/Navigation_Icon/Effortless_Navigation.svg);
}

.multicolor {
  background-image: url(../../../public/Icap/Images/Assets_Updated/Mobile_Horizantal/Navigation_Icon/Multi_Color_Highlighting.svg);
}

.note {
  background-image: url(../../../public/Icap/Images/Assets_Updated/Mobile_Horizantal/Navigation_Icon/Group-2.svg);
}

.audio {
  background-image: url(../../../public/Icap/Images/Assets_Updated/Mobile_Horizantal/Navigation_Icon/Group-3.svg);
}

.progresss {
  background-image: url(../../../public/Icap/Images/Assets_Updated/Mobile_Horizantal/Navigation_Icon/Layer_1.svg);
}

.offline {
  background-image: url(../../../public/Icap/Images/Assets_Updated/Mobile_Horizantal/Navigation_Icon/Group.svg);
}

.future {
  background-image: url(../../../public/Icap/Images/Assets_Updated/Mobile_Horizantal/Navigation_Icon/Group-1.svg);
}

@media (max-width: 992px) {
  .dummycarousel {
    position: relative;
  }
  .carousel-container {
    position: absolute;
    top: 215px;
  }
  .carousel__item {
    font-size: 3rem;
  }
}

@media (max-width: 778px) {
  .carousel__item {
    font-size: 2rem;
  }
  /* .future,
  .offline,
  .progresss,
  .audio,
  .note {
    display: none;
  } */
}

/* Dasbhobard Page */
.dashboard {
  display: flex;
  padding: 0 80px;
  justify-content: center;
  width: 100vw;
  height: 88vh;
  background-image: url(../../../public/Icap/Images/Assets_Updated/Web/pattern/Frame\ 2.png);

  background-size: contain;
}
.dashboard-content {
  height: 100%;
  width: 100%;
  padding: 30px 0;
}
.pageBackDashboard {
  font-size: 1.5rem;
  color: #185698;
  cursor: pointer;
}
.pageBackDashboard:hover {
  color: #8f9fb0;
}
.backGoCategory {
  margin-bottom: 15px;
  margin-left: 10px;
}
.dashboardheading {
  font-size: 3rem !important;
}
.tablecards {
  display: flex;
  align-items: center;
  gap: 20px;
}

.table-container {
  width: 55%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow: hidden;
  border: 30px solid #fff;
}

table {
  width: 100%;
  border-collapse: collapse;
}
.tableheading th {
  font-size: 1.4rem;
  font-weight: 500;
  width: 80px;
}
@media (max-width: 1200px) {
  .table-container th {
    font-size: 1rem;
  }
}
@media (max-width: 767px) {
  .table-container th {
    font-size: 1.2rem;
  }
}
.tableheading :nth-child(2) {
  background-color: #6d6e72;
  text-align: center;
}

thead {
  background-color: #58585a;
  color: white;
  text-align: left;
}

thead th {
  padding: 15px 20px;
}

.subject {
  background-color: #17abaf;
  color: white;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1;
  cursor: pointer;
}

/* tbody tr:nth-child(odd) {
  background-color: #e6f9ff;
}

tbody tr:nth-child(even) {
  background-color: #ccf2ff;
} */

tbody td {
  padding: 15px 20px;
}

tr.spaceUnder > td {
  border-bottom: 0.2em solid #fff;
  border-top: 0.2em solid #fff;
}
.time {
  text-align: center;
  font-weight: bold;
  color: #fff;
  background-color: #1f5c95;
  font-size: 1.4em;
}

.time span {
  font-size: 0.8em;
  margin-left: 5px;
  font-weight: 100;
}
.hours {
  font-size: 1.2em !important;
  font-weight: 700 !important;
}

.icons {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.tableicon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .tableicon {
   border-radius: 2px;
  font-size: 5em;
  color: white;
  font-weight: 100 !important;
  padding: 0.5em;
  background-color: #17abaf;
  border: 20px solid #fff; 
  cursor: pointer;
} */
.NewsIcon {
  border: 15px solid #fff;
}
.PlannerIcon {
  border: 15px solid #fff;
}
.PlannerIconSvg {
  width: 9rem;
  height: 9rem;
}
.NewsIconSvg {
  width: 9rem;
  height: 9rem;
}
@media (max-width: 1170) {
  .table-container {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .dashboard {
    height: 85vh;
  }
  .table-container {
    width: 100%;
  }
  .tbody td {
    padding: 12px;
  }
  .tablecards {
    width: 86vw;
  }
}

@media (max-width: 767px) {
  .table-container {
    width: 100vw;
  }
  .tablecards {
    flex-direction: column;
  }
  .icons {
    flex-direction: row;
  }
  /* .tableicon {
    font-size: 3em;
  } */
  tbody td {
    padding: 10px 5px;
  }
  .dashboard {
    height: 72vh;
    padding: 0;
  }
  .tablecards {
    width: 100vw;
  }
  .table-container {
    border: 15px solid #fff;
  }
}

.progress-bar {
  height: 10px;
}

.loading{
  margin-top: 100px;
  margin-left: 40px;
}
</style>
