<template>
	<li :class="'chapter-row-item ' + (isDisabled ? 'disabled' : '')" @click="handleContentClick()">
		<div v-if="item.type != ContentType.DIRECTORY" class="content-list-item-container">
			<div class="content-icon-container">
				<div v-if="item.type === ContentType.LIVE_CLASS ? isLiveClassEnded(item) : completed"
					class="chapter-status-icon">
					<img src="../../assets/images/check-green-circle-icon.svg">
				</div>
				<div v-else style="" class="chapter-status-icon">

				</div>
				<div class="content-icon-border 123">
					<img v-if="item.type == ContentType.LIVE_CLASS" class="page-view-icon"
						src="@/assets/images/zoom-icon.svg" />
					<img v-if="item.type == ContentType.STUDY_MATERIAL" class="page-view-icon"
						src="@/assets/images/study-material.png" />
					<img v-if="item.type == ContentType.DIRECTORY" class="page-view-icon"
						src="@/assets/images/folder-icon.png" />
					<img v-if="item.type == ContentType.RECORDED_VIDEOS && item.externalService.serviceProvider.toLowerCase() == 'vss'"
						class="page-view-icon" src="@/assets/images/recorded-lecture.png" />
					<img v-if="item.type == ContentType.RECORDED_VIDEOS && item.externalService.serviceProvider.toLowerCase() == 'youtube'"
						class="page-view-icon" src="@/assets/images/youtube-icon.png" />
					<img v-if="item.type == ContentType.EXAM" class="page-view-icon" src="@/assets/images/exam-icon.png" />

					<img v-if="item.type == ContentType.FLASHCARDS" class="page-view-icon"
						src="@/assets/images/flash-card.png" />
					<img v-if="item.type == ContentType.FREETEXT" class="page-view-icon"
						src="@/assets/images/freetext-icon.svg" />
					<img v-if="item.type == ContentType.ASSIGNMENT" class="page-view-icon"
						src="@/assets/images/assignment.png" />
					<img v-if="item.type == ContentType.QUIZ" class="page-view-icon"
						src="./../../assets/images/exam-icon.png" />


				</div>
			</div>
			<div class="content-text">

				<span>{{ item.title }}</span>

				<span
					v-if="item.type == ContentType.RECORDED_VIDEOS && item.externalService && item.externalService.serviceProvider.toLowerCase() == 'vss'"
					class="sub-text">
					{{ secondsToHHMMSSFormat(item.extraProperties.Duration) }}
				</span>
				<span v-else-if="item.type == ContentType.RECORDED_VIDEOS" class="sub-text">
					Recorded Video
				</span>
				<span
					v-else-if="item.type == ContentType.LIVE_CLASS && item.externalService && item.externalService.serviceProvider.toLowerCase() == 'zoom'"
					class="sub-text">

					{{ timeParser(item.availableFrom) }},
					{{ dateParser(item.availableFrom) }} |
					{{ secondsToHHMMSSFormat(item.extraProperties.Duration) }}
				</span>
				<span
					v-else-if="item.type == ContentType.ASSIGNMENT && typeof (item.extraProperties) == 'string' && JSON.parse(item.extraProperties).Deadline"
					class="sub-text">
					{{ timeParser(JSON.parse(item.extraProperties).Deadline) }},
					{{ dateParser(JSON.parse(item.extraProperties).Deadline) }}
				</span>

				<span
					v-else-if="item.type == ContentType.FLASHCARDS && typeof (item.extraProperties) == 'string' && JSON.parse(item.extraProperties).Count"
					class="sub-text">
					{{ JSON.parse(item.extraProperties).Count }} cards
				</span>
				<span v-else-if="item.type == ContentType.STUDY_MATERIAL" class="sub-text">
					Study Material
				</span>

				<span v-else-if="item.type == ContentType.FREETEXT" class="sub-text">
					EBook
				</span>

				<span v-else class="sub-text">
					{{ item.type }}
				</span>



				<!-- <span v-else style="font-size: xx-small; display: block; height: 15px;">

						{{
							item.type == ContentType.RECORDED_VIDEOS
							? item
							: item.type == ContentType.ASSIGNMENT
								? "Assignment"
								: item.type == ContentType.LIVE_CLASS
									? "Live Class"
									: item.type == ContentType.EXAM
										? "Exam"
										: "Study Material"
						}}
						</span> -->
			</div>
			<div class="content-info">

				<div class="confidence-info-wrapper">
					<div v-if="confidence" class="confidence-info">
						<span
							:class="['eb-label', 'confidence-label-medium', { 'high': confidence.toLowerCase() == 'high' }, { 'medium': confidence.toLowerCase() == 'medium' }, { 'low': confidence.toLowerCase() == 'low' }]">
							<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16.38"
								height="16.444" viewBox="0 0 16.38 16.444">
								<g id="" data-name="Group 3867" clip-path="url(#clip-path)">
									<path id="" data-name="Path 8048"
										d="M3.083,14.413c-.831-.451-.98-.81-.73-1.738.28-1.036.538-2.079.845-3.107a.652.652,0,0,0-.269-.8c-.8-.638-1.59-1.295-2.382-1.947A1.187,1.187,0,0,1,.058,5.473a1.173,1.173,0,0,1,1.183-.8c1.024-.061,2.047-.139,3.071-.169a.6.6,0,0,0,.631-.488C5.288,3,5.648,1.984,6.007.973,6.253.279,6.593-.005,7.157,0s.878.281,1.132,1c.354,1,.719,1.988,1.046,2.993a.652.652,0,0,0,.714.513c1.041.03,2.08.117,3.121.168a1.123,1.123,0,0,1,1.1.769,1.136,1.136,0,0,1-.4,1.33c-.854.706-1.7,1.422-2.562,2.118a.6.6,0,0,0-.234.727c.3,1.063.56,2.141.873,3.2a1.287,1.287,0,0,1-.527,1.6h-.993c-.959-.7-1.928-1.386-2.872-2.1a.592.592,0,0,0-.827.007c-1.006.715-2.033,1.4-3.051,2.1Z"
										transform="translate(1.016 1.015)" fill="none" stroke="#707070"
										stroke-width="1.5" />
								</g>
							</svg> <span class="text">
								{{ confidence }}</span>
						</span>
					</div>
				</div>
				<div style="width: 20px;" class="ms-2 bookmark-wrapper">
					<div v-if="bookmark" class="bookmark-info d-flex align-items-center" @click.stop>
						<span @click="" :class="['bookmark-info-icon']">
							<i class="fa fa-bookmark"></i>
						</span>
					</div>
				</div>
				<div class="inline-progress-wrapper">

					<div v-if="item.type == ContentType.RECORDED_VIDEOS || item.type == ContentType.FLASHCARDS" class="inline-progress d-flex ms-4">
						<div class="progress mb-0">
							<div class="progress-bar" role="progressbar" :style="{ width: getProgress }" aria-valuenow="25"
								aria-valuemin="0" aria-valuemax="100"></div>
						</div>
						<div class="inline-progress-label mb-0">
							<span id="progressVal">{{ getProgress }}</span>
						</div>
					</div>
				</div>



				<!-- <p v-if="item.type == ContentType.LIVE_CLASS">
					<span v-if="contentExtraProperties"
						>{{ timeParser(contentExtraProperties.Start_Time) }}, <br />
						{{ dateParser(contentExtraProperties.Start_Time) }}</span
					>
					<span v-else> 1 hour </span>
				</p>
				<p v-if="item.type == ContentType.RECORDED_VIDEOS">
					<span v-if="contentExtraProperties"
						>{{
							contentExtraProperties.Duration
								? (contentExtraProperties.Duration / 60).toFixed(1)
								: ""
						}}
						min</span
					>
					<span v-else> 45 min</span>
				</p> -->
			</div>
			<div v-if="isUnpaid && isDemo" class="content-cta demo">
				<button type="button" class="border-btn">Demo</button>
			</div>
			<div v-else-if="!isUnpaid" class="content-cta d-flex gap-2">
				<!-- <button 				
				v-if="item.type != ContentType.EXAM"
				:disabled="isDisabled" type="button" class="border-btn">
					{{ buttonText }}
				</button> -->
				<!-- <button 				
				v-else-if="!item.availableTo || new Date() <= new Date(item.availableTo)"
				:disabled="isDisabled" type="button" class="border-btn">
					{{ buttonText }}
				</button> -->
				<!-- <button
					v-if="
						item.type == ContentType.EXAM &&
						item.externalService.serviceProvider ==
							ServiceCodes.EXAM.V3ExamSystem
					"
					:disabled="isDisabled"
					type="button"
					class="border-btn"
					@click="handleLegacyExamButtonClick"
				>
					View Results
				</button> -->
			</div>
		</div>
		<b-modal :visible="showWebDisabledModel" hide-header centered hide-footer @change="closeWebDisabledModal" size="lg"
			body-class="modal-body" ok-title="Ok">
			<div class="login-webDisabled">
				<h1 class="heading-bold-20">Please download app for your device to access content.</h1>
				<ul class="download-btn-list">
					<li class="download-btn-list-item" v-if="systemParameters && systemParameters.AndroidAppUpdateUrl">
						<a :href="systemParameters.AndroidAppUpdateUrl" class="download android" target="_blank">
							<i class="fa fa-android fa-3x"></i>
							<span class="df">Download For</span>
							<span class="dfn">Android</span>
						</a>
					</lI>
					<li class="download-btn-list-item" v-if="systemParameters && systemParameters.IOSAppUpdateUrl">
						<a :href="systemParameters.IOSAppUpdateUrl" class="download apple" target="_blank">
							<i class="fa fa fa-apple fa-3x"></i>
							<span class="df">Download For</span>
							<span class="dfn">IOS / IPAD</span>
						</a>
					</li>
					<li class="download-btn-list-item" v-if="systemParameters && systemParameters.WindowsAppUpdateURL">
						<a class="download windows" :href="systemParameters.WindowsAppUpdateURL" target="_blank">
							<i class="fa fa fa-windows fa-3x"></i>
							<span class="df">Download For</span>
							<span class="dfn">Windows</span>
						</a>
					</li>
					<li class="download-btn-list-item" v-if="systemParameters && systemParameters.MacAppUpdateURL">
						<a :href="systemParameters.MacAppUpdateURL" target="_blank" class="download apple">
							<i class="fa fa fa-apple fa-3x"></i>
							<span class="df">Download For</span>
							<span class="dfn">Mac</span>
						</a>
					</li>
				</ul>
			</div>

		</b-modal>

	</li>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import StudyMaterial from "../Content/StudyMaterial.vue";
import { ContentType } from "../../constants/content";
import { LegacyExam } from "../../constants/exam";
import { getInputDateFormat } from "../../helpers/FormatHelpers";
import {
	toShortDateString,
	getTimeIn12Hours,
} from "../../helpers/FormatHelpers";
import { ServiceCodes } from "../../constants/serviceCodes";
import { getCookie } from "../../helpers/CookieHelpers";

export default {
	name: "ProductContentChaptersContentListItem",
	data() {
		return {
			activeAccordian: "",
			showStudyMaterialModal: false,
			ContentType,
			ServiceCodes,
			LegacyExam,
			showWebDisabledModel: false
		};
	},
	components: {
	},
	props: {
		item: Object,
		chapterID: String
	},
	methods: {
		...mapActions("content", ["getContentById"]),
		...mapActions("content", ["updateActions"]),

		isLiveClassEnded(data) {
			var currentDate = new Date();
			if (!data.availableTo)
				return false;


			const end = new Date(data.availableTo)

			return currentDate > end;
		},
		closeWebDisabledModal(isOpen) {
			this.showWebDisabledModel = isOpen;
		},
		dateParser(date) {
			return toShortDateString(date);
		},
		timeParser(date) {
			return getTimeIn12Hours(date);
		},
		secondsToHHMMSSFormat: function (seconds) {
			var sec_num = parseInt(seconds, 10); // don't forget the second param
			var hours = Math.floor(sec_num / 3600);
			var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
			var seconds = sec_num - (hours * 3600) - (minutes * 60);

			// if (hours < 10) { hours = "0" + hours; }
			// if (minutes < 10) { minutes = "0" + minutes; }
			// if (seconds < 10) { seconds = "0" + seconds; }
			return (parseInt(hours) > 0 ? (hours + ' hr ') : "") +
				(parseInt(minutes) > 0 ? (minutes + ' min ') : "") +
				(parseInt(seconds) > 0 ? (seconds + ' sec') : "");
		},
		getContentActions() {

			var actionList = this.contentActions[this.item.id] ?? [];
			var actions = {}

			for (let action of actionList) {
				if (action.dataType && action.dataType == 'bookmark' && this.item.type.toString().toLowerCase() !== 'freetext') {
					actions.bookmark = action.data == "true"
				}

				if ( action && action.dataType && action.dataType == 'freetextBookmark' && this.item.type.toString().toLowerCase() === 'freetext') {
					actions.bookmark = (JSON.parse(action.data || '[]').length > 0).toString()
				}

				if (action.dataType && action.dataType == 'confidence_level') {
					actions.confidence = action.data;
				}

				if (action.dataType && action.dataType == 'completionStatus') {
					actions.completed = action.data == 'completed';
				}

				if (action.dataType && action.dataType === 'flashcardResponse') {
					let extraProperties = action.content.extraProperties ? JSON.parse(action.content.extraProperties) : null;
					let Count = extraProperties ? extraProperties.Count : 1;

					let data = action.data ? JSON.parse(action.data) : {};
					let keys = Object.keys(data);

					actions.flashcard = {};

					actions.flashcard.maxCount = Count;
					actions.flashcard.attemptedLength = keys.length ?? 0;
				}

			}

			return actions;
		},
		handleContentClick() {
			if (!this.isDesktopApp && this.isWebDisabled) {
				this.showWebDisabledModel = true;
				return;
			}

			// this.$router.push({ query: { chapterId: this.chapterID, contentId: this.item.id } });
			// undefined is set in table of contents for toggle
			let query = { ...this.$route.query, chapterId: this.chapterID, contentId: this.item.id, tableOfContent: undefined };

			this.$router.push({ query });

			// if (this.item.type == ContentType.EXAM) {
			// 	if (
			// 		this.item.externalService.serviceProvider ==
			// 		ServiceCodes.EXAM.V3ExamSystem
			// 	) {
			// 		this.getContentById({
			// 			id: this.item.id,
			// 			extraParams: { action: LegacyExam.GET_EXAM_ACTION.ATTEMPT },
			// 		}).then((content) => {
			// 			if (content && this.loggedIn) {
			// 				var examAttemptUrl =
			// 					content.data.redirectUrl +
			// 					"&RedirectUrl=" +
			// 					window.location.href;
			// 				window.open(examAttemptUrl, "_blank");
			// 				// window.location.href = examAttemptUrl;
			// 				return;
			// 			}
			// 			this.$toasted.error(
			// 				"Please login/create an account to demo this Exam",
			// 				{
			// 					duration: 3000,
			// 					theme: "outline",
			// 					position: "bottom-center",
			// 				}
			// 			);
			// 			this.$router.push({
			// 				name: "Login",
			// 				query: {
			// 					nextUrl:
			// 						this.$route.path,
			// 				},
			// 			});
			// 		});
			// 		return;
			// 	}
			// 	console.log("examClicked");
			// 	this.getContentById({
			// 		id: this.item.id,
			// 		extraParams: { RedirectUrl: window.location.href },
			// 	}).then((res) => {
			// 		var data = res.data;
			// 		if (this.loggedIn) {
			// 			var examAttemptUrl = `http://examapp.gocbeglobal.com/exam/attempt?TestID=${
			// 				data.examID
			// 			}&Name=${data.name ?? ""}&Email=${data.email ?? ""}&UserID=${
			// 				data.userID ?? ""
			// 			}&RedirectUrl=${data.redirectUrl}&Signature=${data.signature}`;
			// 			window.open(examAttemptUrl, "_blank");
			// 			// window.location.href = examAttemptUrl;
			// 			return;
			// 		}
			// 		this.$toasted.error(
			// 			"Please login/create an account to demo this Exam",
			// 			{
			// 				duration: 3000,
			// 				theme: "outline",
			// 				position: "bottom-center",
			// 			}
			// 		);
			// 		this.$router.push({
			// 			name: "Login",
			// 			query: {
			// 				nextUrl: this.$route.path,
			// 			},
			// 		});
			// 	});
			// } else if (this.item.type == ContentType.LIVE_CLASS) {
			// 	var joinURLFromCookies = getCookie(`${this.item.id}_${this.user?.id}`);
			// 	if (joinURLFromCookies) {
			// 		window.location.href = joinURLFromCookies;
			// 		return;
			// 	}
			// 	this.$router.push(this.contentLink);
			// } else{
			// 	this.$router.push({ query:{ chapterId:this.$route.query.chapterId, contentId:this.item.id }})
			// };

		},
		handleLegacyExamButtonClick(e) {
			if (!this.isDesktopApp && this.isWebDisabled) {
				this.showWebDisabledModel = true;
				return;
			}
			e.stopPropagation();
			this.getContentById({
				id: this.item.id,
				extraParams: { action: LegacyExam.GET_EXAM_ACTION.VIEW_RESULTS },
			}).then((content) => {
				if (content && this.loggedIn) {
					var examAttemptUrl =
						content.data.redirectUrl + "&RedirectUrl=" + window.location.href;
					window.open(examAttemptUrl, "_blank");
					return;
				}
			});
		},
		timeParser(date) {
			return getTimeIn12Hours(date);
		},
		dateParser(date) {
			return toShortDateString(date);
		},
	},
	computed: {
		...mapState("user", ["loggedIn", "user"]),
		...mapState("appState", ["isMobile", "systemParameters"]),
		...mapState("content", ["contentActions"]),
		isDesktopApp() {
			return window.navigator.userAgent.toLowerCase().indexOf("electron") > -1;
		},
		isWebDisabled() {
			return this.systemParameters && this.systemParameters.IsWebAccessDisabled == '1';
		},
		contentLink() {
			switch (this.item.type) {
				case ContentType.LIVE_CLASS:
					return {
						path:
							"/content/liveclass/" +
							this.item.title
								.toLowerCase()
								.replace(/[^a-zA-Z0-9 ]/g, "")
								.split(" ")
								.join("-") +
							"-" +
							this.item.id +
							"_",
					};
				case ContentType.ASSIGNMENT:
					return {
						path:
							"/content/assignment/" +
							this.item.title
								.toLowerCase()
								.replace(/[^a-zA-Z0-9 ]/g, "")
								.split(" ")
								.join("-") +
							"-" +
							this.item.id,
					}
			}
		},
		bookmark() {
			return this.getContentActions().bookmark
		},
		confidence() {
			return this.getContentActions().confidence && ["high", "medium", "low"].indexOf(
				this.getContentActions().confidence.toLowerCase()) > -1 ? this.getContentActions().confidence : null;
		},
		completed() {
			return this.getContentActions().completed
		},
		flashcard() {
			return this.getContentActions().flashcard
		},
		getProgress() {
			if (this.item.type === ContentType.FLASHCARDS) {
				if (this.flashcard) {
					let attemptedLength = this.flashcard.attemptedLength ?? 0;
					let progress = (attemptedLength / this.flashcard.maxCount) * 100;
					progress = progress > 100 ? 100 : progress;
					return progress.toFixed(0) + '%';
				} else {
					return '0%';
				}
			}
			if (this.item.type === ContentType.LIVE_CLASS) {
				return this.isLiveClassEnded(this.item) ? "100%" : "0%";
			}
			if (this.item.type === ContentType.RECORDED_VIDEOS && this.contentActions[this.item.id] && this.item.externalService && this.item.externalService.serviceProvider?.toLowerCase() == 'vss') {
				var videoProgressAction = this.contentActions[this.item.id].find(x => x.dataType == "progressPercentage");

				if (videoProgressAction) {
					var prog = parseInt(videoProgressAction.data);
					return (prog > 100 ? 100 : prog) + '%'
				}

				return this.completed ? "100%" : "0%";
			}
			else {
				return this.completed ? "100%" : "0%";
			}
		},

		isDisabled() {
			return (
				(getInputDateFormat(new Date(this.item.availableFrom)) !=
					getInputDateFormat(new Date()) &&
					this.item.type == ContentType.LIVE_CLASS) ||

				(new Date(this.item.availableFrom) > new Date() &&
					this.item.type == ContentType.LIVE_CLASS) ||

				(this.isUnpaid && !this.isDemo)
			);
		},
		isDemo() {
			return this.item.isContentAvailableForDemo;
		},
		isUnpaid() {
			return false;
		},
		contentExtraProperties() {
			if (this.item.extraProperties) return this.item.extraProperties;
			else return null;
		},
		buttonText() {
			return this.item.type == ContentType.RECORDED_VIDEOS
				? "Watch"
				: this.item.type == ContentType.LIVE_CLASS
					? "Join Now"
					: this.item.type == ContentType.ASSIGNMENT
						? "Submit"
						: this.item.type == ContentType.EXAM
							? "Attempt"
							: "View";
		},
	},

	async mounted() {
		await this.getContentActions();
	},
	created() {
	},
	watch: {
	}
};
</script>

<style scoped>
.content-item-list li {
	padding: 0;
	border-bottom: 1px solid var(--color-light-gray);
	cursor: pointer;
}

.content-item-list li.disabled {
	pointer-events: none;
}

.content-item.disabled .content-text {
	opacity: 0.5;
}

.content-list-item-container {
	color: var(--secondary-color);
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
}


.content-item {
	font-size: 0.9rem;
	padding: 1rem !important;
}

/* .content-item span, */
/* .content-item-disabled span {
	font-size: 0.7rem;
	color: var(--color-gray);
} */

.content-item-list li:last-child {
	border-bottom: none;
}

.content-item-icon {
	/* margin-left: 0.25rem; */
	margin-right: 0.5rem;
	font-size: 1rem;
	color: var(--color-text-default);
}

.content-icon-container {
	width: 44px;
	display: flex;
	align-items: center;
	position: relative;
}

.chapter-status-icon {
	margin-right: 0;
	flex: 0 0 auto;
	position: absolute;
	border-radius: 50%;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	right: 6px;
	top: 1px;
}


.chapter-status-icon img {
	width: 18px;
	background-color: #fff;
	border-radius: 50%;
	padding: 0.085rem;
}

.page-view-icon {
	font-size: 0.6rem;
	width: auto;
	height: 32px;
	/* border-radius: 50%; */
	display: flex;
	align-items: center;
	justify-content: center;
	max-height: 28px;
	margin: 0;
}

.page-view-icon.blue-bg {
	background-color: #2d8bfe;
	color: var(--color-white);
}

.content-item-list li.active {
	background-color: var(--color-light-gray);
}

/* .content-icon-container {
	width: 70px !important;
	display: flex;
	align-items: center;
	justify-content: center;
} */

.content-text {
	line-height: 1.3;
	font-size: 1rem;
	color: #666;
	font-size: 0.875rem;
	width: calc(100% - 350px);
}

.content-text span {
	color: black;
	font-size: 1rem;
}

.content-text .sub-text {
	font-size: 0.75rem;
	display: block;
	color: #6c757d;
	height: 15px;
}

.content-list-item-container:hover .content-text span {
	color: var(--primary-color);
}

.content-icon-border {
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	/* margin-right: 1rem; */
	/* border: 2px solid #d5d5d5; */
	border-radius: 50%;
}

.content-info {
	margin-left: auto;
	margin-right: 1rem;
	min-width: 3.425rem;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
}

.content-info p {
	margin: 0;
	font-size: 0.675rem;
	line-height: 1.25;
}

.content-info .heading {
	text-transform: uppercase;
	color: var(--color-gray);
}

.confidence-info-wrapper {
	width: 100px;
	display: flex;
	place-content: end;
}

.confidence-info {
	width: 76px;
}

.content-info .eb-label {
	border-radius: 4px;
	justify-content: start;
}

.confidence-info {
	width: 90px;
	height: 26px;
}

.confidence-label-medium {
	width: fit-content;
}

.content-info .eb-label {
	padding-top: 3px;
	padding-bottom: 3px;
	padding-left: 6px;
	padding-right: 6px;
	height: 26px;
}

.confidence-label-medium.high {
	border: 2px solid #11AE00;
	background-color: #FEFFFE;
}

.confidence-label-medium .text {
	color: #000;
	font-family: Circular Std;
	font-size: 15px;
	font-style: normal;
	font-weight: normal;
	line-height: normal;
	padding-top: 2.5px;
}

.confidence-label-medium.high svg path {
	fill: #11AE00;
	stroke: #11AE00;
}

.confidence-label-medium.medium {
	border: 2px solid #FEAA00;
	background-color: #FFFBF3;
}

.confidence-label-medium.medium svg path {
	fill: #FEAA00;
	stroke: #FEAA00;
}

.confidence-label-medium.low {
	border: 2px solid #F0546C;
	background-color: #FFFCFD;
}

.confidence-label-medium.low svg path {
	fill: #F0546C;
	stroke: #F0546C;
}

.confidence-label-medium svg {
	width: 15px;
	height: 15px;
	margin-right: 5px;
}

.inline-progress-wrapper {
	width: 130px;
	display: flex;
	place-content: end;
	margin-right: 5px;
}

.inline-progress {
	width: 106px;
}

.inline-progress .progress {
	width: 75px;
	height: 6px;
}

.inline-progress-label {
	width: 24px;
}

.bookmark-info-icon {
	border: solid 1px var(--primary-color);
	display: inline-block;
	padding: 1px 4px 2px;
	border-radius: 4px;
	width: 20px;
	height: 20px;
	text-align: center;
	font-size: 13px;
	color: var(--primary-color);
	margin: 0px -7px;
}

.bookmark-active {
	color: var(--color-text-default);
	border: solid 1px var(--color-text-default);
}

.content-cta button {
	padding: 0.425rem 0.5rem;
	min-width: 5.75rem;
	line-height: 1;
	border-color: #282828;
	font-size: 0.875rem;
	border-radius: 4px;
	color: #282828 !important;
	font-weight: 500;
}

.login-webDisabled {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: auto;
	padding: 0 7% 0;
}

.download-btn-list {
	text-align: center;
	margin: 0;
	padding: 0;
	display: inline;
}

.download-btn-list-item {
	display: inline;
}

.download-btn-list-item a {
	margin: 10px;
}

.download {
	width: 200px;
	height: 70px;
	background: black;
	float: left;
	border-radius: 5px;
	position: relative;
	color: #fff;
	cursor: pointer;
	border: 1px solid #fff;
}

.download>.fa {
	color: #fff;
	position: absolute;
	top: 50%;
	left: 15px;
	transform: translateY(-50%);
}

.df,
.dfn {
	position: absolute;
	left: 60px;
	width: 50%;
}

.df {
	top: 16px;
	font-size: 12px;
}

.dfn {
	top: 28px;
	font-size: 18px;
}

.download:hover {
	-webkit-filter: invert(100%);
	filter: invert(100%);
}



@media (max-width: 768px) {
	.content-text {
		width: calc(100% - 192px);
	}

	.confidence-label-medium .text {
		font-size: 0.9rem;
		padding-top: 1px;
	}

	.content-info .eb-label {
		padding-top: 1px;
		padding-bottom: 1px;
		padding-left: 3px;
		padding-right: 4px;
		height: 22px;
	}

	.confidence-label-medium svg {
		width: 12px;
		height: 12px;
		margin-right: 2px;
	}

	.confidence-info-wrapper {
		width: 72px;
		flex: 0 0 auto;
	}

	.confidence-info {
		width: 70px !important;
		height: 22px;
	}

	.confidence-label-medium svg path {
		stroke: transparent !important;
	}

	.confidence-label-medium {
		border-width: 1px !important;
	}

	.content-info {
		margin-right: 0;
	}

	.inline-progress-wrapper {
		width: 45px;
		align-items: center;
		justify-content: center;
		padding-left: 10px;
		flex: 0 0 auto;
	}

	.bookmark-wrapper {
		flex: 0 0 auto;
		margin-left: 0.015rem !important;
	}

	.inline-progress {
		width: 35px;
		flex-direction: column;
		margin-left: 0 !important;
	}

	.inline-progress .progress {
		width: 35px;
		height: 5px;
	}

	.inline-progress-label {
		font-size: 0.75rem;
	}

	.content-icon-container {
		width: 46px;
	}

	.chapter-status-icon img {
		width: 18px;
	}
}


/* Ebook-Digital */
	.ebook-digital .content-info > * {
		display: block !important;
		visibility: visible !important;
		opacity: 1 !important;
	}

	.ebook-digital .content-text {
		width: calc(100% - 192px);
	}

	.ebook-digital .confidence-label-medium .text {
		font-size: 0.9rem;
		padding-top: 1px;
	}

	.ebook-digital .content-info .eb-label {
		padding-top: 1px;
		padding-bottom: 1px;
		padding-left: 3px;
		padding-right: 4px;
		height: 22px;
	}

	.ebook-digital .confidence-label-medium svg {
		width: 12px;
		height: 12px;
		margin-right: 2px;
	}

	.ebook-digital .confidence-info-wrapper {
		width: 72px;
		flex: 0 0 auto;
	}

	.ebook-digital .confidence-info {
		width: 70px !important;
		height: 22px;
	}

	.ebook-digital .confidence-label-medium svg path {
		stroke: transparent !important;
	}

	.ebook-digital .confidence-label-medium {
		border-width: 1px !important;
	}

	.ebook-digital .content-info {
		margin-right: 0;
	}

	.ebook-digital .inline-progress-wrapper {
		width: 45px;
		align-items: center;
		justify-content: center;
		padding-left: 10px;
		flex: 0 0 auto;
	}

	.ebook-digital .bookmark-wrapper {
		flex: 0 0 auto;
	}

	.ebook-digital .inline-progress {
		width: 35px;
		flex-direction: column;
		margin-left: 0 !important;
	}

	.ebook-digital .inline-progress .progress {
		width: 35px;
		height: 5px;
	}

	.ebook-digital .inline-progress-label {
		font-size: 0.75rem;
	}

	.ebook-digital .content-icon-container {
		width: 46px;
	}

	.ebook-digital .chapter-status-icon img {
		width: 18px;
	}

</style>
