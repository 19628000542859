import Home from "../../views/Home.vue";
import Account from "../../views/Account.vue";
import Login from "../../components/Account/Login.vue";
import Register from "../../components/Account/Register.vue";
import ForgotPassword from "../../components/Account/ForgotPassword.vue";
import UserVerification from "../../components/Account/UserVerification.vue";
import ProductDetails from "../../views/ProductDetails.vue";
import Search from "../../views/Search.vue";
import Dashboard from "../../views/Dashboard.vue";
import Support from "../../views/Support.vue";
import Profile from "../../views/Profile.vue";
import ProductContentPage from "../../views/ProductContentPage.vue";
import Cart from "../../views/Cart.vue";
import Content from "../../views/Content.vue";
import RecordedVideo from "../../components/Content/RecordedVideo.vue";
import Assignment from "../../components/Content/Assignment.vue";
import LiveClass from "../../components/Content/LiveClass.vue";
import Quiz from "../../components/Content/Quiz.vue";
import Invoice from "../../views/Invoice.vue";
import CourseRegistration from "../../custom/astc/CourseRegistration.vue";
import CourseEnrollment from "../../components/Admin/Enrollment/CourseEnrollment.vue";
import BankTransfer from "../../components/Payment/BankTransfer.vue";
import KuickPayCheckout from "../../components/Payment/KuickPayCheckout.vue";
import Safepay from "../../components/Payment/Safepay.vue";
import KuickPayBPS from "../../components/Payment/KuickPayBPS.vue";
import Cash from "../../components/Payment/Cash.vue";
import Payment from "../../views/Payment.vue";
import PayPro from "../../components/Payment/PayPro.vue";
import MeezanPaymentGateway from "../../components/Payment/MeezanPaymentGateway.vue";
import ScotiaPaymentGateway from "../../components/Payment/ScotiaPaymentGateway.vue";
import ZoomOAuthService from "../../components/Admin/Service/ZoomOAuthService.vue";
import NgeniusPaymentGateway from "../../components/Payment/NgeniusPaymentGateway.vue";
// import ManagementHome from "../../views/Admin/ManagementHome.vue";
import CourseManagement from "../../views/Admin/CourseManagement.vue";
import PaymentManagement from "../../views/Admin/PaymentManagement.vue";
import InvoiceManagement from "../../views/Admin/InvoiceManagement.vue";
import UsersManagement from "../../views/Admin/UsersManagement.vue";
import SystemPreferences from "../../views/Admin/SystemPreferences.vue";
import AttendanceWithBarcode from "../../views/Admin/AttendanceWithBarcode.vue";
import BulkOperations from "../../views/Admin/BulkOperations.vue";
import ProductContentDashboard from "../../views/ProductContentDashboard.vue"
import EbookContentDashboard from "../../views/EbookContentDashboard.vue"
import UserDashboard from "../../views/UserDashboard.vue"
import CourseDetail from "../../views/Admin/CourseDetail.vue";
import CourseContent from "../../components/Admin/Content/CourseContent.vue";
import CoursePricing from "../../components/Admin/Pricing/CoursePricing.vue";
import CourseFeedback from "../../components/Admin/Feedback/CourseFeedback.vue";
import CourseQuestionBank from "../../components/Admin/QuestionBank/CourseQuestionBank.vue";
import helpers from "../../helpers/CookieHelpers";
import store from "../../store";
import InstructorProfile from "../../components/Instructor/InstructorProfile.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Home",
    },
  },
  {
    path: "/payment",
    name: "Payment",
    component: Payment,
    children: [
      {
        path: "cash/:id",
        name: "Cash",
        component: Cash,
      },
      {
        path: "banktransfer/:id",
        name: "BankTransfer",
        component: BankTransfer,
      },
      {
        path: "kuickpaybps/:id",
        name: "KuickPayBPS",
        component: KuickPayBPS,
      },
      {
        path: "safepay/:id",
        name: "Safepay",
        component: Safepay,
      },
      {
        path: "kuickpaycheckout/:id",
        name: "KuickPayCheckout",
        component: KuickPayCheckout,
      },
      {
        path: "paypro/:id?",
        name: "PayPro",
        component: PayPro,
      },
      {
        path: "meezan/:id?",
        name: "MeezanPaymentGateway",
        component: MeezanPaymentGateway,
      },
      {
        path: "scotia/:id?",
        name: "ScotiaPaymentGateway",
        component: ScotiaPaymentGateway,
      },
      {
        path: "ngenius/:id?",
        name: "NgeniusPaymentGateway",
        component: NgeniusPaymentGateway,
      },
    ],
  },
  {
    path: "/register",
    name: "CourseRegistration",
    component: CourseRegistration,
    meta: {
      title: "Register Courses",
    },
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
        meta: {
          guest: true,
          title: "Login",
        },
      },
      {
        path: "register",
        name: "Register",
        component: Register,
        meta: {
          guest: true,
          title: "Register",
        },
      },
      {
        path: "forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
        meta: {
          guest: true,
          title: "Forgot Password",
        },
      },
      {
        path: "user-verification",
        name: "UserVerification",
        component: UserVerification,
        meta: {
          guest: true,
          title: "User Verification",
        },
      },
    ],
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
    meta: {
      guest: true,
      title: "Search Courses",
    },
  },
  {
    path: "/product/content/:id",
    name: "ProductContentPage",
    component: ProductContentPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/product/details/:id",
    name: "ProductDetails",
    component: ProductDetails,
    meta: {
      guest: true,
    },
  },
  {
    path: "/user/dashboard",
    name: "Dashboard",
    component: UserDashboard,
    meta: {
      requiresAuth: true,
      title: "Dashboard",
    },
  },
  {
    path: "/user/invoices",
    name: "Invoice",
    component: Invoice,
    meta: {
      requiresAuth: true,
      title: "Invoices",
    },
  },
  {
    path: "/user/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
      title: "Profile",
    },
  },
  {
    path: "/shop/checkout",
    name: "Cart",
    component: Cart,
    meta: {
      requiresAuth: true,
      title: "Cart",
    },
  },
  {
    path: "/product/dashboard/:page/:id",
    name: "Product Content Dashboard",
    component: ProductContentDashboard,
    meta: {
      requiresAuth: true,
      title: "Product Content Dashboard",
      isProductContentDashboardPage: true
    },
  },
  {
    path: "/product/dashboard/:page",
    name: "Product Content Dashboard",
    component: ProductContentDashboard,
    meta: {
      requiresAuth: true,
      title: "Product Content Dashboard",
      isProductContentDashboardPage: true
    },
  },
  {
    path: "/Ebook/dashboard/:page/:id",
    name: "Ebook Content Dashboard",
    component: EbookContentDashboard,
    meta: {
      requiresAuth: true,
      title: "Ebook Content Dashboard",
      isProductContentDashboardPage: true
    },
  },
  // {
  //   path: "/user/dashboard/2",
  //   name: "User Dashboard",
  //   component: UserDashboard,
  //   meta: {
  //     requiresAuth: true,
  //     title: "User Dashboard"
  //   },
  // },
  {
    path: "/content",
    name: "Content",
    component: Content,
    children: [
      {
        path: "video/:id",
        name: "VideoContent",
        component: RecordedVideo,
        meta: {
          // requiresAuth: true,
        },
      },
      {
        path: "assignment/:id",
        name: "AssignmentContent",
        component: Assignment,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "liveclass/:id",
        name: "LiveClass",
        component: LiveClass,
        meta: {
          // requiresAuth: true,
        },
      },
      {
        path: "quiz/:id",
        name: "Quiz",
        component: Quiz,
        meta: {
          // requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/management/users",
    name: "UsersManagement",
    component: UsersManagement,
    meta: {
      requiresAuth: true,
      isAdmin: true,
      title: "Users | Management",
      activeTab: "UsersManagement",
    },
  },
  {
    path: "/management/preferences",
    name: "SystemPreferences",
    component: SystemPreferences,
    meta: {
      requiresAuth: true,
      isAdmin: true,
      title: "System Preferences | Management",
      activeTab: "SystemPreferences",
    },
  },
  {
    path: "/management/payments",
    name: "PaymentManagement",
    component: PaymentManagement,
    meta: {
      requiresAuth: true,
      isAdmin: true,
      title: "Payments | Management",
      activeTab: "PaymentManagement",
    },
  },
  {
    path: "/management/invoices",
    name: "InvoiceManagement",
    component: InvoiceManagement,
    meta: {
      requiresAuth: true,
      isAdmin: true,
      title: "Invoices | Management",
      activeTab: "InvoiceManagement",
    },
  },
  {
    path: "/management/course",
    name: "CourseManagement",
    component: CourseManagement,
    meta: {
      requiresAuth: true,
      isAdmin: true,
      title: "Course Management | Management",
      activeTab: "CourseManagement",
    },
  },
  {
		path: "/service/zoom",
		name: "ZoomService",
		component: ZoomOAuthService,
		meta: {
			requiresAuth: true,
			isAdmin: true,
			isHideAdminLayout: true,
			title: "Zoom Account Integration"
		},
	},
  {
    path: "/management/course/:id",
    name: "CourseDetail",
    component: CourseDetail,
    meta: {
      showTabs: true,
      requiresAuth: true,
      isAdmin: true,
      title: "Course Detail",
      activeTab: "CourseManagement",
    },
    children: [
      {
        path: "content",
        name: "CourseContent",
        component: CourseContent,
        meta: {
          showTabs: true,
          requiresAuth: true,
          isAdmin: true,
          title: "Course Content",
          activeTab: "CourseManagement",
        },
      },
      {
        path: "enrollment",
        name: "CourseEnrollment",
        component: CourseEnrollment,
        meta: {
          showTabs: true,
          requiresAuth: true,
          isAdmin: true,
          title: "Enrollment",
          activeTab: "CourseManagement",
        },
      },
      {
        path: "pricing",
        name: "CoursePricing",
        component: CoursePricing,
        meta: {
          showTabs: true,
          requiresAuth: true,
          isAdmin: true,
          title: "Pricing",
          activeTab: "CourseManagement",
        },
      },
      {
        path: "feedback",
        name: "CourseFeedback",
        component: CourseFeedback,
        meta: {
          showTabs: true,
          requiresAuth: true,
          isAdmin: true,
          title: "Feedback",
          activeTab: "CourseManagement",
        },
      },
      {
        path: "layout",
        name: "CourseLayout",
        component: CourseContent,
        meta: {
          showTabs: true,
          requiresAuth: true,
          isAdmin: true,
          title: "Layout",
          activeTab: "CourseManagement",
        },
      },
      {
        path: "dashboard",
        name: "CourseDashboard",
        component: CourseContent,
        meta: {
          showTabs: true,
          requiresAuth: true,
          isAdmin: true,
          title: "Dashboard",
          activeTab: "CourseManagement",
        },
      },
      {
        path: "questionbank",
        name: "CourseQuestionBank",
        component: CourseQuestionBank,
        meta: {
          showTabs: true,
          requiresAuth: true,
          isAdmin: true,
          title: "Question Bank",
          activeTab: "CourseManagement",
        },
      },
    ],
  },
  {
    path: "/management/barcode-attendance",
    name: "AttendanceWithBarcode",
    component: AttendanceWithBarcode,
    meta: {
      requiresAuth: true,
      isAdmin: true,
      title: "Barcode Attendance | Management",
      activeTab: "AttendanceWithBarcode",
    },
  },
  {
    path: "/bulk-operations",
    name: "BulkOperations",
    component: BulkOperations,
    meta: {
      requiresAuth: true,
      isAdmin: true,
      title: "Bulk Operations",
      activeTab: "BulkOperations",
    },
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: {
      requiresAuth: true,
      isAdmin: false,
      title: "Student Support",
    },
  },
  {
    path: "/management/support",
    name: "Support",
    component: Support,
    meta: {
      requiresAuth: true,
      isAdmin: true,
      title: "Support Tickets",
      activeTab: "Support",
    },
  },
  {
    path: "/instructor/profile",
    name: "InstructorProfile",
    component: InstructorProfile,
  },
];

function beforeEach(to, from, next) {
  let user = JSON.parse(localStorage.getItem("user"));
  if (to.meta && to.meta.title) {
    window.document.title = to.meta.title;
  }
  if (
    from.matched.some((record) => record.name == "CourseContent") &&
    to.matched.some((record) => record.name != "CourseContent")
  ) {
    var confirmed;
    console.log(store.state.content.uploadingContent);
    if (store.state.content.uploadingContent) {
      confirmed = window.confirm(
        "Are you sure you want to leave this page. You will lose all your upload progress if you navigate to another page"
      );
    } else {
      confirmed = true;
    }
    if (confirmed) {
      next();
      store.commit("appState/setUploading", false);
    } else {
      console.log("to", to);
      next(from);
      store.commit("appState/setUploading", false);
    }

    console.log("state", store.state.content.uploadingContent);
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    //if route requires auth
    if (helpers.checkCookie("token") == false) {
      console.log("login page visited before auth");
      //if user is not logged in
      next({
        path: "/account/login", //redirect to the login page
        query: { nextUrl: to.path }, //then continue to requested page
      });
    } else {
      // console.log("checking for user");

      if (to.matched.some((record) => record.meta.isAdmin)) {
        //if route requires admin user
        var userType = user.type || user.role;
        // console.log(userType);
        if (userType && userType.toLowerCase() != "student") {
          //if user is admin
          next();
        } else {
          next({ name: "Dashboard" }); //redirect to dashboard
        }
      } else {
        //if route doesnot requires admin user
        var userType = user.type || user.role;
        if (userType && userType.toLowerCase() != "student") {
          if (to.matched.some((record) => !record.meta.isAdmin)) {
            next({ path: "/management/course" });
          }
        } else next();
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    //if route is accessible to guest
    // if (helpers.checkCookie("token") == false) {
    // console.log(to);
    if (
      to.matched.some((record) => record.name.toLowerCase() == "login") &&
      user
    ) {
      console.log("login page visited after auth");
      var userType = user.type || user.role;
      // console.log(userType);
      if (userType && userType.toLowerCase() != "student") {
        //if user is admin
        next({ path: "/management/course" });
      } else {
        next({ path: "/user/dashboard" }); //redirect to dashboard
      }
    }
    next();
    // }
  } else {
    //if route does not require user to login
    next();
  }
}

export default { routes, beforeEach };
