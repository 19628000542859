// import helpers from "../../helpers/CookieHelpers";
import defaultRouteSettings from "./default";
import Home from "../../custom/icap/Home.vue";

const routes = defaultRouteSettings.routes.map((route) => {
  if (route.path == "/") {
    route.redirect = "/account/login";
  } else if (route.path == "/user/dashboard") {
    route.component = Home;
  }

  return route;
});

export default { routes, beforeEach: defaultRouteSettings.beforeEach };
