<template>
	<div :class="['body-content', { 'transparent': loading }]">

		<center v-if="loading" class="loading">
			<b-spinner large></b-spinner>
		</center>

		<div v-else-if="flashCards.length > 0">
			<div class="main-tabs-row">
				<ul class="main-tabs nav nav-tabs">
					<li class="nav-item" role="presentation">
						<button :class="['nav-link', { active: activeTab == 0 }]" @click="changeTab(0)" id="FCStudyTab"
							data-bs-toggle="tab" data-bs-target="#FCStudyPane" type="button" role="tab"
							aria-controls="FCStudyPane" :aria-selected="activeTab == 0">
							Study
						</button>
					</li>
					<li class="nav-item" role="presentation">
						<button :class="['nav-link', { active: activeTab == 1 }]" @click="changeTab(1)" id="FCListTab"
							data-bs-toggle="tab" data-bs-target="#FCListPane" type="button" role="tab"
							aria-controls="FCListPane" :aria-selected="activeTab == 1">
							List
						</button>
					</li>
				</ul>
			</div>

			<div class="eb-content-wrapper">
				<div class="tab-content eb-body" id="flashcardsTabContent">
					<div :class="['flashcard-page tab-pane', { show: activeTab == 0 }, { active: activeTab == 0 }]"
						id="FCStudyPane" role="tabpanel" aria-labelledby="FCStudyTab" tabindex="0">


						<!-- <div class="eb-header d-flex mb-1">
							<button class="eb-filter-toggle dropdown-toggle " data-bs-toggle="collapse"
								data-bs-target="#ebFilters" aria-expanded="false" aria-controls="ebFilters"><img
									src="@/assets/images/filter-icon.svg"> Filter</button>

							<button class="eb-filter-toggle ms-auto" id="FCListInnerTab" data-bs-toggle="tab"
								data-bs-target="#FCListPane" type="button" role="tab" aria-controls="FCListPane"
								aria-selected="false"><i class="fas fa-align-left me-2"></i> View List</button>


						</div> -->

						<!-- <div class="eb-filters collapse" id="ebFilters">
							<div class="eb-filter-area">
								<div class="row g-3 eb-filters mb-3">
									<div class="col-12">
										<label for="inputAddress2" class="form-label">Search</label>
										<input type="text" class="form-control" id="" placeholder="Search Sections">
									</div>
									<div class="col-md-6">
										<label class="form-label">Category</label>
										<select id="" class="form-select">
											<option selected>All</option>
											<option>...</option>
										</select>
									</div>
									<div class="col-md-6">
										<label class="form-label">Confidence</label>
										<select id="" class="form-select">
											<option selected>All</option>
											<option>...</option>
										</select>
									</div>
									<div class="col-12">
										<div class="form-check d-inline-block">
											<input class="form-check-input" type="checkbox" id="check1">
											<label class="form-check-label" for="check1">
												Has a bookmark
											</label>
										</div>
										<div class="form-check d-inline-block">
											<input class="form-check-input" type="checkbox" id="check2">
											<label class="form-check-label" for="check2">
												Has a note
											</label>
										</div>
										<div class="form-check d-inline-block">
											<input class="form-check-input" type="checkbox" id="check3">
											<label class="form-check-label" for="check3">
												Has related questions
											</label>
										</div>
									</div>
									<div class="col-12">
										<button type="submit" class="btn btn-primary me-1">Filter</button>
										<button type="submit" class="btn btn-outline-primary">Clear Filters</button>
									</div>
								</div>
							</div>
						</div>eb-filters -->



						<div class="eb-content flashcard-content">

							<!-- <div class="cd-head">
								<div class="eb-sub-header py-1 justify-content-between px-2">
									<div class="eb-chapter-arrows">
										<button class="eb-chapter-prev" @click="prevCard()"><i
												class="fas fa-caret-left"></i></button>
										<button class="eb-chapter-next" @click="nextCard()">
											<i class="fas fa-caret-right"></i></button>
									</div>
									<div class="eb-chapter-controls ms-auto d-flex">
										<button class="eb-rotate-control" @click="resetFlashCards()">
											<i class="fas fa-undo fa-flip-horizontal"></i>
										</button>

									</div>
								</div>
							</div> -->

							<div class="cd-body bottom-round">
								<div class="flashcard-container justify-content-center h-100 align-items-center">
									<div :class="'flashcard-box col-md-7 px-0 ' + (isActive ? 'active' : '')"
										id="flashcardBox">

										<div class="flashcard" @click="toggleActive()">
											<div class="fcard-front">
												<!-- <div class="fcard-header">
													<div class="fcard-pages ms-auto align-self-start">
														<span class="fcard-cur-page">{{ activeCardIndex + 1 }}</span> of
														<span class="fcard-page-count">{{ flashCards.length }}</span>
													</div>
												</div>/fcard-header -->

												<div :class="['fcard-body']" id="flashcardBodyFront">
													<div class="fcard-text" v-html="flashCards[activeCardIndex].Slide1"></div>
												</div><!-- /fcard-body -->
												
												<div class="footer-message" style="color:red; align-self: center; margin-bottom: 3px; margin-top: 5px; cursor: pointer;"> Click to Flip </div>

												<div class="fcard-footer justify-content-center align-items-center">
													<div v-if="flashcardsPreparation[flashCards[activeCardIndex].ID]"
														class="text-center">Previous attempt</div>
													<div v-if="flashcardsPreparation[flashCards[activeCardIndex].ID]"
														:class="'alert ' + (flashcardsPreparation[flashCards[activeCardIndex].ID].answer == 'yes' ? 'alert-success' : 'alert-danger') + ' rounded-0 py-2 mb-0 ms-3'">
														{{ (flashcardsPreparation[flashCards[activeCardIndex].ID].answer ==
															'yes' ?
															'Correct' : 'Incorrect') }}
													</div>
												</div><!-- /fcard-footer -->
											</div><!-- fcard-front -->

											<div class="fcard-back">
												<!-- <div class="fcard-header">
													<div class="fcard-pages ms-auto align-self-start">
														<span class="fcard-cur-page">{{ activeCardIndex + 1 }}</span> of
														<span class="fcard-page-count">{{ flashCards.length }}</span>
													</div>
												</div>/fcard-header -->

												<div :class="['fcard-body']" id="flashcardBodyBack">
													<div class="fcard-text" v-html="flashCards[activeCardIndex].Slide2"></div>
												</div><!-- /fcard-header -->

												<div class="footer-message" style="color:red; align-self: center; margin-bottom: 3px; margin-top: 5px; cursor: pointer;"> Click to Flip</div>

												<div class="fcard-footer justify-content-center">
													<div class="w-100 text-center pb-1">Did you get it right?</div>
													<div class="actions-row w-100 d-flex justify-content-center row gx-3">
														<div class="col">
															<button
																@click="setFlashcardResponse(flashCards[activeCardIndex], 'no')"
																class="btn btn-outline-primary w-100">No</button>
														</div>
														<div class="col">
															<button
																@click="setFlashcardResponse(flashCards[activeCardIndex], 'yes')"
																class="btn btn-outline-primary w-100">Yes</button>
														</div>
													</div>
												</div><!-- /fcard-footer -->
											</div><!-- fcard-back -->

										</div><!-- /flashcard -->
									</div><!-- /flashcard-box -->
								</div>
							</div><!-- cd-body -->

						</div><!-- /eb-content -->

					</div><!-- /tab-pane -->

					<div :class="['flashcard-page tab-pane', { show: activeTab == 1 }, { active: activeTab == 1 }]"
						id="FCListPane" role="tabpanel" aria-labelledby="FCListTab" tabindex="1">

						<div class="eb-content flashcard-list-content">
							<div class="cd-body round">
								<div class="eb-header eb-header-auto border-bottom fc-list-header">
									<div class="row flash-list-subheader px-0 mx-0 w-100">
										<div class="col-4 fc-list-top-info ps-4 py-4">
											<b>{{ flashCards.length }}</b>
											<p>Number of Cards:</p>
										</div>
										<div class="col-4 fc-list-top-info ps-4 py-4">
											<b class="text-success">{{ getCorrectAnswersLength() }}</b>
											<p>Correct</p>

										</div>
										<div class="col-4 fc-list-top-info ps-4 py-4">
											<b class="text-danger">{{ getIncorrectAnswersLength() }}</b>
											<p>Incorrect</p>
										</div>
									</div>

								</div><!-- /eb-header -->

								<div class="flashcard-table-wrapper">
									<table class="table flashcard-table">
										<thead>
											<tr>
												<th scope="col" width="5%" class="font-medium bg-light">#</th>
												<th scope="col" width="20%" class="font-medium bg-light">Side 1</th>
												<th scope="col" class="font-medium bg-light">Side 2</th>
												<th scope="col" width="15%" class="font-medium bg-light">Response</th>
											</tr>
										</thead>
										<tbody>
											<tr class="t-row" id="card-list" @click="handleListCardItemClick(index)"
												v-for="(card, index) in flashCards" :key="index">
												<td>{{ index + 1 }} </td>
												<td ><div v-html="card.Slide1"></div></td>
												<td ><div v-html="card.Slide2"></div></td>
												<td>
													<img v-if="showFlashcardResponse(card) != 'Not Answered'"
														:src="getStatusIconPath(showFlashcardResponse(card))"
														class="status-icon">
												</td>
											</tr>
										</tbody>
									</table>
								</div>

							</div><!-- /round -->

						</div><!-- /eb-content -->

					</div><!-- /tab-pane Flashcard List -->
					<div class="flashcard-footer-row">
						<div class="fcard-progress">
							<div class="progress mb-1">
								<div class="progress-bar" role="progressbar" :style="{ width: `${getProgress}%` }"
									:aria-valuenow="getProgress" aria-valuemin="0" aria-valuemax="100">
								</div>
							</div>
						</div>

						<div class="fcard-footer-actions eb-sub-header bg-white py-1 justify-content-between row mx-0">
							<div class="fcard-actions-col-1 col-md-4">

							</div>
							<div class="fcard-actions-col-2 eb-chapter-arrows d-flex col-md justify-content-center">
								<button :disabled="activeCardIndex <= 0" @click="prevCard" class="eb-chapter-prev"><i
										class="fas fa-caret-left"></i></button>
								<div class="fcard-pages mx-2">
									<span class="fcard-cur-page">{{ (activeCardIndex + 1) }}</span> / <span
										class="fcard-page-count">{{
											flashCards.length }}</span>
								</div>
								<button :disabled="activeCardIndex >= (flashCards.length - 1)" @click="nextCard"
									class="eb-chapter-next"><i class="fas fa-caret-right"></i></button>
							</div>

							<div
								class="fcard-actions-col-3 eb-chapter-controls ms-auto d-flex justify-content-end col-md-4">

								<!-- <button @click="toggleViewMode" class="eb-filter-toggle ms-auto"><i
											v-if="viewMode == 'study'" class="fas fa-align-left me-2"></i> <i v-else
											class="fa fa-credit-card"></i></button>
									<button @click="resetFlashcard" class="eb-rotate-control"><i
											class="fas fa-undo fa-flip-horizontal"></i></button> -->

							</div>
						</div><!-- eb-sub-header -->
					</div>
				</div>
			</div><!-- /eb-content-wrapper -->
		</div>

		<center v-else class="no-content text-center">
			<h4 class="mt-4">No FlashCards Found</h4>
		</center>

	</div>
</template>

<script>
// imports come here

import { mapActions } from 'vuex';

export default {
	name: "ProductContentFlashCards",
	data() {
		return {
			loading: false,
			activeTab: 0,
			isActive: false,
			activeCardIndex: 0,
			flashCards: [],
			flashcardsPreparation: {},
			completionStatuses: {},
			productVariantID: null
		};
	},
	methods: {
		...mapActions('content', ['getUserContentByProductVariant', "setUserContentAction", "fetchUserContentActionsByID"]),
		...mapActions("productVariant", ["getProductVariantProgress"]),
		...mapActions("content", ["updateAction"]),

		changeTab(index) {
			this.activeTab = index;
			this.applyMathJax();
		},
		fetchData() {
			this.loading = true;
			this.getUserContentByProductVariant({
				productVariantID: this.$route.params.id,
				dataType: 'flashcards'
			}).then((data) => {

				var tempFlashCards = [];

				for (let item of data) {

					let cards = [];
					try {
						cards = JSON.parse(item.data || '{}').Flashcards
					}
					catch (e) {
						cards = [];
					}


					for (let card of cards) {
						card.contentId = item.id;

						if (card.ID !== undefined) {
							tempFlashCards.push(card)
						}
					}
				}

				this.flashCards = tempFlashCards;
				this.getFlashcardResponse();
			}).catch((error) => {
				this.loading = false;
				console.error("Error fetching flash cards: ", error);
			});

		},
		toggleActive() {
			this.isActive = !this.isActive;
			this.checkScrollable();
		},
		nextCard() {
			if (this.activeCardIndex != this.flashCards.length - 1) {
				this.activeCardIndex++;
				this.isActive = false;
			}
		},
		prevCard() {
			if (this.activeCardIndex > 0) {
				this.activeCardIndex--;
				this.isActive = false;
			}
		},
		resetFlashCards() {
			this.isActive = false;
			this.activeCardIndex = 0;
		},
		async setFlashcardResponse(card, answer) {
			const id = card.ID;
			const contentId = card.contentId;

			if (this.flashcardsPreparation[id]) {
				this.flashcardsPreparation[id].answer = answer;
			} else {
				this.flashcardsPreparation[id] = {
					id: contentId,
					answer: answer,
				};
			}

			const Data = {};
			for (const key in this.flashcardsPreparation) {
				const value = this.flashcardsPreparation[key];
				if (value.id === contentId) {
					Data[key] = value.answer;
				}
			}
			console.log("Data: ", Data)

			const contentAction = {
				ContentID: contentId,
				DataType: "flashcardResponse",
				Data: JSON.stringify(Data),
			};

			try {
				this.setUserContentAction(contentAction).then((action) => {

					var self = this.flashcardsPreparation;
					this.flashcardsPreparation = null;
					this.flashcardsPreparation = self;

					this.updateAction({
						"id": action.id,
						"userID": action.userID,
						"contentID": contentId,
						"dataType": action.dataType,
						"data": action.data
					});

					let completed = this.isAllOfOneSectionCompleted(contentId)
					if (completed && !this.isCurrentSectionAlreadyCompleted(contentId)) {
						this.markCompleted(contentId, completed);
					}
				});
			} catch (error) {
				console.error("Error occurred while setting flashcard response:", error);
			}
		},
		getFlashcardResponse() {
			try {
				this.fetchUserContentActionsByID({ productVariantID: this.$route.params.id, dataType: "flashcardResponse" }).then((actions) => {

					for (let action of actions) {
						let parsedData = JSON.parse(action.data);
						for (let key of Object.keys(parsedData)) {

							if (key !== null && key !== undefined && key !== 'undefined' && key !== 'null') {
								this.flashcardsPreparation[key] = {
									id: action.content.id,
									answer: parsedData[key]
								}
							}
						}
					}

					this.loading = false;
				});
			} catch (ex) {

			}
		},
		// Working
		isAllOfOneSectionCompleted(contentId) {
			const filteredFlashcards = this.flashCards.filter(card => card.contentId === contentId);
			let response = filteredFlashcards.every(card => !!this.flashcardsPreparation[card.ID]);
			return response;
		},
		isCurrentSectionAlreadyCompleted(contentId) {
			let keys = Object.keys(this.completionStatuses)
			const filteredList = keys.filter(key => key === contentId);

			if (filteredList > 0) {
				for (let key of filteredList) {
					if (!this.completionStatuses[key]) {
						return false;
					}
					else if (this.completionStatuses[key] && this.completionStatuses[key] === 'completed') {
						return true;
					}
					else {
						return false;
					}
				}
			}
			else {
				return false;
			}
		},
		fetchCompletionStatuses() {
			this.fetchUserContentActionsByID({ productVariantID: this.$route.params.id, dataType: "completionStatus" }).then((data) => {
				console.log("Completion Statuses: ", data);

				for (let item of data) {
					this.completionStatuses[item.content.id] = item.data;
				}

			}).catch((error) => {

				console.error("Error fetching user content actions: ", error);
			});
		},
		markCompleted(contentId, isCompleted) {
			if (contentId) {
				var contentAction = {
					ContentID: contentId,
					DataType: "completionStatus",
					Data: isCompleted ? "completed" : "uncompleted"
				}

				this.setUserContentAction(contentAction).then((action) => {
					if (action && action.dataType && action.dataType.toLowerCase() == "completionstatus") {
						this.completionStatuses[contentId] = action.data;
						this.getProductVariantProgress(this.$route.params.id);
						this.updateAction({ "id": action.id, "userID": action.userID, "contentID": contentId, "dataType": action.dataType, "data": action.data });
					}

				});
			}
		},
		showFlashcardResponse(card) {
			var answer = this.flashcardsPreparation &&
				this.flashcardsPreparation[card.ID] &&
				this.flashcardsPreparation[card.ID].answer
				? this.flashcardsPreparation[card.ID].answer == 'yes' ? "Correct" : "Incorrect" : 'Not Answered';
			return answer;
		},
		getStatusIconPath(action) {
			return action === 'Correct' ? require('@/assets/images/check-icon-green-circle.svg') : require('@/assets/images/cross-icon.svg');
		},
		getCorrectAnswersLength() {
			let counter = 0;
			for (let card of this.flashCards) {
				if (this.showFlashcardResponse(card) === 'Correct') {
					counter++;
				}
			}
			return counter;
		},
		getNotAnsweredLength() {
			let counter = 0;
			for (let card of this.flashCards) {
				if (this.showFlashcardResponse(card) === 'Not Answered') {
					counter++;
				}
			}
			return counter;
		},
		getIncorrectAnswersLength() {
			let counter = 0;
			for (let card of this.flashCards) {
				if (this.showFlashcardResponse(card) === 'Incorrect') {
					counter++;
				}
			}
			return counter;
		},
		handleListCardItemClick(index) {
			this.activeTab = 0;
			this.activeCardIndex = index;
			this.isActive = false;
		},

		activateMathJax(){
			this.processMathJax(document.getElementById('flashcardBodyFront'))
			this.processMathJax(document.getElementById('flashcardBodyBack'))
			this.processMathJax(document.getElementById('card-list'));
		},

		applyMathJax() {
			if (window.MathJax) {
				this.$nextTick(() => {
				window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
				});
			}
			},

		checkScrollable() {
			this.adjustAlignment('flashcardBodyFront');
			this.adjustAlignment('flashcardBodyBack');

			this.activateMathJax();
		},

    adjustAlignment(elementId) {
      const element = document.getElementById(elementId);
      const isScrollable = element.scrollHeight > element.clientHeight;

      // Toggle classes based on scrollable state
      if (isScrollable) {
        element.classList.add('scrollable');
        element.classList.remove('not-scrollable');
      } else {
        element.classList.add('not-scrollable');
        element.classList.remove('scrollable');
      }
    },

	processMathJax(element) {
      if (window.MathJax) {
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, element]);
      }
    }
	},
	computed: {
		getProgress() {
			if (this.flashCards.length === 0) {
				return "0";
			}
			const progress = ((this.activeCardIndex + 1) / this.flashCards.length) * 100;
			return `${progress.toFixed(0)}`;
		},
	},
	watch: {
		$route(to, from) {

			if (this.$route.params.id && this.productVariantID != this.$route.params.id) {
				this.productVariantID = this.$route.params.id;
				this.fetchData();
				this.fetchCompletionStatuses();
			}

		}
	},
	components: {},
	created() {
		if (this.$route.params.id && this.productVariantID != this.$route.params.id) {
			this.productVariantID = this.$route.params.id;
			this.fetchData();
			this.fetchCompletionStatuses();
		}
		window.document.title = "Flashcards";


	},
	mounted() {
	 setTimeout(() => {this.checkScrollable()}, 300);
	}
};
</script>

<style scoped>
/*======= Flashcards =========*/
.flashcard-page .cd-body {
	height: calc(100vh - 210px);
	overflow: auto;
}

.flashcard-container {
	margin: 0px;
}

.flashcard-content {
	font-size: 1rem;
}

.has-floating-menu.Flashcards .floating-menu+.chapter-content-left {
	padding-right: 0;
	/* background-color: rgb(248 249 250) !important; */
	height: 100% !important;
}

/* .flashcard-content .eb-sub-header {
  margin-right: -54px;
} */

.flashcard-box {
	height: 100%;
	width: 100%;
}

.flashcard {
	position: relative;
	width: 100%;
	height: 100%;
	transition: transform 0.8s;
	transform-style: preserve-3d;

}


.flashcard-box.active .flashcard {
	/* transform: rotateY(180deg); 
  animation: flipOut .25s ease-in-out 1; */
}

.fcard-front,
.fcard-back {
	position: absolute;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	flex-direction: column;
	display: flex;
	background: white;
	animation-fill-mode: forwards;
	z-index: 1;
}

.fcard-front {
	animation: flipIn .25s ease-in-out 1;

	z-index: 4;
}

.fcard-back {
	/* display: none;  */
	animation: flipOut .25s ease-in-out 1;
	/* transform: rotateY(180deg); */
}

.flashcard-box.active .fcard-back {
	animation: flipIn .25s ease-in-out 1;
	z-index: 6;

}

.flashcard-box.active .fcard-front {
	display: none;
	animation: flipOut .25s ease-in-out 1;
}

@keyframes flipIn {
	0% {
		transform: rotateX(180deg)
	}

	to {
		transform: rotateX(1turn)
	}
}

@keyframes flipOut {
	0% {
		transform: rotateX(0deg)
	}

	to {
		transform: rotateX(180deg)
	}
}


.fcard-header {
	/* border-bottom: solid 1px rgba(var(--primary-color-rgb), 0.25); */
	height: 60px;
	display: flex;
	padding: 10px;
	cursor: pointer;
}

.fcard-pages span {
	font-size: 14px;
}

.fcard-pages span {
	font-weight: 600;
}

.fcard-footer {
	/* border-top: solid 1px rgba(var(--primary-color-rgb), 0.4); 
  height: 84px; */
	display: flex;
	padding: 10px 10px 20px;
	flex-wrap: wrap;
}

.fcard-body {
  height: 209px;
  overflow-y: auto;
  display: flex;
  /* background: linear-gradient(to bottom, white 29px, rgba(0, 0, 0, 0.09) 1px);
  background-size: 100% 30px; */
  justify-content: center;
  align-items: center;
  padding: 20px 10%;
  text-align: center;
  cursor: pointer;
  transition: margin-top 0.3s ease, padding-top 0.3s ease;
}


.fcard-body.scrollable {
  align-items: flex-start;
}

.fcard-body.not-scrollable {
  align-items: center;
}

.fcard-front .fcard-body,
.fcard-back .fcard-body {
  flex-grow: 1;
}

/* Custom scrollbar styles */
.fcard-body::-webkit-scrollbar {
  width: 10px;
}

.fcard-body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.fcard-body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.fcard-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.fcard-front .fcard-body,
.fcard-back .fcard-body {
	flex-grow: 1;
}



.flashcard-table>:not(caption)>*>*,
.flashcard-table tr td {
	padding-bottom: 1.25rem;
	padding-top: 0.8rem;
}

.flashcard-table>:not(caption)>*>* {
	padding-bottom: 0.8rem;
}

.flashcard-table tbody tr:last-child td {
	border-bottom: none !important;
}

.fcard-progress .progress {
	height: 6px;
}

.fcard-progress .progress .progress-bar {
	background-color: var(--primary-color) !important;
}

.Flashcards .floating-menu {
	top: 20px;
	margin: 0;
	right: 10px;
	position: absolute;
	z-index: 5;
}

.fcard-footer .actions-row .col {
	flex: 0 0 auto;
	width: 24%;
}

.status-icon {
	margin-left: 20px;
	width: 24px;
	height: auto;
}

.flashcard-header-row {
	height: 36px;
}

.flashcard-footer-row {
	height: 50px;
	background-color: white;
}

.flashcard-bg-row {
	height: calc(100% - 50px);
}

.no-content {
	margin-top: 50px;
	margin-bottom: 50px;
}

.status-icon {
	margin-right: 10px;
	width: 24px;
	height: auto;
}

.loading {
	margin-top: 250px;
	margin-bottom: 250px;
}

.ebook-digital .transparent {
	background: transparent;
}

.flashcard-table {
	font-size: 1rem;
}

@media (max-width: 768px) {
	.flashcard-page .cd-body {
		height: auto;
	}

	.ebook-digital .flashcard-page .cd-body {
		height: auto !important;
		min-height: auto !important;
	}
	

	.main-tabs-row .nav-tabs .nav-link {
		font-size: 1rem;
	}

	.eb-content-wrapper {
		padding-top: 0px;
	}

	.flashcard-list-content .eb-header {
		padding: 0;
	}

	.flashcard-container {
		height: calc(100dvh - 280px) !important;
	}

	.ebook-digital .flashcard-container {
		height: calc(100dvh - var(--header-height) - 180px) !important;
		padding-bottom: 20px !important;
	}

	.ebook-digital .flashcard-table-wrapper{
		height: calc(100dvh - var(--header-height) - 260px);
		overflow-y: scroll !important;
	}

}
</style>
