<template>
	<div class="p-3 col-lg-12">
		<div v-if="isNotStartedClass(this.contentData) || isActiveClass(this.contentData)"
			class="live-class-content ongoing text-center">

			<img class="page-view-icon live-class-icon mb-3" src="@/assets/images/live-class-gray-icon.svg" />
			<div class="text-center">
				<h3 class="live-alert d-inline-block round">{{ contentData.title }}</h3>
			</div>
			<h4 class="lcc-chapter-title mt-3 mb-4">
				<strong>{{ this.contentData.title }}</strong>
			</h4>
			<div class="lcc-time">
				<div class="datebox mx-auto mb-2">
					<span class="datebox-day">{{ getMonthDate(this.contentData.availableFrom) }}</span>
					<span class="datebox-month">{{ getMonthName(this.contentData.availableFrom) }}</span>
				</div>
				<div class="text-muted">Starts at:</div>
				<h5>
					<span v-if="contentExtraProperties">
						{{ timeParser(scheduledTime) }}
					</span>
					<span v-else> 1 hour </span>
				</h5>
			</div>
			<div class="d-flex justify-content-center">
				<button v-if="isActiveClass(this.contentData)" @click="handleContentClick" type="button"
					class="primary-btn lcc-join-btn btn-round mx-2">
					<img class="" src="@/assets/images/video-camera-white-icon.svg" />
					Start Class
				</button>
			</div>
		</div><!-- ongoing -->


		<div v-else-if="!isPresent(this.attendence)" class="p-3 col-lg-12 live-class-content missed text-center">
			<img class="page-view-icon missed-class-icon mb-3" src="@/assets/images/live-class-missed-icon.svg" />

			<h2 class="mt-2 mb-1">You <span class="text-warning">Missed</span> this class</h2>

			<h4 class="lcc-chapter-title mt-3 mb-4">
				<strong>{{ this.contentData.title }}</strong>
			</h4>
			<div class="lcc-time">
				<div class="datebox mx-auto mb-2">
					<span class="datebox-day">{{ getMonthDate(this.contentData.availableFrom) }}</span>
					<span class="datebox-month">{{ getMonthName(this.contentData.availableFrom) }}</span>
				</div>
				<div class="text-muted">Starts at:</div>
				<h5>
					<span v-if="contentExtraProperties">
						{{ timeParser(scheduledTime) }}
					</span>
					<span v-else> 1 hour </span>
				</h5>
			</div>
			<div v-if="getReason" class="d-flex justify-content-center">
				<div class="btn-outline-primary btn-round mx-2 px-4 py-2">
					<b>Reason: <span class="text-danger">{{ getReason }}</span></b>
				</div>
			</div>
		</div><!-- missed -->


		<div v-else class="p-3 mt-5 col-lg-12 live-class-content present text-center">
			<img class="page-view-icon present-class-icon mb-3" src="@/assets/images/live-class-present-icon.svg" />

			<h2 class="text-center">You are <span class="text-success">Present</span> in this class</h2>

			<h4 class="lcc-chapter-title mt-3 mb-4">
				<strong>{{ this.contentData.title }}</strong>
			</h4>
			<div class="lcc-time-container">
				<div v-for="(timeline, index) in this.timelines" :key="index" class="lcc-time"
					@click="selectTimeline(timeline)">
					<div class="datebox mx-auto mb-2">
						<span class="datebox-day">{{ getMonthDate(timeline.startTime) }}</span>
						<span class="datebox-month">{{ getMonthName(timeline.startTime) }}</span>
					</div>
					<div class="text-muted">Conducted at:</div>
					<h5>
						<span>
							{{ timeParser(timeline.startTime) }}
						</span>
					</h5>
				</div>
			</div>
			<div v-if="selectedTimeline && getTotalDuration() !== 0" class="lcc-timeline w-75 mx-auto mb-4">
				<div class="d-flex timeline-labels-row justify-content-between align-items-center">
					<div class="d-flex timeline-label-start pe-3"> <b>{{ getFormattedTime(selectedTimeline.startTime) }}</b>
					</div>
					<div class="d-flex timeline-labels text-black-50 justify-content-between">
						<span v-for="interval in 12" :key="interval"> {{ getIntervalDuration(interval) }}</span>
					</div>
					<div class="d-flex timeline-label-end justify-content-end ps-3"><b>{{
						getFormattedTime(selectedTimeline.endTime) }}</b>
					</div>
				</div>
				<div class="round timeline-progress-row d-flex">
					<div v-for="(item, index) in selectedTimeline.timeline" :key="index" :class="['timeline-segment', { 'offline': item.status === 'absent' },
						{ 'online': item.status === 'present' }, { 'gap': item.status === 'gap' }]"
						:style="{ width: getDurationByStatusPercent(item) + '%' }">

						<div v-if="getDurationByStatus(item) && getDurationByStatusPercent(item) > 20"
							class="timeline-progress-label">{{ getDurationByStatus(item) }}</div>
						<div v-if="getDurationByStatus(item) && getDurationByStatusPercent(item) > 10"
							class="timeline-progress-val">{{ getDurationByStatusPercent(item).toFixed(0) + "%" }}</div>
					</div>
				</div>
			</div>
			<div v-else-if="!selectedTimeline">
				<b class="no-timeline-text">No Timeline Selected</b>
			</div>
			<div v-else>
				<b class="no-timeline-text">No Timeline</b>
			</div>
		</div>

	</div>
</template>

<script>

import { mapState, mapActions } from "vuex";
import { getCookie } from "../../../helpers/CookieHelpers";

import {
	toShortDateString,
	getTimeIn12Hours,
	getInputDateFormat
} from "../../../helpers/FormatHelpers";
export default {
	name: "LiveClass",
	data() {
		return {
			attendence: null,
			attendenceList: [],
			timelines: [],
			selectedTimeline: null,
		}
	},
	methods: {
		...mapActions("content", ["fetchSingleLiveClassAttendance"]),

		timeParser(date) {
			return getTimeIn12Hours(date);
		},
		dateParser(date) {
			return toShortDateString(date);
		},
		handleContentClick() {
			var joinURLFromCookies = getCookie(`${this.contentData.id}_${this.user?.id}`);
			if (joinURLFromCookies) {
				window.location.href = joinURLFromCookies;
				return;
			}

			this.$router.push(this.contentLink);
		},
		getMonthDate(dateString) {
			return new Date(dateString).getDate()
		},
		getMonthName(dateString) {
			const dateObj = new Date(dateString);
			const monthIndex = dateObj.getMonth();

			const monthNames = [
				'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
				'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
			];

			return monthNames[monthIndex];
		},
		isActiveClass(liveClass) {
			var currentDate = new Date();

			const start = new Date(liveClass.availableFrom)
			const end = new Date(liveClass.availableTo)

			return currentDate > start && currentDate < end
		},
		isNotStartedClass(liveClass) {
			var currentDate = new Date();

			const start = new Date(liveClass.availableFrom)

			return currentDate < start;
		},
		isPresent(attendence) {
			if (!attendence) {
				return false;
			}

			return attendence.status && attendence.status.toLowerCase() === 'present';
		},
		getLiveClassAttendenceReport() {
			if (this.$route.params.id != null) {

				this.fetchSingleLiveClassAttendance({
					contentId: this.contentData.id,
					userId: this.user.id
				})
					.then((data) => {
						if (data.length > 0) {
							data.sort((a, b) => new Date(a.timeIn) - new Date(b.timeIn));
							this.attendenceList = [...data];
							this.attendence = data[data.length - 1];


							if (!(this.isNotStartedClass(this.contentData)
								|| this.isActiveClass(this.contentData))
								&& !this.isPresent(this.attendance)) {
								this.manageAttendenceProgress()
							}
						}
					});
			}
		},
		getFormattedTime(time) {

			let formattedTime = time.toLocaleString('en-US', {
				hour: 'numeric',
				minute: 'numeric',
				hour12: true
			});

			return formattedTime;
		},


		getTotalDuration() {
			const seconds = this.getDateTimeToSeconds(
				this.selectedTimeline.startTime,
				this.selectedTimeline.endTime
			);

			return seconds;
		},

		getDateTimeToSeconds(startTime, endTime) {
			const durationInMillis = endTime - startTime;
			const durationInSeconds = Math.floor(durationInMillis / 1000);
			return durationInSeconds;
		},

		getIntervalDuration(intervalNumber) {
			const totalDuration = this.getTotalDuration();
			const intervalCount = 12;
			const intervalDuration = totalDuration / intervalCount;
			let currentDuration = 0;

			for (let i = 0; i < intervalNumber; i++) {
				currentDuration += intervalDuration
			}

			const currentDurationInMinutes = currentDuration / 60;
			return currentDurationInMinutes.toFixed(0);
		},

		manageAttendenceProgress() {
			let startTime = new Date(this.contentData.availableFrom);
			let endTime = new Date(this.contentData.availableTo);

			let updatedAttendenceList = [];
			let meetingHostGaps = this.calculateTimeGaps(); // List

			let previousTimeOut = startTime;

			this.attendenceList.forEach((attendance, index) => {
				let timeIn = new Date(new Date(attendance.timeIn).toLocaleString());
				let timeOut = new Date(new Date(attendance.timeOut).toLocaleString());

				if (!attendance.timeOut) {
					for (let i = index + 1; i < this.attendenceList.length; i++) {
						const nextTimeIn = this.attendenceList[i].timeIn;
						if (nextTimeIn) {
							timeOut = new Date(new Date(this.attendenceList[i].timeIn).toLocaleString());
							break;
						}
					}
				}

				if (timeIn > previousTimeOut) {
					let absentEntry = {
						status: 'absent',
						duration: ((timeIn - previousTimeOut) / 1000),
						timeIn: previousTimeOut.toISOString(),
						timeOut: timeIn.toISOString(),
					};

					if (absentEntry.duration < 60) {
						updatedAttendenceList.push(absentEntry);
					}

				}

				if (attendance.duration !== 0) {
					updatedAttendenceList.push(attendance);
					previousTimeOut = timeOut;
				}
			});

			if (previousTimeOut < endTime) {
				let absentEntry = {
					status: 'absent',
					duration: ((endTime - previousTimeOut) / 1000),
					timeIn: previousTimeOut.toISOString(),
					timeOut: endTime.toISOString(),
				};

				updatedAttendenceList.push(absentEntry);

			}
			let sortedList = [...updatedAttendenceList, ...meetingHostGaps];
			sortedList.sort((a, b) => new Date(a.timeIn) - new Date(b.timeIn));

			let mergedList = this.mergeSameStatuses([...sortedList])

			this.timelines = this.createTimelines([...mergedList])

			this.attendenceList = [...mergedList];
		},

		mergeSameStatuses(newList) {
			let mergedList = [];

			let currentIndex = 0;
			while (currentIndex < newList.length) {
				const currentStatus = newList[currentIndex].status;
				let mergedItem = { ...newList[currentIndex] };

				while (currentIndex + 1 < newList.length && newList[currentIndex + 1].status === currentStatus) {
					currentIndex++;
					mergedItem.timeOut = newList[currentIndex].timeOut;
					mergedItem.duration = (mergedItem.duration || 0) + (newList[currentIndex].duration || 0);
				}

				mergedList.push(mergedItem);

				currentIndex++;
			}

			mergedList.sort((a, b) => new Date(a.timeIn) - new Date(b.timeIn));

			return mergedList;
		},


		calculateTimeGaps() {
			let meetings = this.contentData.extraProperties.MeetingIntervals || {};

			const sortedMeetings = Object.entries(meetings)
				.map(([id, meeting]) => ({ id, ...meeting }))
				.sort((a, b) => new Date(a.Starts_At) - new Date(b.Starts_At));

			const gapDetails = [];
			if (sortedMeetings.length > 0) {
				for (let i = 1; i < sortedMeetings.length; i++) {
					const prevMeetingEnd = new Date(sortedMeetings[i - 1].Ends_At);
					const currentMeetingStart = new Date(sortedMeetings[i].Starts_At);

					if (currentMeetingStart > prevMeetingEnd) {
						let timeIn = new Date(prevMeetingEnd);
						let timeOut = new Date(currentMeetingStart);

						gapDetails.push({
							status: 'gap',
							duration: ((timeOut - timeIn) / 1000), // Convert milliseconds to seconds
							timeIn: timeIn,
							timeOut: timeOut,
						});
					}
				}
			}

			return gapDetails;
		},

		createTimelines(mergedList) {
			const timelines = [];
			let currentTimeline = [];

			for (let i = 0; i < mergedList.length; i++) {
				const currentItem = mergedList[i];

				if (currentItem.status === 'gap') {
					if (currentTimeline.length > 0) {
						timelines.push([...currentTimeline]);
						currentTimeline = [];
					}
				} else {
					currentTimeline.push(currentItem);
				}
			}

			// If there are items left in the current timeline, add it to the timelines array
			if (currentTimeline.length > 0) {
				timelines.push(currentTimeline);
			}

			// Update timeOut for the last item in each timeline
			timelines.forEach(timeline => {
				const lastItem = timeline[timeline.length - 1];
				if (lastItem && lastItem.timeIn && lastItem.endTime) {
					const endTime = new Date(lastItem.endTime);
					if (lastItem.timeOut && new Date(lastItem.timeOut) < endTime) {
						lastItem.timeOut = endTime.toISOString();
					}
				}
			});

			// Convert timelines to the desired format
			let formattedTimelines = timelines.map(timeline => {
				let totalDuration = 0;
				timeline.forEach(item => totalDuration += item.duration);

				let startTime = new Date(timeline[0].timeIn);
				let endTime = new Date(timeline[timeline.length - 1].timeOut);

				const totalDurationByTime = this.getDateTimeToSeconds(startTime, endTime);

				if (totalDuration < totalDurationByTime) {
					const missingDuration = totalDurationByTime - totalDuration;

					if (timeline[timeline.length - 1].status !== 'absent') {
						timeline.push({
							status: 'absent',
							duration: missingDuration,
							timeIn: new Date(endTime.getTime() - missingDuration * 1000).toISOString(),
							timeOut: endTime.toISOString(),
						});
					}
					else {
						timeline[timeline.length - 1].duration += missingDuration
						timeline[timeline.length - 1].timeIn =
							new Date(endTime.getTime() - timeline[timeline.length - 1].duration * 1000).toISOString();
					}
				}

				return {
					startTime: startTime,
					endTime: endTime,
					timeline: timeline
				};
			});


			if (formattedTimelines.length > 0) {
				this.selectTimeline(formattedTimelines[0]);
			}

			return formattedTimelines;
		},



		selectTimeline(timeline) {
			this.selectedTimeline = timeline
		},

		getDurationByStatus(attendance) {
			if (attendance) {
				if (attendance.status === 'present') {
					return 'online';
				} else if (attendance.status === 'absent') {
					return 'offline';
				}
				else if (attendance.status === 'gap') {
					return 'gap';
				}
			}
			return '';
		},

		getDurationByStatusPercent(attendance) {
			const percent = (attendance.duration / this.getTotalDuration()) * 100;
			return percent;
		}
	},
	props: {
		contentData: Object,
	},
	computed: {
		...mapState("user", ["loggedIn", "user"]),

		getReason() {
			const attendence = this.attendence;

			if (!attendence) {
				return null;
			}

			const { reason } = attendence;
			if (reason && reason.trim() !== '') {
				return reason.trim();
			}

			return null;
		},
		contentLink() {

			return {
				path:
					"/content/liveclass/" +
					this.contentData.title
						.toLowerCase()
						.replace(/[^a-zA-Z0-9 ]/g, "")
						.split(" ")
						.join("-") +
					"-" +
					this.contentData.id +
					"_" +
					this.$route.params.id,
			};

		},
		contentExtraProperties() {
			if (this.contentData.extraProperties) return this.contentData.extraProperties;
			else return null;
		},

		scheduledTime() {
			let intervals = this.contentExtraProperties.MeetingIntervals;

			if (intervals) {
				let keys = Object.keys(intervals);
				return intervals[keys[0]].Starts_At
			}
			else {
				return this.contentExtraProperties.Start_Time
			}
		},

		isDisabled() {
			return (
				(getInputDateFormat(new Date(this.contentData.availableFrom)) !=
					getInputDateFormat(new Date())) ||
				(new Date(this.contentData.availableFrom) > new Date())
			);
		},
	},
	mounted() {
		this.getLiveClassAttendenceReport();
	},
	created() {
	},
};
</script>

<style scoped>
.no-timeline-text {
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	color: var(--color-red);
	padding: 10px 25px;
	background-color: #f7f7f7;
	border-radius: 5px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	margin-right: 15px;
}

.live-alert {
	border: solid 2px var(--primary-color);
	color: var(--primary-color);
	padding: 6px 20px;
	font-size: 1.35rem;
}

.live-class-content .page-view-icon {
	width: 48px;
	height: auto;
	margin-right: 0px;
}

.live-class-content .missed-class-icon,
.live-class-content .present-class-icon {
	width: 60px;
}

.lcc-time-container {
	display: inline-flex;
}

.lcc-time {
	margin: 30px 0 20px;
	margin-right: 15px;
}

.lcc-join-btn {
	padding: 10px 30px;
}

.lcc-join-btn img {
	width: 22px;
	margin: -2px 4px 0 0;
}

.LiveClass .text-warning {
	color: #FEAA00 !important;
}

.LiveClass .text-success {
	color: #34D8A4 !important;
}


@media (min-width: 1200px) {

	.h4,
	h4 {
		font-size: 1.35rem;
	}
}

.timeline-progress-row {
	background-color: #f2f2f2;
	column-gap: 3px;
	padding: 10px 12px;
	margin-top: 10px;
	width: calc(100% - 20px);
	margin-left: 10px;
}

.timeline-label-start,
.timeline-label-end {
	line-height: 1.1;
	font-size: 0.95rem;
}

.timeline-labels {
	font-size: 0.825rem;
	flex-grow: 1;
	position: relative;
}

.timeline-labels:before {
	content: '';
	position: absolute;
	top: 48%;
	width: 100%;
	height: 1px;
	z-index: -1;
	border-top: solid 1px var(--border-color);
}

.timeline-labels>span {
	background-color: white;
	display: inline-block;
	padding: 0 4px;
}

.timeline-segment {
	height: 26px;
	align-items: center;
	color: white;
	font-size: 0.85rem;
	overflow: hidden;
	background-color: black;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2px 5px 2px 8px;
	border-radius: 5px;
}

.timeline-segment.offline {
	background-color: var(--primary-color);
}

.timeline-segment.online {
	background-color: var(--live-class-success);
}

.timeline-segment.gap {
	border: 1px solid black;
	color: black;
	background-color: transparent;
}

.timeline-progress-val {
	padding: 0 3px 0 5px;
	border-radius: 40px;
	background-color: rgba(0, 0, 0, 0.17);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}</style>