import { render, staticRenderFns } from "./ProductContentChaptersContentList.vue?vue&type=template&id=61894d02&scoped=true"
import script from "./ProductContentChaptersContentList.vue?vue&type=script&lang=js"
export * from "./ProductContentChaptersContentList.vue?vue&type=script&lang=js"
import style0 from "./ProductContentChaptersContentList.vue?vue&type=style&index=0&id=61894d02&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61894d02",
  null
  
)

export default component.exports