import { productVariantService } from "../services/productVariant.service";
import productVariantObject from "../models/productVariant";
import Vue from "vue";
const state = {
  productVariants: { items: {}, length: 0 },
  productVariantsByCategory: {},
  productVariantsByProduct: {},
  loading: false,
  copiedProductVariant: null,
  productVariantsProgress:{}
};

const getters = {
  allProductVariants: (state) => {
    return Object.values(state.productVariants.items);
  },
};

const actions = {
  fetchProductVariants: async ({ commit }, filters) => {
    commit("setLoading", true);
    // state.loading = true;
    var productVariants = { items: {}, length: 0 };
    await productVariantService.fetchAllProductVariants(filters).then(
      (productVariant) => {
        var data = productVariant?.payload;

        /// adding missing product details to show on UI
        data.map((variant) => {
          // console.log(variant);
          var tempVariant = { ...variant };
          tempVariant.properties = {
            ...tempVariant.properties,
           
            pricing: { currency: "PKR", amount: 0 },
            ratings: { value: variant.rating, numberOfRatings: 0 },
          };
          if (tempVariant.product) {
            tempVariant.product.productThumbnailPath =
              "assets/images/sample-thumbnail.jpeg";
            tempVariant.product.productFriendlyTitle = tempVariant.product
              .productTitle
              ? tempVariant.product.productTitle
                  .toLowerCase()
                  .replace(" - ", "-")
                  .replace(/\s/g, "-")
              : null;
          }
          productVariants.items[variant.productVariantID] =
            new productVariantObject(tempVariant);
        });
        ///

        // const productVariantObjects = data.map(
        // 	(variant) => new productVariantObject(variant)
        // );

        // console.log(productVariantObjects);
        // console.log(category);
        productVariants["length"] = data.length;
        commit("setProductVariants", productVariants);
        commit("setLoading", false);
      },
      (error) => {
        Vue.toasted.error(error.message, {
          icon: "exclamation-triangle",
          theme: "outline",
          position: "bottom-center",
          duration: 3000,
        });
        commit("setLoading", false);
      }
    );
  },
  getProductVariantById: async ({ commit }, id) => {
    commit("setLoading", true);
    // state.loading = true;
    console.log("getProductVariantById", id);

    const data = await productVariantService.fetchProductVariantById(id).then(
      (productVariant) => {
        var variant = productVariant?.payload;
        /// adding missing product details to show on UI
        var tempVariant = { ...variant };
        tempVariant.properties = {
          ...tempVariant.properties,
          
          pricing: { currency: "PKR", amount: 0 },
          ratings: { value: variant.rating, numberOfRatings: 0 },
        };
        
        if (tempVariant.product) {
          tempVariant.product.productThumbnailPath =
            "assets/images/sample-thumbnail.jpeg";
          tempVariant.product.productFriendlyTitle = tempVariant.product
            .productTitle
            ? tempVariant.product.productTitle
                .toLowerCase()
                .replace(" - ", "-")
                .replace(/\s/g, "-")
            : null;
        }

        // console.log(category);
        // commit("setProductVariants", productVariant?.payload);
        return new productVariantObject(tempVariant);
      },
      (error) => {
        Vue.toasted.error(error.message, {
          icon: "exclamation-triangle",
          theme: "outline",
          position: "bottom-center",
          duration: 3000,
        });
        commit("setLoading", false);
      }
    );
    // console.log("data", data);
    // commit("setLoading", false);
    commit("addProductVariant", { data, id });
    return data;
  },
  getProductVariantByCategoryId: async ({ commit }, id) => {
    commit("setLoading", true);
    // state.loading = true;
    console.log("getProductVariantByCategoryId", id);

    const data = await productVariantService
      .fetchProductVariantByCategoryId(id)
      .then(
        (productVariant) => {
          var data = productVariant?.payload;
          console.log(data);

          /// adding missing product details to show on UI
          data = data.map((variant) => {
            // console.log(variant);
            var tempVariant = { ...variant };
            tempVariant.properties = {
              ...tempVariant.properties,
             
              pricing: { currency: "PKR", amount: 0 },
              ratings: { value: variant.rating, numberOfRatings: 0 },
            };
           
            if (tempVariant.product) {
              tempVariant.product.productThumbnailPath =
                "assets/images/sample-thumbnail.jpeg";
              tempVariant.product.productFriendlyTitle = tempVariant.product
                .productTitle
                ? tempVariant.product.productTitle
                    .toLowerCase()
                    .replace(" - ", "-")
                    .replace(/\s/g, "-")
                : null;
            }
            return new productVariantObject(tempVariant);
          });
          return data;
        },
        (error) => {
          Vue.toasted.error(error.message, {
            icon: "exclamation-triangle",
            theme: "outline",
            position: "bottom-center",
            duration: 3000,
            // singleton: true,
            // action: {
            // 	text: "RETRY",
            // 	onClick: (e, toastObject) => {
            // 		toastObject.goAway(0);
            // 		window.location.reload();
            // 	},
            // },
          });
          commit("setLoading", false);
        }
      );
    // console.log("data", data);
    // commit("setLoading", false);
    // commit("addProductVariantByCategory", { data, id });
    commit("addProductVariantByCategory", { data, id });
    commit("setLoading", false);
    return data;
  },
  getProductVariantByProductId: async ({ commit }, id) => {
    commit("setLoading", true);
    // state.loading = true;
    // console.log("getProductVariantByProductId", id);
    const data = await productVariantService
      .fetchProductVariantByProductId(id)
      .then(
        (response) => {
          var data = response?.payload;

          /// adding missing product details to show on UI
          data = data.map((variant) => {
            // console.log(variant);
            var tempVariant = { ...variant };
            tempVariant.properties = {
              ...tempVariant.properties,
             
              pricing: { currency: "PKR", amount: 0 },
              ratings: { value: variant.rating, numberOfRatings: 0 },
            };
            
            if (tempVariant.product) {
              tempVariant.product.productThumbnailPath =
                "assets/images/sample-thumbnail.jpeg";
              tempVariant.product.productFriendlyTitle = tempVariant.product
                .productTitle
                ? tempVariant.product.productTitle
                    .toLowerCase()
                    .replace(" - ", "-")
                    .replace(/\s/g, "-")
                : null;
            }
            // productVariants[
            // 	tempVariant.product.productID
            // ] = new productVariantObject(tempVariant);
            return new productVariantObject(tempVariant);
          });
          console.log("data", data);
          return data;
        },
        (error) => {
          Vue.toasted.error(error.message, {
            icon: "exclamation-triangle",
            theme: "outline",
            position: "bottom-center",
            duration: 3000,
            // singleton: true,
            // action: {
            // 	text: "RETRY",
            // 	onClick: (e, toastObject) => {
            // 		toastObject.goAway(0);
            // 		window.location.reload();
            // 	},
            // },
          });
          commit("setLoading", false);
        }
      );
    // console.log("data", productVariants);
    // commit("setLoading", false);
    // commit("addProductVariantByCategory", { data, id });

    commit("addProductVariantByProduct", { data, id });
    commit("setLoading", false);
    return data;
  },
  getProductVariantProgress: async ({ commit },  id=null) => {
    const data = await productVariantService
      .fetchProductVariantProgress(id)
      .then(
        (response) => {
          var data = response?.payload;
            return data;
        },
        (error) => {
          Vue.toasted.error(error.message, {
            icon: "exclamation-triangle",
            theme: "outline",
            position: "bottom-center",
            duration: 3000,
          });
        }
      );
      if(data && data.length>0)
      {
        if (!id) {
          var productVariantProgress = {};
          data.forEach(element => {
            productVariantProgress[element.productVariantID] = element;
          });
          state.productVariantsProgress = productVariantProgress;
        }else{
          var ob=state.productVariantsProgress;
          state.productVariantsProgress=null;
          ob[id]=data[0];
          state.productVariantsProgress=ob;
        }
			}
    return data;
  },
  createProductVariant: async ({ commit }, productVariant) => {
    commit("setLoading", true);
    // state.loading = true;
    console.log("createProductVariant", productVariant.title);
    if (productVariant.productID) {
      var nextSequence =
        state.productVariantsByProduct[productVariant.productID] &&
        state.productVariantsByProduct[productVariant.productID].length > 0
          ? state.productVariantsByProduct[productVariant.productID].at(-1)
              .sequence + 1
          : 1;
      console.log(nextSequence);
      content.sequence = nextSequence;
    }
    const data = await productVariantService
      .createProductVariant(productVariant)
      .then(
        (productVariant) => {
          var tempVariant = productVariant?.payload;

          /// adding missing product details to show on UI

          tempVariant.properties = {
            ...tempVariant.properties,
            
            pricing: { currency: "PKR", amount: 0 },
            ratings: { value: tempVariant.rating, numberOfRatings: 0 },
          };
          
          if (tempVariant.product) {
            tempVariant.product.productThumbnailPath =
              "assets/images/sample-thumbnail.jpeg";
            tempVariant.product.productFriendlyTitle = tempVariant.product
              .productTitle
              ? tempVariant.product.productTitle
                  .toLowerCase()
                  .replace(" - ", "-")
                  .replace(/\s/g, "-")
              : null;
          }

          return new productVariantObject(tempVariant);

          // console.log("response", data);
          // data = new productObject(data);
          // return data;
        },
        (error) => {
          Vue.toasted.error(error.message, {
            icon: "exclamation-triangle",
            theme: "outline",
            position: "bottom-center",
            duration: 3000,
            // singleton: true,
            // action: {
            // 	text: "RETRY",
            // 	onClick: (e, toastObject) => {
            // 		toastObject.goAway(0);
            // 		window.location.reload();
            // 	},
            // },
          });
          commit("setLoading", false);
        }
      );
    // console.log("id: ", id);
    commit("addProductVariantByProductInArray", { data, id: data.product.id });
    commit("setLoading", false);
    return data;
  },
  createProductVariantFeedback: async (
    { commit },
    productVariantFeedbackData
  ) => {
    const res = await productVariantService
      .createProductVariantFeedback(productVariantFeedbackData)
      .then(
        (response) => {
          var data = response;
          return data;
        },
        async (error) => {
          let _error = await error.json();
          return handleError(_error);
        }
      );
    return res;
  },
  updateProductVariant: async ({ commit }, productVariant) => {
    commit("setLoading", true);

    console.log("updateProductVariant", productVariant.id);
    const data = await productVariantService
      .updateProductVariant(productVariant)
      .then(
        (productVariant) => {
          var tempVariant = productVariant?.payload;

          /// adding missing product details to show on UI

          tempVariant.properties = {
            ...tempVariant.properties,
            // },
            pricing: { currency: "PKR", amount: 0 },
            ratings: { value: tempVariant.rating, numberOfRatings: 0 },
          };
         
          if (tempVariant.product) {
            tempVariant.product.productThumbnailPath =
              "assets/images/sample-thumbnail.jpeg";
            tempVariant.product.productFriendlyTitle = tempVariant.product
              .productTitle
              ? tempVariant.product.productTitle
                  .toLowerCase()
                  .replace(" - ", "-")
                  .replace(/\s/g, "-")
              : null;
          }

          return new productVariantObject(tempVariant);
        },
        (error) => {
          Vue.toasted.error(error.message, {
            icon: "exclamation-triangle",
            theme: "outline",
            position: "bottom-center",
            duration: 3000,
          });
          commit("setLoading", false);
        }
      );
    // console.log("id: ", id);
    commit("editProductVariantByProductInArray", { data, id: data.product.id });
    commit("editProductVariantByCategoryInArray", {
      data,
      id: data.product.id,
    });
    commit("addProductVariant", { data, id: data.id });
    commit("setLoading", false);
    return data;
  },
  deleteProductVariant: async ({ commit }, { productVariant, categoryId }) => {
    commit("setLoading", true);
    // state.loading = true;
    console.log("deleteProductVariant", productVariant);
    const data = await productVariantService
      .deleteProductVariant(productVariant.id)
      .then(
        () => {
          commit("setLoading", false);
          commit("deleteProductVariant", { productVariant, categoryId });
          Vue.toasted.success("Batch deleted successfully", {
            theme: "outline",
            position: "bottom-center",
            duration: 3000,
          });
        },
        (error) => {
          Vue.toasted.error(error.message, {
            icon: "exclamation-triangle",
            theme: "outline",
            position: "bottom-center",
            duration: 3000,
          });
          commit("setLoading", false);
        }
      );
  },
  assignContentToProductVariant: async ({ commit }, content) => {
    commit("setLoading", true);
    // state.loading = true;
    console.log("assignContentToProductVariant", content);
    const data = await productVariantService
      .assignContentToProductVariant(content)
      .then(
        (data) => {
          var response = data?.payload;
          console.log("content assigned", content);
          commit("addContentToProductVariantDetails", content);
        },
        (error) => {
          Vue.toasted.error(error.message, {
            icon: "exclamation-triangle",
            theme: "outline",
            position: "bottom-center",
            duration: 3000,
            // singleton: true,
            // action: {
            // 	text: "RETRY",
            // 	onClick: (e, toastObject) => {
            // 		toastObject.goAway(0);
            // 		window.location.reload();
            // 	},
            // },
          });
          commit("setLoading", false);
        }
      );
    // console.log("id: ", id);
    // dispatch("getProductVariantById", content.productVariantID);
    // commit("addContentToProductVariantDetails", content);
    commit("setLoading", false);
    return data;
  },
  PasteProductVariantItems: async ({ commit }, content) => {
    commit("setLoading", true);

    const data = await productVariantService
      .PasteProductVariantItems(content)
      .then(
        (data) => {
          var response = data?.payload;
          console.log("content assigned", content);
          // commit("addContentToProductVariantDetails", content);
          return response;
        },
        (error) => {
          Vue.toasted.error(error.message, {
            icon: "exclamation-triangle",
            theme: "outline",
            position: "bottom-center",
            duration: 3000,
            // singleton: true,
            // action: {
            // 	text: "RETRY",
            // 	onClick: (e, toastObject) => {
            // 		toastObject.goAway(0);
            // 		window.location.reload();
            // 	},
            // },
          });
          commit("setLoading", false);
        }
      );
    // console.log("id: ", id);
    // dispatch("getProductVariantById", content.productVariantID);
    // commit("addContentToProductVariantDetails", content);
    commit("setLoading", false);
    return data;
  },

  PasteProductVariants: async ({ commit }, content) => {
    commit("setLoading", true);

    const data = await productVariantService
      .PasteProductVariants(content)
      .then(
        (data) => {
          var response = data?.payload;
          console.log("content assigned", content);
          // commit("addContentToProductVariantDetails", content);
          return response;
        },
        (error) => {
          Vue.toasted.error(error.message, {
            icon: "exclamation-triangle",
            theme: "outline",
            position: "bottom-center",
            duration: 3000,
            // singleton: true,
            // action: {
            // 	text: "RETRY",
            // 	onClick: (e, toastObject) => {
            // 		toastObject.goAway(0);
            // 		window.location.reload();
            // 	},
            // },
          });
          commit("setLoading", false);
        }
      );
    // console.log("id: ", id);
    // dispatch("getProductVariantById", content.productVariantID);
    // commit("addContentToProductVariantDetails", content);
    commit("setLoading", false);
    return data;
  },

  saveCopiedProductVariant: ({commit}, productVariant) => {
    state.copiedProductVariant = productVariant;
  },
  editProductVariantSequence: async ({ commit }, { productVariant, id }) => {
    commit("setLoading", true);
    await productVariantService
      .editProductVariantSequence({ productVariant, id })
      .then(
        (res) => {
          Vue.toasted.success("Sequence updated successfully!", {
            theme: "outline",
            position: "bottom-center",
            duration: 3000,
          });
        },
        (error) => {
          Vue.toasted.error(error.message, {
            icon: "exclamation-triangle",
            theme: "outline",
            position: "bottom-center",
            duration: 3000,
          });
          console.log(error);
          commit("setLoading", false);
        }
      );
    commit("setLoading", false);
  },
  fetchRatingByProductVariant: async ({ commit }, id) => {
    return await productVariantService.fetchProductVariantRating(id).then(
      (ratings) => {
        return ratings.payload;
      },
      (error) => {
        return [];
      }
    );
  },
  async unenrollAllUsersCourses({ commit }, emails) {
    const data = await productVariantService
      .unenrollAllUsersCourses(emails)
      .then(
        (res) => {
          return res;
        },
        (error) => {
          if (error.error) handleError(error);
          return error;
        }
      );
    return data;
  },
  async enrollUsersSpecificCourses({ commit }, enrollmentData) {
    const data = await productVariantService
      .enrollUsersSpecificCourses(enrollmentData)
      .then(
        (res) => {
          return res;
        },
        (error) => {
          if (error.error) handleError(error);
          return error;
        }
      );
    return data;
  },
  async updateUsersSpecificCoursesSubscription({ commit }, unenrollmentData) {
    const data = await productVariantService
      .updateUsersSpecificCoursesSubscription(unenrollmentData)
      .then(
        (res) => {
          return res;
        },
        (error) => {
          if (error.error) handleError(error);
          return error;
        }
      );
    return data;
  },
  async deleteStudentFeedback({commit},ratingId){
    console.log("My Rating Id is: ",ratingId);
    commit("setLoading", true);
    await productVariantService.deleteStudentFeedback(ratingId).then(
      () => {
        commit("setLoading", false);
      },(error) => {
        handleError(error);
        commit("setLoading", false);
      }
    )
  }
};

const mutations = {
  setProductVariants(state, data) {
    // console.log(data);
    state.productVariants = data;
    state.loading = false;
  },
  addProductVariant(state, { data, id }) {
    if (!state.productVariants.items[id]) {
      state.productVariants.length += 1;
    }
    var updatedItems = {
      ...state.productVariants.items,
      [id]: data,
    };
    // state.productVariants.items = {
    // 	...state.productVariants.items,
    // 	[id]: data,
    // };
    state.productVariants = {
      items: updatedItems,
      length: state.productVariants.length,
    };
    // state.productVariants.items[id] = data;
    state.loading = false;
  },
  addProductVariantByCategory(state, { data, id }) {
    Vue.set(state.productVariantsByCategory, id, data);

    state.productVariantsByCategory = {
      ...state.productVariantsByCategory,
      [id]: data,
    };
    state.loading = false;
  },
  addProductVariantByProduct(state, { data, id }) {
    // state.productVariantsByProduct[id] = data;
    state.productVariantsByProduct = {
      ...state.productVariantsByProduct,
      [id]: data,
    };
    state.loading = false;
  },
  addProductVariantByProductInArray(state, { data, id }) {
    if (state.productVariantsByProduct[id])
      state.productVariantsByProduct[id].push(data);
    else state.productVariantsByProduct[id] = [data];
    console.log(state.productVariantsByProduct[id], data);
    state.loading = false;
  },
  editProductVariantByProductInArray(state, { data, id }) {
    console.log(data, id);
    if (state.productVariantsByProduct[id])
      state.productVariantsByProduct[id] = state.productVariantsByProduct[
        id
      ].map((p) => {
        if (p.id == data.id) {
          return data;
        }
        return p;
      });
    // console.log(state.productVariantsByProduct[id][data.id]);
    // else state.productVariantsByProduct[id] = [data];
    // console.log(state.productVariantsByProduct[id], data);
    state.loading = false;
  },
  editProductVariantByCategoryInArray(state, { data, id }) {
    console.log(data, id);
    if (state.productVariantsByProduct[id])
      state.productVariantsByProduct[id] = state.productVariantsByProduct[
        id
      ].map((p) => {
        if (p.id == data.id) {
          return data;
        }
        return p;
      });
    // console.log(state.productVariantsByProduct[id][data.id]);
    // else state.productVariantsByProduct[id] = [data];
    // console.log(state.productVariantsByProduct[id], data);
    state.loading = false;
  },
  setLoading(state, data) {
    if (data) {
      console.log("productVariants loading...");
    } else {
      console.log("productVariants loading complete!");
    }
    state.loading = data;
  },
  setProductVariantsLength(state, value) {
    state.productVariants.length = value;
  },
  addContentToProductVariantDetails(state, data) {
    console.log("productVariants", data);
    state.productVariants.items[data.productVariantID].productVariantItems = [
      ...state.productVariants.items[data.productVariantID].productVariantItems,
      data.items[0],
    ];
  },
  updateContentInProductVariantDetails(state, data) {

    console.log("productVariants", data);
    if(data && data.productVariantID){
    state.productVariants.items[data.productVariantID].productVariantItems =
      state.productVariants.items[
        data.productVariantID
      ].productVariantItems.map((item) => {
        if (item.id == data.id) {
          // data.data.children = item.data.children;
          return data;
        } else return item;
      });
    }

    // state.productVariants.items[data.productVariantID].productVariantItems.push(
    // 	data.items[0]
    // );
  },
  removeContentFromProductVariantDetails(state, data) {
    console.log("removeContentFromProductVariantDetails", data);
    var filteredItems = state.productVariants.items[
      data.productVariantID
    ].productVariantItems.filter((content) => content.id != data.id);

    state.productVariants.items[data.productVariantID].productVariantItems =
      filteredItems;
  },
  clearProductVariants(state) {
    console.log("clearing productVariants");
    state.productVariants = { items: {}, length: 0 };
    state.productVariantsByCategory = {};
    state.productVariantsByProduct = {};
  },
  deleteProductVariant(state, { productVariant, categoryId }) {
    console.log("delete productVariant", productVariant, categoryId);
    delete state.productVariants.items[productVariant.id];
    var variantsByProduct = state.productVariantsByProduct[
      productVariant.product.id
    ]
      ? state.productVariantsByProduct[productVariant.product.id].filter(
          (v) => v.id != productVariant.id
        )
      : [];

    var variantsByCategory = state.productVariantsByCategory[categoryId]
      ? state.productVariantsByCategory[categoryId].filter(
          (v) => v.id != productVariant.id
        )
      : [];
    if (state.productVariantsByProduct[productVariant.product.id])
      state.productVariantsByProduct = {
        ...state.productVariantsByProduct,
        [productVariant.product.id]: variantsByProduct,
      };

    if (state.productVariantsByCategory[categoryId])
      state.productVariantsByCategory = {
        ...state.productVariantsByCategory,
        [categoryId]: variantsByCategory,
      };
  },
};

function handleError(error) {
  // console.log(error);
  Vue.toasted.error(error.error.message, {
    icon: "exclamation-triangle",
    theme: "outline",
    position: "bottom-center",
    duration: 3000,
  });
  return Promise.reject(error);
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
