<template>
  <b-modal @change="closeModal()" :id="`file-upload-modal-${parentContentId}`" :visible="visible" size="xl" hide-header
    hide-footer centered :content-class="`file-upload-modal ${parentContentId}`" body-class="modal-body"
    modal-class="custom-modal" no-close-on-backdrop :data-id="parentContentId">
    <div class="body-container">
      <b-spinner small class="close-button" v-if="loading"></b-spinner>
      <button v-else @click="closeModal()" class="close-button" role="button">
        <img src="@/assets/images/cancel-icon.svg" />
      </button>
      <h1 class="add-content-title">
        Upload File To Encrypt
      </h1>
      <div v-if="isFileAdded || currentLoading > 0" class="progress mt-3">
              <div class="progress-bar" :style="{ width: `${currentLoading}%` }">{{ currentLoading }}%</div>
      </div>
      <div v-else class="body-content-container">
        <div :class="['upload-container' ]">
          <keep-alive>
            <vue-dropzone  ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"
              :useCustomSlot="true" v-on:vdropzone-file-added="convertAndUpload" v-on:vdropzone-removed-file="removeFile"
              v-on:vdropzone-sending="sendingUploadRequest" v-on:vdropzone-total-upload-progress="updateVideoProgress"
              v-on:vdropzone-success="completeUpload">
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">
                  Drag and drop to upload content!
                </h3>
                <div class="subtitle">
                  ...or click to select a file from your computer
                </div>
                <button role="button" class="upload-btn mt-4">
                  <i class="fas fa-file-import"></i>
                  Select File
                </button>
              </div>
            </vue-dropzone>
          </keep-alive>
        </div>
      </div>
      <p class="text-danger">{{ errorText }}</p>
    
    </div>
  </b-modal>
</template>



<script>
import vue2Dropzone from "vue2-dropzone";
import { mapActions, mapMutations, mapState } from "vuex";
import { contentService } from "../../../services/content.service";
import { POST_FILE_API } from "./../../../constants/urls";
import { getInputDateFormat } from "../../../helpers/FormatHelpers";
import CookieHelpers from "../../../helpers/CookieHelpers";
import DataEncrypter from "../../ProductContentDashboard/Content/data_encrypter";

export default {
  name: "UploadFreetextModal",
  components: {
    vueDropzone: vue2Dropzone
  },
  computed: {
    ...mapState("content", ["loading"]),
    ...mapState("appState", ["apiURL"]),
    ...mapState("appState", ["systemParameters"]),
  },
  props: {
    visible: Boolean,
    parentContentId: String,
    contentData: Object,
    productVariantId: String,
  },
  data() {
    return {
      dropzoneOptions: {
        url: " ",
        thumbnailWidth: 300,
        timeout: 100000000,
        uploadMultiple: false,
        autoProcessQueue: false,
        acceptedFiles: ".html, .htm",
      },
      isFileAdded: false,
      fileUploadSuccess: false,
      uploadedUrl: '',
      errorText: '',
      currentLoading: 0,
    };
  },
  methods: {
    ...mapActions("content", ["updateContent"]),
    ...mapMutations("content", ["setUploading"]),

    convertAndUpload(file) {
      this.isFileAdded = false;
      const reader = new FileReader();
      var self = this;
      reader.onload = (event) => {
        const htmlContent = event.target.result;

        let secret = DataEncrypter.padKey(self.systemParameters.EbookViewerKey, 16);
        let encryptedData = DataEncrypter.encrypt(htmlContent, secret);

        const jsonData = {
          iv: encryptedData?.iv,
          encryptedHtml: encryptedData?.cipher,
        };

        const jsonFileName = `${file.name.toString().split('.')[0]}.json`;
        const jsonBlob = new Blob([JSON.stringify(jsonData)], { type: "application/json" });
        const formData = new FormData();
        formData.append("entity", "Freetext");
        formData.append("type", "pdf");
        formData.append('file', jsonBlob, jsonFileName);

        this.uploadFile(formData);
      };

      reader.readAsText(file);
    },

    uploadFile(formData) {
      const uploadUrl = this.apiURL + POST_FILE_API;
      const xhr = new XMLHttpRequest();

      xhr.open("POST", uploadUrl, true);
      xhr.setRequestHeader("AuthToken", "System123");
      xhr.setRequestHeader("Authorization", `Bearer ${CookieHelpers.getCookie("token")}`);
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          this.currentLoading = Math.round((event.loaded / event.total) * 100);
        }
      };
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 201) {
            let url = JSON.parse(xhr.responseText)?.payload;

            this.updateContent({...this.contentData , 
              IsAvailableForDemo: this.contentData.isContentAvailableForDemo || false, 
              data: { freetext: url }}).then(() => {

              this.fileUploadSuccess = true;
              this.$toasted.success("File uploaded successfully", {
                theme: "outline",
                position: "bottom-center",
                duration: 3000,
              });
              
						  this.$emit("updateModalState", false);
          });
          } else {
            this.errorText = xhr.responseText;
          }
        }
      };

      xhr.send(formData);
    },

    removeFile(file) {
      this.isFileAdded = false;
    },

    closeModal() {
      var confirmed = false;
      if (!this.fileUploadSuccess && this.isFileAdded) {
        confirmed = window.confirm(
          "Are you sure? You will lose all upload progress if you close this window now"
        );
      } else {
        confirmed = true;
      }
      if (confirmed) {
        $(`.file-upload-modal.${this.parentContentId}`).removeClass("min");
        $(".minmaxCon").remove($(`.file-upload-modal.${this.parentContentId}`));
        Object.assign(this.$data, this.$options.data.call(this));
        this.setUploading(false);
        this.$emit("updateModalState", false);
      }
    },

    sendingUploadRequest(file, xhr, formData) {
      console.log({ event: 'sendingUploadRequest', xhr, formData });
      // Optionally modify headers or handle AWS presigned URL logic here
    },

    updateVideoProgress(totaluploadprogress, totalBytes, totalBytesSent) {
      this.$emit("updateProgress", totaluploadprogress, this.contentData.id);
      this.currentLoading = totaluploadprogress;
    },
  },
  watch: {
    visible() {
      if (this.visible) {
        this.currentLoading = 0;
      }
    },
  },
};
</script>


<style>
.progress-bar {
  margin-top: 10px 20px;
  border-radius: 8px;
}
</style>
