<template>
  <nav>
    <div class="header-row">
      <a class="navbar-brand left-item" href="#">
        <img class="logo" src="../../../public/Icap/Images/Assets_Updated/Mobile_Horizantal/Header/logo.png" alt="" />
      </a>

      <div v-if="selectedProductVariant && selectedProductVariant.title && selectedProductVariant.product && selectedProductVariant.product.title" class="product-variant-title center-item">
        {{ selectedProductVariant.product.title }} ({{ selectedProductVariant.title }})
      </div>

      <!-- User Menu with Dropdown -->
      <div class="dropdown right-item">
        <button
          class="btn header-secondary-btn avatar dropdown-toggle"
          type="button"
          id="userMenu"
          @click="toggleUserMenu"
          :aria-expanded="userMenuOpen.toString()"
        >
          <div v-if="user" class="welcome-user">
            <a class="users-icon">
              <i class="fas fa-user user-icon rounded-circle"></i>
            </a>
            <div class="welcome-message">
              <p>Welcome!</p>
              <p>{{ user.firstName }} {{ user.lastName }}</p>
            </div>
          </div>
        </button>
        <ul v-if="userMenuOpen" class="dropdown-menu custom-user-dropdown" aria-labelledby="userMenu">
          <li>
            <a class="dropdown-item" @click="handleDropdownClick('support')">Support</a>
          </li>
          <li>
            <a class="dropdown-item" @click="handleDropdownClick('logout')">Logout</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>


<script>
import { mapActions, mapState } from "vuex";
import { parseForURL } from '../../helpers/FormatHelpers';

export default {
  name: "ICAPHeader",
  data() {
    return {
      searchText: "",
      showDropdown: false,
      userMenuOpen: false,
    }
  },
  components: {

  },
  computed: {
    ...mapState("user", ["loggedIn", "user"]),
    ...mapState("productVariant", ["productVariants"]),

    searchList() {

      var list = [];
      if (this.productVariants.length > 0 && this.searchText !== "") {
        var filteredVariants = Object.values(this.productVariants.items).filter(
          (v) =>
            v.status.toLowerCase() != "private" &&
            v.status.toLowerCase() != "draft"
        );
        for (var item of filteredVariants) {
          if (
            (item.title || "")
              .toLocaleLowerCase()
              .indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
            (item.id || "")
              .toLocaleLowerCase()
              .indexOf(this.searchText.toLocaleLowerCase()) > -1 ||
            (item.product && item.product.title || "")
              .toLocaleLowerCase()
              .indexOf(this.searchText.toLocaleLowerCase()) > -1
          ) {

            list.push(item);

          }
        }
      }
      return list;
    },

    selectedProductVariant() {
     return this.productVariants && this.productVariants.items && this.productVariants.items[this.$route.params.id];
    }



  },
  watch: {
    showDropdown: function () {
      if (this.showDropdown) this.fetchProductVariants();
    },
  },
  methods: {
    ...mapActions("productVariant", ["fetchProductVariants"]),
    ...mapActions("user", ["logout"]),

    handleLinkClick(item) {
      this.searchText = "";
      if (
        this.user &&
        ((this.user.type && this.user.type.toLowerCase() == "student") ||
          (this.user.role && this.user.role.toLowerCase() == "student")) &&
        this.user.productVariants.length > 0
      ) {
        var found = this.user.productVariants.find((p) => p.id == item.id);
        if (found) {
          this.$router.push({ path: this.getProductDetailsUrl(found), 
            query: {...this.$route.query } });
          return;
        }
      }
      this.$router.push(
        `/product/details/${parseForURL(item.product.friendlyTitle)}-${item.id}`
      );
    },
    closeDropdown() {
      setTimeout(() => (this.showDropdown = false), 300);
    },

    validateThemeRoute(themeName, productVariant) {
      return (
        productVariant &&
        productVariant.properties &&
        productVariant.properties.contentViewTheme &&
        productVariant?.properties?.contentViewTheme?.toLowerCase() ===
        themeName?.toLowerCase()
      );
    },

    getProductDetailsUrl(productVariant) {
      if (this.validateThemeRoute("ebookdigital", productVariant)) {
        return "/ebook/dashboard/chapters/" + productVariant.id;
      } else if (this.validateThemeRoute("ebook", productVariant)) {
        return "/product/dashboard/chapters/" + productVariant.id;
      } else {
        return `/product/dashboard/chapters/${productVariant.id}`
      }
    },

    toggleUserMenu() {
      this.userMenuOpen = !this.userMenuOpen;
    },

    handleDropdownClick(action) {
      this.userMenuOpen = false;
      if (action == "logout") {
				this.logout();
				return;
			} else if (action === "support") {
        window.open('https://student.icap.org.pk/icap-helpdesk-lms', '_blank');
        return;
      }

    }

  },
  mounted() {
    this.$nextTick(() => {
      window.postMessage({ event: "header-loaded", data: null });
    });
  },
};
</script>

<style>


</style>

<style scoped>

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  height: var(--header-height);
}

.header-item {
  flex: 1 1 calc(15% - 10px);
}

.left-item {
  margin-left: 2rem !important;
  margin-right: auto; 
}

.center-item {
  flex: 5; 
  text-align: center; 
}

.right-item {
  margin-left: auto; 
  margin-right: 10px;
}

.product-variant-title {
  margin: 0px 0px 0px 120px !important;
  font-size: 1.25rem !important;
  color: #185698 !important;
  white-space: nowrap;      
  overflow: hidden;          
  text-overflow: ellipsis;  
  width: 100%;    
}


.navbar{
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

@media (max-width: 767px) {

  .form-control {
    height: inherit !important;
  }
}

.form-control:focus {
  box-shadow: none !important;
}

@font-face {
  font-family: "Montserrat Variable";
  src: url("../../../public/Icap/font/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat Variable" !important;
}

.header {
  box-shadow: rgba(185, 185, 185, 0.16) 6px 3px 6px;
}

.navbar {
  margin-bottom: 0 !important;
}

.navbar-brand {
  padding: 0;
  margin-left: 0 !important;
}

.container-fluid:before,
.navbar:before {
  display: none !important;
}

@media (min-width: 768px) {
  .navbar-collapse.collapse {
    display: none !important;
  }
}

.logo {
  height: 50px;
  margin-left: 1rem;
}

.navIcon {
  display: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba%2833%2C 37%2C 41%2C 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.search-form {
  visibility: hidden;  
  width: 74vw;
  height: 50px;
  padding: 5px;
  border-radius: 10px;
  font-size: 100% !important;
  --bs-body-font-size: 100% !important;
  font-weight: 500 !important;
  background-color: #fafafa;
  /* box-shadow:
0 3px 6px #dbdbdb29 !important; */
}

.form-control {
  font-size: 1rem !important;
  margin-bottom: 0;
}

.navbar-collapse {
  flex-grow: 0 !important;
  flex-basis: 0 !important;
}

.search-header {
  border: none !important;
  outline: none;
  background-color: transparent !important;
  font-weight: 500 !important;
  box-shadow: none;
}

.heading_search {
  padding-top: 12px;
}
.header-hidden {
visibility: hidden;
}
.magnifying-icon {
  border: none !important;
  outline: none;
  background-color: transparent !important;
}

.user-icon {
  color: #fff;
  background-color: #185698;
  /* border-radius: 18px; */
  padding: 8px;
}

.welcome-user {
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
  /* width: 15vw; */
}

.product-variant-title{
      margin: 0px 0px 0px 50px !important;
}

.welcome-message p {
  margin: 0;
  margin-left: 0.5rem;
  font-weight: bold;
  color: #185698;
}

.welcome-message :nth-child(2) {
  font-weight: normal;
  color: #000;
}

.toggle {
  border: none !important;
}

.header-upside {
  display: flex;
}

.mainheading {
  display: none;
}

@media (max-width: 994px) {
  .logo {
    margin-left: 1rem;
  }

  .tablet-header {
    display: flex;
    align-items: center;
  }

  .search-form {
    flex-direction: row-reverse;
    /* width: 62vw; */
    align-items: center;
    height: 35px;
    width: 38vw;
  }

  .header-upside {
    margin: 0;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
  }

  .heading_search {
    display: none;
    flex-direction: row;
  }

  .mainheading {
    margin-bottom: 0.5rem;
    display: none;
    /* flex-direction: column; */
    gap: 10px;
    margin-left: 10px;
    margin-top: 12px;
  }

  .mainheading p {
    text-align: center;
    margin: 0;
    color: #185698;
    font-size: 22px;
    font-weight: 500;
    line-height: 0.75;
  }

  .search-form {
    display: none;
  }

  .content {
    height: 35vh !important;
  }

  .bannerheading {
    display: none;
  }

  .banner {
    position: relative;
  }

  .listmain {
    position: absolute;
    bottom: 0;
  }

  .accordianlist {
    font-size: 1rem !important;
  }

  .accordion-item {
    background-color: #f4f7f8 !important;
    margin-bottom: 5px !important;
  }
}

@media (max-width: 1365px) {
  .welcome-message p {
    font-size: 12px;
  }
}

/* @media (max-width: 1230px) {
  .welcome-message p {
    font-size: 1px;
  }
} */

@media (max-width: 767px) {
  .right-item {
    margin-left: auto; 
    margin-right: 0px;
  }

  .logo {
    /* margin: 0.5rem; */
  }

  .toggle {
    margin-bottom: 32px;
  }

  .search-form {
    padding: 0px;
    border: 1px solid silver;
    background-color: #fafafa;
    align-self: center;
    margin: 1rem 0;
    width: 80vw;
    display: none !important;
  }

  .users-icon {
    width: 6px;
    margin-left: 0.5rem;
  }

  .user-icon {
    padding: 5px;
  }

  .welcome-user {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 2rem !important;
  }

  .product-variant-title{
      margin: 0px 0px 0px 30px !important;
   }
  

  .welcome-message p {
    text-align: right;
  }

  .header-upside {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .heading_search {
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .mainheading {
    display: none;
    flex-direction: column;
    margin-top: 10px;
  }
}

/* Content */
.content {
  width: 100vw;
  height: 60vh;
}

.banner {
  width: 100%;
  height: 100%;
  /* background: url(assets/Assets_Updated/Web/hero_banner_big.png); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bannerheading {
  color: #185698;
}

.mainlist {
  width: max-content;
}

.listbutton {
  text-align: left;
  background-color: transparent !important;
  border: transparent !important;
  font-size: 1.75rem !important;
  color: gray !important;
}

.listbutton+ul {
  background-color: transparent !important;
}

.mainlist .listbutton::before {
  content: "•";
  /* Unicode character for bullet */
  position: absolute;
  left: -20px;
  /* Adjust as necessary to align the bullet */
  font-size: 1.2em;
  /* Adjust bullet size as needed */
}

.mainlist .btn-group .listbutton {
  flex: none;
}

.dropend:hover .dropdown-toggle::after {
  visibility: visible;
}

.dropend .dropdown-toggle::after {
  visibility: hidden;
}

/* Accordian */

.accordianlist {
  font-size: 1.5rem !important;
  color: gray !important;
}

.accordion {
  --bs-accordion-bg: transparent !important;
  width: max-content;
  --bs-accordion-btn-icon-transform: rotate(-90deg) !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button::before {
  content: "•";
  /* Unicode character for bullet */
  position: absolute;
  left: -20px;
  /* Adjust as necessary to align the bullet */
  font-size: 1.2em;
  /* Adjust bullet size as needed */
}

.accordion-button::after {
  margin-left: 10px !important;
}

.accordion {
  padding: 0.2rem 0.3rem !important;
  --bs-border-color: none !important;
}

.accordion-body {
  padding: 0.2rem 1.25rem !important;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
}

.accordianlist::after {
  background-image: url("https://icons.getbootstrap.com/assets/icons/chevron-right.svg") !important;
}

/* .accordiancustom {
  background-color: transparent !important;
}*/

@media (max-width: 992px) {
  .accordianlist {
    font-size: 1rem !important;
  }

  .banner {
    position: relative;
    background-position: top right;
    background-size: cover;
  }

  .accordiancustom {
    position: absolute;
    top: 20px;
  }

  .accordion {
    width: 80vw;
    --bs-accordion-btn-padding-y: 0.5rem !important;
  }

  .accordion-button::before {
    background-color: #f4f7f8 !important;
    /* padding: 6.5px; */
    /* padding: 6.6px 8px 6.7px 8px; */
    padding: 0.42rem 0.5rem;
  }
}

@media (max-width: 767px) {
  .accordiancustom {
    left: -30px;
  }

  .content {
    height: 30vh !important;
  }
}

@media (max-width: 470px) {
  .accordion-button::before {
    background-color: transparent !important;
  }
}

/* Carousel */
.carousel-container {
  width: 100vw;
}

.carousel__list {
  display: flex;
  justify-content: space-evenly;
  position: relative;
}

.carousel__item {
  position: relative;
}

.carousel__item span {
  font-size: 0.75rem;
  position: absolute;
  top: 50px;
  left: -11px;
  text-align: center;
}

.wide {
  width: 4rem;
  height: 3rem;
  /* background: url(assets/Assets_Updated/Mobile_Horizantal/Navigation_Icon/Wide_Compatability.svg); */
  background-size: contain;
  background-repeat: no-repeat;
}

.rivh {
  /* background: url(assets/Assets_Updated/Mobile_Horizantal/Navigation_Icon/Rivh_multimedia_content.svg); */
  background-size: contain;
  background-repeat: no-repeat;
  width: 4rem;
  height: 3rem;
}

.optimal {
  /* background: url(assets/Assets_Updated/Mobile_Horizantal/Navigation_Icon/Optimal_Readability.svg); */
  background-size: contain;
  background-repeat: no-repeat;
  width: 4rem;
  height: 3rem;
}

.effortless {
  /* background: url(assets/Assets_Updated/Mobile_Horizantal/Navigation_Icon/Effortless_Navigation.svg); */
  background-size: contain;
  background-repeat: no-repeat;
  width: 4rem;
  height: 3rem;
}

.multicolor {
  /* background: url(assets/Assets_Updated/Mobile_Horizantal/Navigation_Icon/Multi_Color_Highlighting.svg); */
  background-size: contain;
  background-repeat: no-repeat;
  width: 4rem;
  height: 3rem;
}

.note {
  /* background: url(assets/Assets_Updated/Mobile_Horizantal/Navigation_Icon/Group-2.svg); */
  background-size: contain;
  background-repeat: no-repeat;
  width: 4rem;
  height: 3rem;
}

.audio {
  /* background: url(assets/Assets_Updated/Mobile_Horizantal/Navigation_Icon/Group-3.svg); */
  background-size: contain;
  background-repeat: no-repeat;
  width: 4rem;
  height: 3rem;
}

.progresss {
  /* background: url(assets/Assets_Updated/Mobile_Horizantal/Navigation_Icon/Layer_1.svg); */
  background-size: contain;
  background-repeat: no-repeat;
  width: 4rem;
  height: 3rem;
}

.offline {
  /* background: url(assets/Assets_Updated/Mobile_Horizantal/Navigation_Icon/Group.svg); */
  background-size: contain;
  background-repeat: no-repeat;
  width: 4rem;
  height: 3rem;
}

.future {
  /* background: url(assets/Assets_Updated/Mobile_Horizantal/Navigation_Icon/Group-1.svg); */
  background-size: contain;
  background-repeat: no-repeat;
  width: 4rem;
  height: 3rem;
}

@media (max-width: 992px) {
  .dummycarousel {
    position: relative;
  }

  .carousel-container {
    position: absolute;
    top: 130px;
  }

  .carousel__item {
    font-size: 3rem;
  }
}

@media (max-width: 778px) {
  .carousel__item {
    font-size: 2rem;
  }

  .future {
    display: none;
  }

  .offline {
    display: none;
  }

  .progresss {
    display: none;
  }

  .audio {
    display: none;
  }

  .note {
    display: none;
  }
}

/* Dasbhobard Page */
.dashboard {
  display: flex;
  padding: 0 80px;
  justify-content: center;
  width: 100vw;
  height: 88vh;
  /* background-image: url(assets/Assets_Updated/Web/pattern/Frame\ 2.png); */

  background-size: contain;
}

.dashboard-content {
  height: 100%;
  width: 100%;
  padding: 30px 0;
}

.dashboardheading {
  font-size: 3rem;
}

.tablecards {
  display: flex;
  align-items: center;
  gap: 20px;
}

.table-container {
  width: 55%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow: hidden;
  border: 30px solid #fff;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.tableheading th {
  font-size: 1.4rem;
  font-weight: 500;
}

.tableheading :nth-child(2) {
  background-color: #6d6e72;
}

thead {
  background-color: #58585a;
  color: white;
  text-align: left;
}

thead th {
  padding: 15px 20px;
}

.subject {
  background-color: #17abaf;
  color: white;
  font-weight: 500;
}

/* tbody tr:nth-child(odd) {
  background-color: #e6f9ff;
}

tbody tr:nth-child(even) {
  background-color: #ccf2ff;
} */

tbody td {
  padding: 15px 20px;
}

tr.spaceUnder>td {
  border-bottom: 0.2em solid #fff;
  border-top: 0.2em solid #fff;
}

.time {
  text-align: center;
  font-weight: bold;
  color: #fff;
  background-color: #1f5c95;
  font-size: 1.4em;
}

.time span {
  font-size: 0.8em;
  margin-left: 5px;
  font-weight: 100;
}

.hours {
  font-size: 1.2em !important;
  font-weight: 700 !important;
}

.icons {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* .NewsIcon {
  background-image: url(/assets/Assets_Updated/Mobile_Horizantal/Other_Btn/News_&_Updates_Updated_1.svg);
}
.PlannerIcon {
  background-image: url(/assets/Assets_Updated/Mobile_Horizantal/Other_Btn/PlannerUpdated.svg);
} */

.NewsIconSvg {
  width: 10rem;
  height: 10rem;
}

.PlannerIconSvg {
  width: 10rem;
  height: 10rem;
}

/* .tableicon {
  background-repeat: no-repeat;
  background-size: contain;
  width: 20rem;
  height: 12rem;
} */

@media (max-width: 992px) {
  .dashboard {
    height: 85vh;
  }

  .table-container {
    width: 100%;
  }

  .tbody td {
    padding: 12px;
  }

  .tablecards {
    width: 86vw;
  }
}

@media (max-width: 767px) {
  .table-container {
    width: 100vw;
  }

  .tablecards {
    flex-direction: column;
  }

  .icons {
    flex-direction: row;
  }

  tbody td {
    padding: 10px 5px;
  }

  .dashboard {
    height: 72vh;
    padding: 0;
  }

  .tablecards {
    width: 100vw;
  }

  .table-container {
    border: 15px solid #fff;
  }
}

/* Search  */

.searchbar-mobile {
  height: 36px;
  border: none;
  outline: none;
  padding: 12px 36px 12px 24px;
  width: 100%;
}

#search {
    
  line-height: 1;
  height: 42px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  background-color: #fafafa;
  outline: none;
  padding: .375rem .75rem;
  width: 100%;
  box-shadow: 0 3px 6px #dbdbdb29;
}

.search-icon {
  font-size: 18px;
  position: absolute;
  margin-right: 1.5rem;
  right: 0;
  color: #000000;
}

.search-dropdown {
  position: absolute;
  top: 6rem;
  background-color: #fff;
  z-index: 1000;
  width: 74vw;
  box-shadow: 0 3px 6px #2f2f2f1f;
  border-radius: 8px;
}

.searchbar ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: 8px;
  max-height: 70vh;
  overflow-y: auto;
}

.searchbar ul li {
  padding: 1rem;
  font-size: 1rem;
  color: #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s linear;
}

.searchbar ul li:hover {
  background-color: #f1f1f1;
}

.searchbar ul li:first-of-type {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.searchbar ul li:last-of-type {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.searchbar-image {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  object-position: top;
  border-radius: 6px;
  margin-right: 1rem;
}

/* Dropdown */
#userMenu {
  align-content: center;
  cursor: pointer;
}

.header-secondary-btn {
  cursor: pointer;
}

.users-icon {
  margin-right: 10px;
}

.user-icon {
  font-size: 1.5em;
}

.welcome-message p {
  margin: 0;
}

.custom-user-dropdown {
  position: absolute;
  top: 97%;
  right: 0; /* Align to the right */
  z-index: 1000;
  display: none;
  min-width: 150px;
  padding: .5rem 0;
  font-size: 1rem;
  color: #212529;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: .25rem;
}

.custom-user-dropdown a {
  display: block;
  padding: .25rem 1.5rem;
  color: #212529;
  text-decoration: none;
}

.custom-user-dropdown a:hover {
  background-color: #f8f9fa;
  cursor: pointer;
}

.dropdown-toggle::after {
  display: none;
}

.header-secondary-btn:focus + .custom-user-dropdown,
.header-secondary-btn[aria-expanded="true"] + .custom-user-dropdown {
  display: block;
}

.custom-dropdown-item {
  display: flex;
  padding: 1.125rem;
  font-size: 1.125rem;
  font-weight: normal !important;
}

.custom-dropdown-item:hover {
  background-color: #f7f7f7;
}

.dropdown-toggle.show {
  display: flex !important;
}

a {
  text-decoration: none;
}

.dropdown-menu.custom-user-dropdown,
.dropdown-menu.custom-user-dropdown.show{
 left: auto;
 border-radius: 0 0 8px 8px;
}


@media (max-width: 768px) {
  .dropdown-toggle.show {
    display: block !important;
    min-width: 130px;
}
}

</style>
