<template>
	<div :class="['body-content', { 'transparent': loading }]">
		<center v-if="loading" class="loading">
			<b-spinner large></b-spinner>
		</center>
		<div v-else-if="assignments.length > 0" class="eb-content-wrapper">
			<div class="eb-body">
				<div class="eb-header d-flex mb-1">
					<h5>Assignments</h5>
				</div>

				<div class="eb-content eb-practice-content">

					<div class="practice-table">
						<div class="cd-body round">

							<div class="pc-table-wrapper" style="
    height: inherit;
    display: flex;
    flex-direction: column;
">
								<ul class="eb-table pc-table">
									<li class="thead">
										<div class="t-cell" style="flex: 0.25"><b>#</b></div>
										<div class="t-cell" style="flex: 2;"><b>Assignment</b></div>
										<div class="t-cell " style="flex: 0.5;"><b>Status</b></div>
										<div class="t-cell " style="flex: 0.5;"><b>Due Date</b></div>
										<div class="t-cell text-center" style="flex: 0.8;"><b>Result</b></div>
									</li>

								</ul>
								<ul class="eb-table pc-table">

									<li class="t-row" v-for="(assignment, index) in assignments"
									@click="()=>{ $router.push({ params:{ page:'chapters', id:productVariantID }, query:{ chapterId:assignment.topLevelContentID, contentId:assignment.id }}) }"
										:key="assignment.id + '' + index">
										<div class="t-cell" style="flex: 0.25">
											{{ index + 1 }}
										</div>
										<div class="t-cell" style="display: block; flex: 2;">
											<span @click.stop>
											<a role="button" @click="()=>{ $router.push({ params:{ page:'chapters', id:productVariantID }, query:{ chapterId:assignment.topLevelContentID }}) }"									
												v-if="assignment.topLevelContentID && chapters && chapters[assignment.topLevelContentID]"
												class="red">{{ chapters[assignment.topLevelContentID].title }} </a></span>
											<br /><span class="font-medium red">{{ assignment.title ? assignment.title :
												'--' }}</span>

										</div>
										<div class="t-cell" style="flex: 0.5;">
											<span class="font-medium">
												<span class="submitted-status"
													v-if="assignmentResults && assignmentResults[assignment.id] && assignmentResults[assignment.id].isGraded == true">
													Graded
												</span>
												<span
													v-else-if="assignmentResults && assignmentResults[assignment.id] && assignmentResults[assignment.id].isSubmitAssignment == true"
													class="submitted-status">
													<i class="fa fa-check"></i> Submitted
													<small style="font-size: x-small;" class="text-dark"><br/>
														{{ parseDueDate(assignmentResults[assignment.id].submissions[assignmentResults[assignment.id].submissions.length - 1].submittedAt) }}
													</small>
												</span>
												<span v-else-if="isMissing(assignment)" class="pending-status"> Missed
												</span>
												<span v-else class="pending-status">
													<i class="fa fa-ban"></i> Pending
												</span>
											</span>
										</div>

										<div class="t-cell" style="flex: 0.5;">
											<span v-if="assignmentProperties(assignment)" class="font-medium">{{
												assignmentProperties(assignment).Deadline ? parseDueDate(
													assignmentProperties(assignment).Deadline) : "--"
											}}</span>
										</div>
										<div class="t-cell text-center" style="flex: 0.8;">

											<span v-if="assignmentResults[assignment.id] && assignmentResults[assignment.id].isGraded && assignmentProperties(assignment)  && assignmentProperties(assignment).Marks ">
												<span v-if="assignmentResults[assignment.id].result"
													id="percentage" :class="['font-medium',
														{ pass: assignmentResults[assignment.id].result.toLowerCase()=='pass' },
														{ fail:  assignmentResults[assignment.id].result.toLowerCase()=='fail' }
													]">{{ assignmentResults[assignment.id].result }} ({{ assignmentResults[assignment.id].percentage }}%)

													<div class="dropdown-content">
														<small>
															<b>Passing Percentage:</b> {{assignmentProperties(assignment).PassingPercentage }}
														</small>
													</div>
												</span>

												<small><br/>{{ assignmentResults[assignment.id].marks }} Out of {{ assignmentProperties(assignment).Marks }}</small>
											</span>	
											<span v-else>--</span>
											<!-- {{ assignmentResults[assignment.id].marks }}
											<span v-if="assignment.percentage && assignment.passingPercentage"
												id="percentage" :class="['font-medium',
													{ pass: getResultStatus(assignment.passingPercentage, assignment.percentage) },
													{ fail: !getResultStatus(assignment.passingPercentage, assignment.percentage) }
												]">{{ assignment.percentage }}% ({{ getResultStatus(assignment.passingPercentage, assignment.percentage)
	? "Pass" : "Fail" }})

												<div class="dropdown-content">
													<p>
														Passing Percentage: {{ assignment.passingPercentage }}
													</p>
												</div>
											</span>

											<span v-else>--</span> -->
										</div>



									</li>
								</ul>
							</div>
						</div>
					</div>
					<!-- </div>/cd-body -->

				</div>
				<!-- /eb-content -->
			</div><!-- /eb-body -->
		</div><!-- /eb-content-wrapper -->
		<center v-else class="no-content text-center">
			<h4 class="mt-4">No Assignments Found</h4>
		</center>
	</div>
</template>


<script>

import { mapActions, mapState } from 'vuex';

export default {
	name: "ProductContentExams",
	data() {
		return {
			loading: false,
			assignments: [],
			productVariantID: null,
			assignmentResults: {}
		};
	},
	methods: {
		...mapActions('content', ['getUserContentByProductVariant', "setUserContentAction", "fetchUserContentActionsByID"]),
		...mapActions("assignment", [
			"submitAssignment",
			"getSubmittedAssignment",
			"getSubmittedAssignmentResults",
			"updateAssignmentSubmission",
			"getProductvariantAssignmentResults"
		]),
		fetchData() {
			var self = this;
			this.loading = true;
			this.getUserContentByProductVariant({
				productVariantID: this.$route.params.id,
				dataType: 'assignment'
			}).then((data) => {
				this.loading = false;
				this.assignments = data;

			}).catch((error) => {
				this.loading = false;
				console.error("Error fetching assignments: ", error);
			});
			this.getProductvariantAssignmentResults(this.$route.params.id).then(function (results) {
				if (results) {
					results.forEach(function (val, ind, arr) {
						self.assignmentResults[val.content.id] = val;
					});
					var temp = self.assignmentResults;
					self.assignmentResults = null;
					self.assignmentResults = temp;

				}
			});
		},
		assignmentProperties(assignment) {
			if (assignment && assignment.extraProperties) {
				var obj = JSON.parse(assignment.extraProperties);
				return obj;
			}
			return {};
		},
		parseDueDate(dateString) {
			const date = new Date(dateString);

			const options = {
				year: "numeric",
				month: "short",
				day: "numeric",
				hour: "numeric",
				minute: "numeric",
				hour12: true,
			};

			return date.toLocaleString("en-US", options);
		},

		getResultStatus(passingPercentage, percentage) {
			var current = parseInt(percentage);
			var total = parseInt(passingPercentage);

			var pass = current >= total;

			return pass;
		},
		isMissing(assignment) {
			var data = this.assignmentProperties(assignment);
			if (data && data.Deadline && (new Date(data.Deadline) < new Date())) {
				return true;
			}
			return false;
		},

	},
	computed: {
		...mapState("productVariant", ["productVariants"]),
		chapters: function () {
			if (this.productVariants && this.productVariants.items && this.productVariants.items[this.productVariantID] && this.productVariants.items[this.productVariantID].productVariantItems) {
				var items = this.productVariants.items[this.productVariantID].productVariantItems;
				var chapters = {};
				items.forEach(function (val, ind, arr) {
					chapters[val.id] = val;
				});
				return chapters;
			}
			return null;
		}
	},
	watch: {
		$route(to, from) {
			if (this.$route.params.id && this.productVariantID != this.$route.params.id) {
				this.productVariantID = this.$route.params.id;
				this.getAssignmentList();
			}

		}
	},

	components: {},
	created() {
		if (this.$route.params.id && this.productVariantID != this.$route.params.id) {
			this.productVariantID = this.$route.params.id;
			this.fetchData();
		}
	},
	mounted() {
	}
};
</script>

<style scoped>
.ebook-digital .body-content {
	min-height: 90vh;
}

.ebook-digital .cd-body{
	min-height: 80vh;
}

.submitted-status {
	padding-top: 10px;
	color: rgb(var(--bs-success-rgb));
}

.pending-status {
	padding-top: 10px;
	color: rgb(var(--bs-danger-rgb));
}

.pc-table{
	overflow: auto;
}

.ebook-digital .pc-table{ overflow:unset; }

.pc-table .t-row {
	cursor: pointer;
}

.pc-table .t-row:hover {
	background-color: var(--gray-light-hover);
}

.pc-table>li>.t-cell {
	flex: 0.6;
	align-items: center;
	display: flex;
	padding: 15px 5px;
	border-bottom: 0.3px solid var(--border-color);
	border-top: 0.3px solid var(--border-color);
}


.pc-table>li>.t-cell:nth-child(1) {
	flex: 0.3;
	padding-left: 20px;
}

.pc-table>li>.t-cell:nth-child(2) {
	flex: 0.8;
}

.pc-table>li>.t-cell:nth-child(3) {
	flex: 1.2;
}

.pc-table>li>.t-cell:nth-child(4) {
	padding-left: 20px;
}

.pc-table>li>.t-cell:last-child {
	flex: 0.4;
	justify-content: center;
}

.t-cell a {
	font-size: 12px;
}

.t-cell b {
	font-size: 13px;
}


.pass {
	color: green;
}

.fail {
	color: var(--color-red);
}


.action-button {
	font-size: 11px;
	padding: 3px 10px;
	border: 1px solid var(--color-red);
	background-color: transparent;
	border-radius: 10px;
	color: var(--color-text-default);
	cursor: pointer;
	transition: background-color 0.3s, color 0.3s, transform 0.2s;
}

.action-button:hover {
	background-color: var(--color-red);
	color: #fff;
	transform: scale(1.1);
}

.eb-table-arr .t-row {
	position: relative;
}

.eb-table-arr .t-row:before {
	position: relative;
	content: "\f101";
	font-family: "Font Awesome 5 Free";
	-webkit-font-smoothing: antialiased;
	display: block;
	font-style: normal;
	font-variant: normal;
	position: absolute;
	top: 50%;
	margin-top: -15px;
	right: 10px;
	font-size: 20px;
	font-weight: 700;
	-webkit-text-stroke: 1px #fff;
	opacity: 0;
	visibility: hidden;
}

.eb-table-arr .t-row:hover:before {
	opacity: 1;
	visibility: visible;
}

.qr-table>li>.t-cell {
	flex: 0.8;
	padding: 15px 5px;
	border-bottom: 0.3px solid var(--border-color);
	border-top: 0.3px solid var(--border-color);
}

.qr-table>li>.t-cell:nth-child(1),
.qr-table>li>.t-cell:nth-child(2) {
	flex: 0.15;
	text-align: center;
}

.qr-table>li>.t-cell:nth-child(2) {
	flex: 0.5;
}

.qr-table>li>.t-cell:nth-child(3) {
	flex: 5;
}

.qr-table>li>.t-cell:last-child {
	flex: 1.1;
}

.qr-table>li>.t-cell:nth-last-child(2) {
	flex: 0.6;
}

.practice-tabs .eb-header {
	height: auto;
}



.no-content {
	margin-top: 50px;
	margin-bottom: 50px;
}

.loading {
	margin-top: 250px;
	margin-bottom: 250px;
}

.ebook-digital .transparent {
	background: transparent;
}
#percentage {
	position: relative;
}

#percentage .dropdown-content {
	display: none;
	position: absolute;
	background-color: #f1f1f1;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 999;
	top: 100%;
	border-radius: 12px;
	right: 0;
	left: -40px;
}

#percentage .dropdown-content p {
	color: black;
	padding: 13px 16px;
	margin: 0;
	text-decoration: none;
	display: block;
}

#percentage:hover .dropdown-content {
	display: block;
}
</style>
