<template>
	<main>
		<div v-if="contentData">
			{{ this.startMeeting() }}
		</div>
	</main>
</template>

<script>
import router from "../../router";
//const crypto = require("crypto"); // crypto comes with Node.js
import LiveClass from "../../models/content/liveClass";
import ProductVariant from "../../models/productVariant";
import { mapActions, mapState } from "vuex";
import { parseForURL } from "../../helpers/FormatHelpers";
import { setCookie } from "../../helpers/CookieHelpers";

function addSendButtonZoom(selector) {
	var sendBtn = $("#send-btn");
	console.log(sendBtn);
	if (!sendBtn[0]) {
		$(".chat-receiver-list").append(
			"<button id='send-btn' class='btn ms-auto btn-sm btn-primary'>Send</button>"
		);
		$(document).on("click", "#send-btn", () => {
			const ke = new KeyboardEvent("keydown", {
				bubbles: true,
				cancelable: true,
				keyCode: 13,
			});
			document.querySelector(selector).dispatchEvent(ke);
		});
	}
}

$(document).on(
	"focus",
	"#chat-window > div.window-content > div > div:nth-child(4) > textarea",
	() => {
		addSendButtonZoom(
			"#chat-window > div.window-content > div > div:nth-child(4) > textarea"
		);
	}
);

$(document).on(
	"focus",
	"#wc-container-right > div > div:nth-child(5) > textarea",
	() => {
		addSendButtonZoom(
			"#wc-container-right > div > div:nth-child(5) > textarea"
		);
	}
);

export default {
	name: "Zoom",
	props: { contentData: LiveClass, productVariantData: Object },
	data() {
		return {
			// leaveUrl: "",
			role: 0,
			userEmail: null,
			userName: null,
			signature: "",
			sdkKey: "",
			secretKey: "",
			initObj: {},
		};
	},
	created() {
		// console.log(apiKey);
		// this.ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.8/lib", "/av");
		this.ZoomMtg.preLoadWasm();
		this.ZoomMtg.prepareWebSDK();
		// loads language files, also passes any error messages to the ui
		this.ZoomMtg.i18n.load("en-US");
		this.ZoomMtg.i18n.reload("en-US");

		const currentUser = JSON.parse(localStorage.getItem("user"));
		console.log(currentUser);
		// Setting initial data
		this.sdkKey = JSON.parse(
			this.contentData.externalService.serviceData
		).SDKKey;
		this.signature = this.contentData.data.token;
		this.userEmail =
			currentUser?.email ??
			`demo_${Math.random().toString(36).substring(2, 15)}@mail.com`;
		this.userName = currentUser?.firstName ?? "Demo Student";

		this.initObj = {
			meetingNumber: this.contentData.data.meetingId,
			userName: `${this.userName} (${this.userEmail})`,
			userEmail: this.userEmail,
			signature: this.signature,
			sdkKey: this.sdkKey,
			passWord: this.contentData.data.password,
			success: (success) => {
				if (currentUser) {
					var attendanceObj = {
						contentID: this.contentData.id,
						userID: currentUser?.id,
						status: "Present",
						attendedFrom: "Online",
						timeIn: new Date().toISOString(),
						isMarkLate: false,
						isLeftEarly: false,
					};
					this.markLiveClassAttendance([attendanceObj]).then((res) => {
						if (res) {
							console.log(res);
						}
					});
				}
				console.log(success);
				this.ZoomMtg.showInviteFunction({
					show: false,
				});
				this.ZoomMtg.showMeetingHeader({
					show: false,
				});
			},
			error: (error) => {
				console.log(error);
			},
		};
		if (!this.contentData.data.openInSDK) {
			if (currentUser) {
				var attendanceObj = {
					contentID: this.contentData.id,
					userID: currentUser?.id,
					status: "Present",
					attendedFrom: "Online",
					timeIn: new Date().toISOString(),
					isMarkLate: false,
					isLeftEarly: false,
				};
				this.markLiveClassAttendance([attendanceObj])
					.then((res) => {
						if (res) {
							console.log(res);
							this.saveJoinUrl(currentUser);
						}
					})
					.finally(() => {
						window.location.href = this.contentData.data.joining_Url;
					});
				return;
			}
			// this.saveJoinUrl();
			window.location.href = this.contentData.data.joining_Url;
		}

		if (this.contentData.data.registrantAllow) {
			var registrantToken = new URL(
				this.contentData.data.joining_Url
			).searchParams.get("tk");
			this.initObj.tk = registrantToken;
		}
	},
	methods: {
		...mapActions("content", ["markLiveClassAttendance"]),
		startMeeting() {
			document.getElementById("zmmtg-root").style.display = "block";

			this.ZoomMtg.init({
				leaveUrl: this.leaveUrl,
				showMeetingHeader: false, //option
				disableInvite: true, //optional
				disablePreview: true, // optional
				// disableVoIP: true,
				isSupportAV: true,
				disableCallOut: true,
				isShowJoiningErrorDialog: true,
        meetingInfo: ['topic','host','dc', 'enctype'],
				success: (success) => {
					console.log(success);
					this.ZoomMtg.join(this.initObj);
				},
				error: (error) => {
					console.log(error);
				},
			});
		},
		calculatedDuration() {
			var startTime = new Date(this.contentData.availableFrom).getTime();
			var endTime = new Date(this.contentData.availableTo).getTime();
			var diff = endTime - startTime;
			return diff / (1000 * 60);
		},
		saveJoinUrl(currentUser) {
			if (this.contentData.data.joining_Url) {
				var linkExpiryTime = new Date(this.contentData.availableTo).setTime(
					new Date(this.contentData.availableTo).getTime() + 60 * 60 * 1000
				);
				setCookie(
					`${this.contentData.id}_${currentUser?.id}`,
					this.contentData.data.joining_Url,
					new Date(linkExpiryTime).toUTCString()
				);
				console.log(
					`${this.contentData.id}_${currentUser?.id}`,
					linkExpiryTime
				);
			}
		},
		// goBack() {
		// 	console.log("back button");
		// 	this.ZoomMtg.leaveMeeting({});
		// 	this.$router.back();
		// },
	},
	computed: {
		leaveUrl() {
			console.log("leave function");
			if(this.productVariantData){
			return `/product/content/${parseForURL(
				this.productVariantData && this.productVariantData.product?this.productVariantData.product.friendlyTitle:''
			)}-${this.productVariantData.id}`;
			}
			else{
				return `/product/dashboard`;
			}
		},
	},
	mounted() {
		this.ZoomMtg.inMeetingServiceListener("onUserJoin", (data) => {
			console.log("inMeetingServiceListener onUserJoin", data);
		});
	},
	beforeDestroy() {
		this.ZoomMtg.leaveMeeting({
			success: () => {
				console.log("leaveMeeting");
			},
			error: (e) => {
				console.log("error leaving meeting", e);
				document.getElementById("zmmtg-root").style.display = "none";
			},
		});
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
main {
	width: 70%;
	margin: auto;
	text-align: center;
}

main button {
	margin-top: 20px;
	background-color: #2d8cff;
	color: #ffffff;
	text-decoration: none;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 40px;
	padding-right: 40px;
	display: inline-block;
	border-radius: 10px;
	cursor: pointer;
	border: none;
	outline: none;
}

main button:hover {
	background-color: #2681f2;
}
</style>

<style>
div[id="zmmtg-root"] {
  z-index: 999999999999 !important;
}
div[feature-type^="participants"] button {
    display: none;
}
/* .footer-button__button.ax-outline:first-of-type {
	display: none !important;
} */

/* #app-signal {
	display: none !important;
}

.meeting-info-container {
	display: none !important;
}

@media screen and (max-width: 584px) {
	#wc-footer > div > div.footer__btns-container > div.more-button {
		display: block !important;
	}
	#wc-footer
		> div
		> div.footer__btns-container
		> div.more-button
		> div
		> ul
		> li
		> a[aria-label="Stop Incoming Video"],
	#wc-footer
		> div
		> div.footer__btns-container
		> div.more-button
		> div
		> ul
		> li
		> a[aria-label="Stop Incoming Video"]
		+ div {
		display: none !important;
	}
}

@media screen and (min-width: 585px) {
	#wc-footer > div > div.footer__btns-container > div.more-button {
		display: none !important;
	}
}

#wc-footer > div > div.footer__btns-container > div:nth-child(1),
#wc-footer
	> div
	> div.footer__btns-container
	> div.sharing-entry-button-container
	> div.footer-button__button.ax-outline.dropup.btn-group,
#wc-footer > div > div.footer__btns-container > div:nth-child(5) > div,
#wc-footer > div > div:nth-child(1) > div.footer-button__wrapper > button {
	display: none !important;
} */
</style>
