<template>
    <div class="tab-pane" id="notesPane" role="tabpanel" aria-labelledby="notesTab" tabindex="0">
        <center v-if="loading" class="loading">
            <b-spinner large></b-spinner>
        </center>
        <div v-else-if="hasNotes()">
            <div class="eb-header">
                <h5>Notes</h5>
            </div>

            <div class="eb-content">
                <div class="accordion accordion-flush" id="notes">

                    <div v-for="(note, index) in notes">
                        <div class="accordion-item eb-chapter-toggle">
                            <h2 class="accordion-header eb-toggle-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    :data-bs-target="'#note-' + index" aria-expanded="false"
                                    :aria-controls="'note-' + index">
                                    <span>{{ note.title }}</span>
                                    <!-- <span>{{ note.content.length }} {{  "Noted"  }} </span> -->
                                </button>
                            </h2>
                            <div :id="'note-' + index" class="accordion-collapse eb-toggle-body collapse ">
                                <div class="accordion-body py-2 px-3">
                                    <div v-for="content in note.content">
                                        <div class="eb-single-note">
                                            <p class="sub-title">
                                                <a role="button"
                                                    @click="onNotesContentView(null, note.topLevelContentID, content)"
                                                    data-bs-toggle="modal" data-bs-target="#chapterModal" class="red note-title">
                                                    {{ content.title }}
                                                </a>
                                                <span class="sub-notes">{{ content.contentTexts.length }} Noted</span>
                                            </p>


                                            <div v-for="(contentText, index) in content.contentTexts">
                                                <div class="row pt-2 pb-2 px-4">
                                                    <div class="col-lg-10 col-md-8">
                                                        <p class="note-text">
                                                            <i class="far fa-sticky-note" style="color: var(--primary-color); margin-right: 10px;"></i>
                                                            {{ contentText.text }}
                                                        </p>

                                                        <p class="comment-text" v-if="contentText.comment" >
                                                            {{ contentText.comment }}
                                                        </p>
                                                    </div>
                                                    <div class="col-lg-2 col-md-4">
                                                        <div class="eb-edit-bar">
                                                            <button class="eb-notes-view"
                                                                @click="onNotesContentView(contentText, note.topLevelContentID, content)"><i
                                                                    class="fas fa-eye"></i></button>
                                                            <button class="eb-notes-delete"
                                                                @click="removeSinglenote(note, content, contentText)">
                                                                <i class="fas fa-trash-alt"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> <!-- /eb-single-note -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div><!-- /eb-content -->
        </div><!-- /tab-pane notes -->
        <center v-else class="no-content text-center">
            <h4 class="mt-4">No Notes Found</h4>
        </center>
    </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';

export default {
    name: "ProductContentChaptersNotes",
    props: {
        active: Boolean,
        productVariantID: String,
    },
    data() {
        return {
            loading: false,
            notes: []
        };
    },
    methods: {
        ...mapActions("content", ["fetchUserContentActionsByID", "setUserContentAction"]),
        fetchData() {
            this.loading = true;
            this.fetchUserContentActionsByID({ productVariantID: this.productVariantID, dataType: "notes" }).then((data) => {

                var tempNotes = [];

                if (this.productVariants && this.productVariants.items && this.productVariants.items[this.productVariantID + ""]) {
                    var selectedProductVariant = this.productVariants.items[this.productVariantID + ""];

                    for (let item of data) {
                        var content = item.content;

                        var title = selectedProductVariant.productVariantItems.find(x => x.id == content.topLevelContentID).title;

                        var tempContent = {
                            id: item.id,
                            title: title,
                            topLevelContentID: content.topLevelContentID,
                            content: []
                        };

                        var parsedDataList = JSON.parse(item.data);

                        var contentList = [];
                        var contentTexts = [];

                        for (let contentItem of parsedDataList) {
                            contentTexts.push(contentItem);
                        }

                        if (contentTexts.length > 0) {
                            contentList.push({
                                id: content.id,
                                title: content.title,
                                contentTexts: contentTexts
                            })
                        }

                        tempContent.content = contentList;

                        var existingIndex = tempNotes.findIndex((existingItem) => existingItem.topLevelContentID === tempContent.topLevelContentID);

                        if (tempContent.content.length > 0) {
                            if (existingIndex !== -1) {
                                tempNotes[existingIndex].content.push(...tempContent.content);
                            } else {
                                tempNotes.push(tempContent);
                            }
                        }
                    }
                }

                this.notes = tempNotes;
                this.loading = false;
                console.log('Notes: ', tempNotes);

            }).catch((error) => {
                this.loading = false;
                console.error("Error fetching user content actions: ", error);
            });
        },
        removeSinglenote(note, content, contentText) {
            var contentAction = {
                ContentID: content.id,
                DataType: "notes",
                Data: undefined
            };

            var tempNotes = [...this.notes];

            var notesIndex = tempNotes.findIndex((item) => item.id === note.id);

            if (notesIndex !== -1) {
                var contentIndex = tempNotes[notesIndex].content.findIndex((item) => item.title === content.title);

                if (contentIndex !== -1) {
                    var contentTextIndex = tempNotes[notesIndex].content[contentIndex].contentTexts.findIndex((item) => item.id === contentText.id);

                    if (contentTextIndex !== -1) {
                        tempNotes[notesIndex].content[contentIndex].contentTexts.splice(contentTextIndex, 1);
                        contentAction.Data = JSON.stringify(tempNotes[notesIndex].content[contentIndex].contentTexts)
                    }

                    if (tempNotes[notesIndex].content[contentIndex].contentTexts.length === 0) {
                        tempNotes[notesIndex].content.splice(contentIndex, 1);
                    }
                }

                if (tempNotes[notesIndex].content.length === 0) {
                    tempNotes.splice(notesIndex, 1);
                }
            }

            this.setUserContentAction(contentAction).then((action) => {
                if (action && action.dataType && action.dataType.toLowerCase() === "notes") {
                    this.notes = tempNotes;
                }
            });
        },
        onNotesContentView(contentText, topLevelContentID, content) {

            let query = {
                    tab: 'tableofcontents',
                    chapterId: topLevelContentID,
                    contentId: content.id,
                    noteId:  contentText?.id
                };

            if(this.$route.query.categoryId) query = {categoryId: this.$route.query.categoryId, ...query}

            this.$router.push({ query: query });
        },

        hasNotes() {
            return this.notes.length > 0;
        },
    },
    computed: {
        ...mapState("productVariant", ["productVariants"]),
    },
    watch: {
        active: function () {
            if (this.active) {
                this.fetchData()
            }
        },
        productVariantID: function () { 
            if (this.active) {
                this.fetchData()
            }
    }
    },
    components: {},
    created() {
        if (this.active) {
            this.fetchData()
        }
    },
    mounted() { }
};
</script>

<style scoped>
.tab-pane{
    border: none;
}

.eb-chapter-toggle .accordion-button,
.eb-chapter-toggle .accordion-button:not(.collapsed) {
    font-size: 14px;
    color: #231F20;
    font-family: 'Circular Std', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    height: auto;
}

.eb-chapter-toggle .accordion-button span {
 padding-top: 11px;
 padding-bottom: 11.5px;
}


.accordion-body p>a {
    font-size: 12px;
}

.eb-single-note {
    margin-bottom: 20px;
}

.no-content {
    margin-top: 50px;
    margin-bottom: 50px;
}

.loading {
    margin-top: 200px;
    margin-bottom: 200px;
}

.sub-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .sub-notes {
    padding-right: 30px;
  }

  .note-title{
    margin: 20px 50px 10px 20px;
  }

  .note-text {
    font-family: 'Font Awesome' !important; 
    font-size: 14px; 
    font-style: italic; 
    line-height: 1.3;
}

.comment-text {
    font-size: 13px; 
}

</style>
