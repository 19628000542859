<template>
	<div class="login-webDisabled" v-if="showWebDisabledModel">
		<h1 class="heading-bold-20">Please download app for your device to access content.</h1>
		<ul class="download-btn-list">
			<li class="download-btn-list-item" v-if="systemParameters && systemParameters.AndroidAppUpdateUrl">
				<a :href="systemParameters.AndroidAppUpdateUrl" class="download android" target="_blank">
					<i class="fa fa-android fa-3x"></i>
					<span class="df">Download For</span>
					<span class="dfn">Android</span>
				</a>
			</lI>
			<li class="download-btn-list-item" v-if="systemParameters && systemParameters.IOSAppUpdateUrl">
				<a :href="systemParameters.IOSAppUpdateUrl" class="download apple" target="_blank">
					<i class="fa fa fa-apple fa-3x"></i>
					<span class="df">Download For</span>
					<span class="dfn">IOS / IPAD</span>
				</a>
			</li>
			<li class="download-btn-list-item" v-if="systemParameters && systemParameters.WindowsAppUpdateURL">
				<a class="download windows" :href="systemParameters.WindowsAppUpdateURL" target="_blank">
					<i class="fa fa fa-windows fa-3x"></i>
					<span class="df">Download For</span>
					<span class="dfn">Windows</span>
				</a>
			</li>
			<li class="download-btn-list-item" v-if="systemParameters && systemParameters.MacAppUpdateURL">
				<a :href="systemParameters.MacAppUpdateURL" target="_blank" class="download apple">
					<i class="fa fa fa-apple fa-3x"></i>
					<span class="df">Download For</span>
					<span class="dfn">Mac</span>
				</a>
			</li>
		</ul>
	</div>
	<div v-else-if="(content[contentId] || !loading)  && contentData  " :class="'chapter-content has-floating-menu '+(contentData.type == 'FreeText'?' freeTextContent ': contentData.type)" >

		<div v-if="contentData.type != 'FreeText' && contentData.type.toLowerCase() != 'quiz'" class="floating-menu" >
			
			<div v-if="!isTableOfContentVisible" class="floating-icons">
				<b-tooltip target="mark-completed" triggers="hover">{{ isCompleted ? 'Mark Uncomplete' : 'Mark Complete'}}</b-tooltip>
				<b-tooltip target="rating" triggers="hover" placement="lefttop">Rating</b-tooltip>
				<b-tooltip target="bookmark" triggers="hover" placement="left">Bookmark</b-tooltip>
				
				<b-tooltip target="calculator" triggers="hover" placement="left">Calculator</b-tooltip>
				<b-tooltip target="download" triggers="hover">Download</b-tooltip>

				<a v-if="shouldShowMarkCompleteButton" id="mark-completed" @click="markCompleted(!isCompleted)" :class="'float-icon completed-icon ' + (isCompleted ? 'active' : '')" :style="{ textDecoration: 'none' }">
					<i v-if="!(actionsLoading.markcompleted)" :class="isCompleted ? 'fa fa-lg fa-check-circle' : 'far fa-lg fa-check-circle'" :style="{ color: isCompleted ? 'green' : 'black' }"></i>
					<i v-else class="fa fa-spinner fa-spin" :style="{ color: 'black', fontSize: '18px' }"></i>
				</a>

				<b-dropdown id="rating"  ref="ratingDropdown" class="p-0 float-icon" size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <i v-if="!(actionsLoading.rating)" :class="starClass" :style="starStyle"></i>
			<i v-if="(actionsLoading.rating)" class="fa fa-spinner fa-spin" style="color:black; font-size:18px"></i>
          </template>
          <b-dropdown-form style="width: 150px">
			<div v-if="currentRating !== 'unrated'">
              <input class="form-check-input high" type="radio" name="confidenceBtn" :checked="currentRating === 'unrated'"
                id="confidenceBtn0" value="high" @click="setConfidenceLevel('Unrated')" style="display: none;">
              <label class="confidence-btn btn-high" for="confidenceBtn0">
                <i class="fa fa-star-o" style="color: black"></i> Unrated
              </label>
            </div>
            <div>
              <input class="form-check-input high" type="radio" name="confidenceBtn"
                :checked="currentRating === 'high'" id="confidenceBtn1" value="high"
                @click="setConfidenceLevel('High')" style="display: none;">
              <label class="confidence-btn btn-high" for="confidenceBtn1">
                <i class="fa fa-star" style="color: green;"></i> High
              </label>
            </div>
            <div>
              <input class="form-check-input medium" type="radio" name="confidenceBtn"
                :checked="currentRating === 'medium'" id="confidenceBtn2" value="medium"
                @click="setConfidenceLevel('Medium')" style="display: none;">
              <label class="confidence-btn btn-medium" for="confidenceBtn2">
                <i class="fa fa-star" style="color: yellow;"></i> Medium
              </label>
            </div>
            <div>
              <input class="form-check-input low" type="radio" name="confidenceBtn"
                :checked="currentRating === 'low'" id="confidenceBtn3" value="low"
                @click="setConfidenceLevel('Low')" style="display: none;">
              <label class="confidence-btn btn-low" for="confidenceBtn3">
                <i class="fa fa-star" style="color: red;"></i> Low
              </label>
            </div>
          </b-dropdown-form>
        </b-dropdown>

				<a id="bookmark" @click="setBookmark()" :class="'float-icon bookmark-icon animate__animated '+(bookmarks[contentId]=='true'?' active animate__bounceIn':'')">
					<i v-if="!(actionsLoading.bookmark)" :class="'fa fa-lg '+(bookmarks[contentId]=='true'?' fa-bookmark':' fa-bookmark-o')"></i>
					<i v-if="(actionsLoading.bookmark)" class="fa fa-spinner fa-spin" style="color:black; font-size:18px"></i>
				</a>

				<a id="calculator" role="button" @click="showCalculator = true" class="float-icon calculator-icon">
					<img src="../../assets/images/calculator-icon.svg">
				</a>
				<a id="download" role="button" v-if=" isAllowDownloadStudyMaterial" @click="handleDownloadDocumentButtonClick" class="float-icon calculator-icon">
					<i class="fa fa-download"></i>
				</a>
			</div>
		</div>

		<Calculator 
		  :visible="this.showCalculator" 
		  @updateModalState="toggleCalculator" />

		<div class="chapter-content-left">

			<div v-if="contentData.type=='RecordedVideos'">
				<RecordedVideo :contentData="contentData"></RecordedVideo>
			</div>
			<div v-else-if="contentData.type=='StudyMaterial'" style="height: inherit;">
				<StudyMaterial :contentData="contentData"></StudyMaterial>
			</div>
			<div v-else-if="contentData.type=='Flashcards'" style="height: inherit;">
				<Flashcards :contentData="contentData"></Flashcards>
			</div>
			
			<div v-else-if="contentData.type=='FreeText'" style="height: inherit;">
				<FreeText 
				:isShowFloatingOptions="true" 
				:isBookmarked="bookmarks[contentId]=='true'" 
				:contentData="contentData"
				@setContentBookmarked="setBookmark"
				></FreeText>
			</div>
			<div v-else-if="contentData.type=='LiveClass'" style="height: inherit;">
				<LiveClass 
				:contentData="contentData"
				></LiveClass>
			</div>
			<div v-else-if="contentData.type=='Exam'">
				<Exam 
				:contentData="contentData"
				></Exam>
			</div>
			<div v-else-if="contentData.type=='Assignment'">
				<Assignment 
				:contentData="contentData"
				></Assignment>
			</div>
			<div v-else-if="contentData.type.toLowerCase()=='quiz'">
				
				<Quiz
				
				:contentData="contentData"
				></Quiz>
			</div>
			<div v-else>
				
			</div>
		</div>
	</div>
	<div v-else-if="( (loading || !contentData) && !content[contentId])"  class="loading-div">
		<b-spinner class="loader"> </b-spinner>
	</div>
</template>

<script>

import { default as download } from 'downloadjs';
import { mapState, mapActions, mapMutations } from "vuex";
import { contentService } from "./../../services/content.service";
import { ContentType } from "../../constants/content";
import RecordedVideo from "./Content/RecordedVideo.vue";
import StudyMaterial from "./Content/StudyMaterial";
import { LegacyExam } from "../../constants/exam";
import { getCookie } from "../../helpers/CookieHelpers";
import { ServiceCodes } from "../../constants/serviceCodes";
import Flashcards from "./Content/Flashcards.vue";
import FreeText from "./Content/FreeText.vue";
import Calculator from "./Calculator.vue"
import LiveClass from './Content/LiveClass.vue';
import Exam from './Content/Exam.vue';

import Assignment from "./Content/Assignment.vue";
import Quiz from "../../components/Content/Quiz.vue";
export default {
	name: "ProductContentView",
	data() {
		return {
			contentId: null,
			contentData: null,
			showWebDisabledModel: false,
			productVariantId:null,
			showCalculator: false,
			bookmarks:[],
			actionsLoading: { markcompleted: false, rating: false, bookmark: false }
		};
	},
	components: {
		RecordedVideo,
		StudyMaterial,
		Flashcards, 
		FreeText, 
		Calculator, 
		LiveClass, 
		Exam, Assignment,Quiz
	},
	methods: {
		...mapActions("content", ["getContentById","setUserContentAction","getUserContentAction"]),
		...mapActions("content", ["updateAction"]),
		...mapActions("productVariant", ["getProductVariantProgress"]),
		handleDownloadDocumentButtonClick(e) {
			if(this.isAllowDownloadStudyMaterial)
			{
				download(this.contentData.data?.Source);
			}
		},
		getContent() {
			if (this.contentId ) {
				this.contentData=null;
				this.$nextTick(() => {
				if(this.content[this.contentId])
				{
					// if (this.content[this.contentId].type == ContentType.LIVE_CLASS) {
					// 	this.contentData = this.content[this.contentId];
					// 	var joinURLFromCookies = getCookie(`${this.contentData.id}_${this.user?.id}`);
					// 	if (joinURLFromCookies) {
					// 		window.location.href = joinURLFromCookies;
					// 		return;
					// 	}else
					// 	{
					// 		this.$router.push(this.contentLink);
					// 	}
					// }
					// else 
					window.document.title=this.content[this.contentId].title;
					if(this.content[this.contentId].type == ContentType.STUDY_MATERIAL && this.content[this.contentId].externalService.serviceProvider.toLowerCase()!="vss" && this.content[this.contentId].data){
						this.contentData = this.content[this.contentId];
						return;
					}	
					else if(this.content[this.contentId].type == ContentType.FLASHCARDS){
						this.contentData = this.content[this.contentId];
						return;
					}	
					else if(this.content[this.contentId].type == ContentType.LIVE_CLASS){
						this.contentData = this.content[this.contentId];
						return;
					}
					else if(this.content[this.contentId].type == ContentType.EXAM){
						this.contentData = this.content[this.contentId];
						return;
					}
					else if(this.content[this.contentId].type == ContentType.Assignment){
						this.contentData = this.content[this.contentId];
						return;
					}					
					else if(this.content[this.contentId].type == ContentType.RECORDED_VIDEOS && this.content[this.contentId].externalService && this.content[this.contentId].externalService.serviceProvider.toLowerCase()!='vss'){
						this.contentData = this.content[this.contentId];
						return;
					}	
				}
				var self=this;
				this.getContentById({
						id: this.contentId,
						extraParams: { Platform: contentService.getCurrentPlatform(), action: LegacyExam.GET_EXAM_ACTION.VIEW_RESULTS, RedirectUrl: window.location.href },
					}).then((value) => {
						this.contentData = this.content[this.contentId];						
						window.document.title=this.content[this.contentId].title;
						this.processContent();
					});
				});
			}

		},
		
		processContent(){
			if (this.contentData.type == ContentType.RECORDED_VIDEOS || this.contentData.type == ContentType.STUDY_MATERIAL || this.contentData.type == ContentType.FLASHCARDS || this.contentData.type == ContentType.LIVE_CLASS || this.contentData.type == ContentType.EXAM) {
				return;
			}
			// else if (this.contentData.type == ContentType.EXAM) {
			// 	if (
			// 		this.contentData.externalService.serviceProvider ==
			// 		ServiceCodes.EXAM.V3ExamSystem
			// 	) {
			// 		if (content) {
			// 			var examAttemptUrl =
			// 				content.data.redirectUrl +
			// 				"&RedirectUrl=" +
			// 				window.location.href;
			// 			window.open(examAttemptUrl, "_blank");
			// 			// window.location.href = examAttemptUrl;
			// 			return;
			// 		}
					
			// 		return;
			// 	}
			// 	var data = res.data;
			// 		var examAttemptUrl = `http://examapp.gocbeglobal.com/exam/attempt?TestID=${
			// 			data.examID
			// 		}&Name=${data.name ?? ""}&Email=${data.email ?? ""}&UserID=${
			// 			data.userID ?? ""
			// 		}&RedirectUrl=${data.redirectUrl}&Signature=${data.signature}`;
			// 		window.open(examAttemptUrl, "_blank");
			// 		// window.location.href = examAttemptUrl;
			// 		return;				
				
			// }
			// else if (this.contentData.type == ContentType.LIVE_CLASS) {
			// 	var joinURLFromCookies = getCookie(`${this.item.id}_${this.user?.id}`);
			// 	if (joinURLFromCookies) {
			// 		window.location.href = joinURLFromCookies;
			// 		return;
			// 	}
			// 	this.$router.push(this.contentLink);
			// } 
			else this.$router.push(this.contentLink);
		},	
		setBookmark:function()
		{
			if(this.contentId)
			{ 
				var contentAction = {
					ContentID:this.contentId,
					DataType:"bookmark",
					Data:this.bookmarks[this.contentId]?(!(this.bookmarks[this.contentId]=="true"))+"":"true"
				}
				this.bookmarks[this.contentId]=this.bookmarks[this.contentId]?(!(this.bookmarks[this.contentId]=="true"))+"":"true";
				var self=this.bookmarks;
						this.bookmarks=null;
						this.bookmarks=self;
				
				this.actionsLoading.bookmark = true;
				this.setUserContentAction(contentAction).then((action)=>{
					if(action && action.dataType  && action.dataType.toLowerCase()=="bookmark")
					{
						this.bookmarks[action.contentID] = action.data;
						var self=this.bookmarks;
						this.bookmarks=null;
						this.bookmarks=self;
						this.updateAction(action);
						
						this.actionsLoading.bookmark = false;
					}
				});
			}
		},	
		getBookmark:function(){
			if(!this.bookmarks[this.contentId])
			{
				this.getUserContentAction({contentId:this.contentId, dataType:"bookmark"}).then((userActions)=>{
					var userAction = userActions?userActions.find(x=>x.dataType=="bookmark"):null;
					if(userAction)
					{
						this.bookmarks[userAction.contentID]=userAction.data;
						var self=this.bookmarks;
						this.bookmarks=null;
						this.bookmarks=self;
					}
				});
			}
		},
		toggleCalculator(state) {
			if (state !== undefined) {
				this.showCalculator = state;
			} else {
				this.showCalculator = !this.showCalculator;
			}
		},

		markCompleted: function (isCompleted) {
      if (this.contentData.id) {
        var contentAction = {
          ContentID: this.contentData.id,
          DataType: "completionStatus",
          Data: isCompleted ? "completed" : "uncompleted"
        }

		this.actionsLoading.markcompleted = true;

        this.setUserContentAction(contentAction).then((action) => {
          if (action && action.dataType && action.dataType.toLowerCase() == "completionstatus") {
            this.getProductVariantProgress(this.$route.params.id);
            this.updateAction({ "id": action.id, "userID": action.userID, "contentID": this.contentData.id, "dataType": action.dataType, "data": action.data });
			this.actionsLoading.markcompleted = false;
		}

        });
      }
    },

	setConfidenceLevel: function (confidenceLevel) {
			if (this.contentData.id) {
				if (this.currentRating.toLowerCase() == confidenceLevel.toLowerCase()) {
					confidenceLevel = 'Unrated';
				}
				
				this.$nextTick(() => {
						this.$refs.ratingDropdown.hide();
					});

				var contentAction = {
					ContentID: this.contentData.id,
					DataType: "confidence_level",
					Data: confidenceLevel
				}

				this.actionsLoading.rating = true;

				this.setUserContentAction(contentAction).then((action) => {
					if (action && action.dataType && action.dataType.toLowerCase() == "confidence_level") {
				
					
						this.updateAction(action);
						this.actionsLoading.rating = false;
					}
				});
			}
		},
	},
	computed: {
		...mapState("content", ["content","loading","contentActions"]),		
		...mapState("user", ["loggedIn", "user"]),	
		...mapState("appState", ["isMobile", "systemParameters"]),
		isDesktopApp() {
			return window.navigator.userAgent.toLowerCase().indexOf("electron") > -1;
		},
		isWebDisabled() {
			return this.systemParameters && this.systemParameters.IsWebAccessDisabled == '1';
		},		
		isAllowDownloadStudyMaterial(){
			return  (this.contentData && this.contentData.type==ContentType.STUDY_MATERIAL
			&& this.contentData.externalService && this.contentData.externalService.serviceProvider.toLowerCase()=='local' && 
			this.contentData.data && this.contentData.data?.AllowDownload?.toString().toLowerCase() == "true") ? true : false
		},
		isCompleted() {
    if (!this.contentData || !this.contentData.id || !this.contentActions || !this.contentActions[this.contentData.id]) {
        return false;
    }
    
    const contentAction = this.contentActions[this.contentData.id].find(x => x.dataType === 'completionStatus');
    
    if (!contentAction || !contentAction.data) {
        return false;
    }
    
    return contentAction.data === 'completed';
},
		contentLink() {
			switch (this.contentData.type) {
				// case ContentType.LIVE_CLASS:
				// 	return {
				// 		path:
				// 			"/content/liveclass/" +
				// 			this.contentData.title
				// 				.toLowerCase()
				// 				.replace(/[^a-zA-Z0-9 ]/g, "")
				// 				.split(" ")
				// 				.join("-") +
				// 			"-" +
				// 			this.contentData.id +
				// 			"_" +
				// 			this.productVariantId,
				// 	};
				// case ContentType.ASSIGNMENT:
				// 	return {
				// 		path:
				// 			"/content/assignment/" +
				// 			this.contentData.title
				// 				.toLowerCase()
				// 				.replace(/[^a-zA-Z0-9 ]/g, "")
				// 				.split(" ")
				// 				.join("-") +
				// 			"-" +
				// 			this.contentData.id +
				// 			"_" +
				// 			this.productVariantId,
				// 	};
			}
		},

		
		shouldShowMarkCompleteButton() {
			const content = this.contentData;
			return content &&
				content.type.toLowerCase() != 'recordedvideos' &&
				(!content.externalService || content.externalService.serviceProvider.toLowerCase() != 'vss') &&
				content.type.toLowerCase() != 'assignment' &&
				content.type.toLowerCase() != 'liveclass' &&
				content.type.toLowerCase() != 'flashcards' &&
				content.type.toLowerCase() != 'quiz' &&
				content.type.toLowerCase() != 'exam';
		},

		isTableOfContentVisible() {
			return this.$route.query.tableOfContent === 'true';
		},

		currentRating() {
    if (!this.contentData || !this.contentData.id || !this.contentActions || !this.contentActions[this.contentData.id]) {
        return 'unrated';
    }
    
    console.log("ContentAction: 1", this.contentActions);
    const contentAction = this.contentActions[this.contentData.id].find(x => x.dataType.toLowerCase() === 'confidence_level');
    
    if (!contentAction || !contentAction.data) {
        return 'unrated';
    }

    console.log("ContentAction: 2", contentAction);
    
    return contentAction.data.toLowerCase();
},

starClass() {
            return this.currentRating === 'unrated' ? 'fa fa-star-o' : 'fa fa-star';
        },
        starStyle() {
            switch (this.currentRating) {
                case 'high':
                    return 'color: green;';
                case 'medium':
                    return 'color: yellow;';
                case 'low':
                    return 'color: red;';
                default:
                    return '';
            }
        }

	},

	watch: {
		$route:function(to,from){
			
			if (this.$route.query.contentId && this.$route.query.contentId!=this.contentId) {

				if(this.contentData && this.contentData.type==ContentType.RECORDED_VIDEOS)
				{	var oldCID=this.contentData.id;
					var self=this;
					this.getUserContentAction({contentId:oldCID}).then((userActions)=>{
					
					});
				}

				this.contentId = this.$route.query.contentId;
				this.getContent();
			}
			
			if (this.$route.query.tab && this.$route.query.tab!=this.currentTab) {
				var oldTab=this.currentTab??"";
				var newTab=this.$route.query.tab;
				if(oldTab.toLowerCase()=="bookmarks" && newTab.toLowerCase()=="tableofcontents")
				{
					this.bookmarks[this.contentId]=null;
					this.getBookmark();
				}
				this.currentTab=newTab;
			}
		},
		contentId:function(){
			this.getBookmark();
		
		},
		contentActions:function(){
		}
	},
	created() {
		if (!this.isDesktopApp && this.isWebDisabled) {
			this.showWebDisabledModel = true;
			return;
		}
		if (this.$route.query.contentId) {
			this.contentId = this.$route.query.contentId;
			this.getContent();
		}
		this.productVariantId=this.$route.params.id;
	},
	mounted() {
	}
};
</script>

<style scoped>
.chapter-content{
	padding: 20px;
	height: inherit; 
	overflow: auto;
  }
  .chapter-content.has-floating-menu{
	position: relative;
	padding-right: 0px !important;
  }
  .chapter-content.freeTextContent 
  {
	/* padding-right: 0px; */
  }
  .chapter-content.Flashcards,
  .chapter-content.RecordedVideos,
  .chapter-content.Quiz,
  .chapter-content.StudyMaterial{
	padding:0;
  }


  .chapter-content.freeTextContent .chapter-content-left
  {
	/* padding-right: 0px; */
  }
  .chapter-content.Quiz .chapter-content-left
  {
	padding: 0px;
	height: 100%;
  }
  .chapter-content.Quiz .chapter-content-left div
  {
	height: 100%;
  }
  
  .StudyMaterial .chapter-content-left{
	height: 100%;
  }

.loading-div {
	width: 100%;
	height: 50vh;
}

.loading-div .loader {
	top: 45%;
	left: 48%;
	position: absolute;
}

.login-webDisabled {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: auto;
	padding: 0 7% 0;
}

.download-btn-list {
	text-align: center;
	margin: 0;
	padding: 0;
	display: inline;
}

.download-btn-list-item {
	display: inline;
}

.download-btn-list-item a {
	margin: 10px;
}

.download {
	width: 200px;
	height: 70px;
	background: black;
	float: left;
	border-radius: 5px;
	position: relative;
	color: #fff;
	cursor: pointer;
	border: 1px solid #fff;
}

.download>.fa {
	color: #fff;
	position: absolute;
	top: 50%;
	left: 15px;
	transform: translateY(-50%);
}

.df,
.dfn {
	position: absolute;
	left: 60px;
	width: 50%;
}

.df {
	top: 16px;
	font-size: 12px;
}

.dfn {
	top: 28px;
	font-size: 18px;
}

.download:hover {
	-webkit-filter: invert(100%);
	filter: invert(100%);
}

.ebook-digital .floating-menu{
	right: 20px;
	top: 1rem !important;
}

@media (max-width: 768px){
	.chapter-content{
		padding: 15px;
	}
	.chapter-content.Assignment{
		padding: 0;
	}

	.ebook-digital .floating-icons {
		position: relative;
		z-index: 1;
	}
}

.confidence-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 10px 0;
    padding: 5px;
}
.confidence-btn i {
    margin-right: 20px;
}

.form-check-input {
    display: none;
}
</style>
<style>
.chapter-content.Quiz .chapter-content-left .MainQuiz-Box
  {
	height: 100% !important; 
	display: flex;
	flex-direction: column;
	overflow: hidden;
  }
  .chapter-content.Quiz .chapter-content-left .MainQuiz-Box .center-section
  {
	flex: 1;
  }
  
  .chapter-content.Quiz .chapter-content-left .div-box
  {
	height: 100%;
  }

  .fa-circle-thin {
    color: gray;
  }

  .fa-check-circle {
      color: green;
  }

#mark-completed,
#rating {
    display: none;
}


  .ebook-digital #mark-completed, .ebook-digital #rating {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

  @media (max-width:768px) {
 
   #rating {
	 display: flex;
   }
 }
  
  </style>