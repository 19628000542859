import Vue from "vue";
import router from "../router";
import store from "../store/index";
import helpers from "../helpers/CookieHelpers";
import { userService } from "../services";

import User from "../models/user";
import ProductVariant from "../models/productVariant";
import ErrorMessage from "../constants/errorMessages";
import { minToHoursAndMins } from "../helpers/FormatHelpers";
const user = JSON.parse(localStorage.getItem("user"));
const isToken = helpers.checkCookie("token");

if (!isToken) {
  localStorage.setItem("user", null);
}

const state =
  isToken && user
    ? {
        loggedIn: true,
        user,
        trainers: null,
        allUsers: null,
        isNextRecordAvailable: true,
        usersByProductVariant: {},
        allStudents: null,
        loading: false,
        userDevices: {},
        userDeviceRestrictions: {},
        emailUsers: null,
      }
    : {
        loggedIn: false,
        user: null,
        trainers: null,
        allUsers: null,
        usersByProductVariant: {},
        allStudents: null,
        loading: false,
        userDevices: {},
        userDeviceRestrictions: {},
        emailUsers: null,
        userCredentials: {
          username: null,
          password: null,
        },
      };

const actions = {
  async loginWithToken({ commit }, token) {
   debugger;
    // try {
    commit("setLoading", true);
    const response = await userService
      .loginWithUserToken(token)
      .then((response) => {
        console.log(response);
        if (response) {
          commit("setLoading", false);
          commit("loginSuccess", response.payload.user);
          store.dispatch("appState/stopLoading");
          if (response.payload.user.role.toLowerCase() == "student") {
            actions.fetchCurrentUser({ commit }).then(() => {
              // console.log(store.state.cart.cart.items);
              var cartItemsToRemove = store.state.cart.cart.items.filter(
                (item) => {
                  var variantIsEnrolled = state.user.productVariants.find(
                    (variant) => variant.id == item.id
                  );
                  if (variantIsEnrolled) return item;
                }
              );

              console.log(cartItemsToRemove);
              cartItemsToRemove.forEach((item) => {
                store.dispatch("cart/removeFromCart", item);
              });
            });
          }
          return response.payload.user;
        }
      })
      .catch((error
        
      ) => {
        handleError(error);
        // console.log(error);
        commit("loginFailure");
        store.dispatch("appState/stopLoading");
        return Promise.reject(error);
      });
    return response;
    // }
    // catch (error) {
    // 	handleError(error);
    // 	// console.log(error);
    // 	commit("loginFailure");
    // 	store.dispatch("appState/stopLoading");
    // 	// console.log(error);
    // 	return error;
    // }
  },
  async login({ commit }, { username, password }) {
    // try {
    commit("setLoading", true);
    const response = await userService
      .login(username, password)
      .then((response) => {
        console.log(response);
        if (response) {
          commit("setLoading", false);
          commit("loginSuccess", response.payload.user);
          store.dispatch("appState/stopLoading");
          if (response.payload.user.role.toLowerCase() == "student") {
            actions.fetchCurrentUser({ commit }).then(() => {
              // console.log(store.state.cart.cart.items);
              var cartItemsToRemove = store.state.cart.cart.items.filter(
                (item) => {
                  var variantIsEnrolled = state.user.productVariants.find(
                    (variant) => variant.id == item.id
                  );
                  if (variantIsEnrolled) return item;
                }
              );

              console.log(cartItemsToRemove);
              cartItemsToRemove.forEach((item) => {
                store.dispatch("cart/removeFromCart", item);
              });
            });
          }
          return response.payload.user;
        }
      })
      .catch((error) => {
        handleError(error);
        // console.log(error);
        commit("loginFailure");
        store.dispatch("appState/stopLoading");
        return Promise.reject(error);
      });
    return response;
    // }
    // catch (error) {
    // 	handleError(error);
    // 	// console.log(error);
    // 	commit("loginFailure");
    // 	store.dispatch("appState/stopLoading");
    // 	// console.log(error);
    // 	return error;
    // }
  },
  logout({ commit }) {
    userService.logout();
    commit("logout");
    console.log("logging out");
    store.commit("productVariant/clearProductVariants");
    store.commit("content/clearContent");

    router.push("/account/login").catch(() => {});
  },
  async register({ commit }, user) {
    console.log("store register function called");
    const response = await userService.register(user).then(
      (res) => {
        // console.log(res);
        store.dispatch("appState/stopLoading");
        if (res) {
          const data = res.payload;
          return data;
        }
      },
      (error) => {
        console.error(error);
        // handleError(error);
        store.dispatch("appState/stopLoading");
        return Promise.reject(error);
      }
    );
    return response;
  },
  async generateOTP({ commit }, userData) {
    commit("setLoading", true);
    console.log("store generateOTP function called");
    const response = await userService.generateOTP(userData).then(
      (res) => {
        // console.log(res);
        store.dispatch("appState/stopLoading");
        if (res) {
          commit("setLoading", false);
          const data = res.payload;
          return data;
        }
      },
      (error) => {
        commit("setLoading", false);
        store.dispatch("appState/stopLoading");
        return Promise.reject(error);
      }
    );
    return response;
  },
  async verifyOTP({ commit }, otpData) {
    commit("setLoading", true);
    console.log("store verifyOTP function called");
    const response = await userService.verifyOTP(otpData).then(
      (res) => {
        // console.log(res);
        store.dispatch("appState/stopLoading");
        if (res) {
          commit("setLoading", false);
          const data = res.payload;
          return data;
        }
      },
      (error) => {
        commit("setLoading", false);
        console.error(error);
        // handleError(error);
        store.dispatch("appState/stopLoading");
        return Promise.reject(error);
      }
    );
    return response;
  },
  async resetPassword({ commit }, resetObject) {
    commit("setLoading", true);
    console.log("store resetPassword function called");
    const response = await userService.resetPassword(resetObject).then(
      (res) => {
        // console.log(res);
        commit("setLoading", false);
        store.dispatch("appState/stopLoading");
        if (res) {
          const data = res.payload;
          return data;
        }
      },
      (error) => {
        console.error(error);
        // handleError(error);
        store.dispatch("appState/stopLoading");
        return Promise.reject(error);
      }
    );
    return response;
  },
  async fetchCurrentUser({ commit }) {
    console.log("current user function called");
    await userService.getCurrentUser().then((res) => {
      var user = res.payload;
      // console.log(user);
      if (user.productVariants && user.productVariants.length > 0) {
        console.log(user.productVariants);
        user.productVariants = user.productVariants.map((v) => {
          v.properties = {
            ...v.properties,
            pricing: { currency: "PKR", amount: 0 },
            ratings: { value: v.rating, numberOfRatings: 0 },
            ...(v.productVariantExtraProperties?JSON.parse(v.productVariantExtraProperties):null)
          };
          if (v.product) {
            v.product.productThumbnailPath =
              "assets/images/sample-thumbnail.jpeg";
            v.product.productFriendlyTitle = v.product.productTitle
              ? v.product.productTitle
                  .toLowerCase()
                  .replace(" - ", "-")
                  .replace(/\s/g, "-")
              : null;
          }
          return new ProductVariant(v);
        });
      }
      store.dispatch("appState/stopLoading");
      commit("userDataFetched", user);
      // localStorage.setItem("user", JSON.stringify(new User(user)));
    }),
      (error) => {
        handleError(error);
      };
  },
  async updateUserProfile({ commit }, userData) {
    commit("setLoading", true);
    await userService.editUserProfile(userData).then((user) => {
      console.log(user);
      commit("setLoading", false);
      store.dispatch("appState/stopLoading");
      // commented due to malfunction on backend
      // -------------------------------------------------
      // commit("updateUserProperty", userData);
      // commit("updateUser", user.payload);
      // localStorage.setItem("user", JSON.stringify(user.payload));
      // -------------------------------------------------
    });
  },
  async changeUserPassword({ commit }, userData) {
    commit("setLoading", true);
    await userService.editUserPassword(userData).then((user) => {
      commit("setLoading", false);
      console.log(user);
      store.dispatch("appState/stopLoading");
    });
  },
  async fetchTrainersList({ commit }) {
    await userService.fetchTrainersList().then(
      (response) => {
        var data = response.payload;
        commit("setTrainers", data);
        store.dispatch("appState/stopLoading");
      },
      (error) => {
        handleError(error);
        // console.log(error);
      }
    );
  },
  async fetchUsersList({ commit }, type) {
    commit("setLoading", true);

    await userService.fetchUsersList(type).then(
      (response) => {
        var data = response.payload;
        if (type) {
          type.toLowerCase() == "student"
            ? commit("setStudents", data)
            : type.toLowerCase() == "trainer"
            ? commit("setTrainers", data)
            : commit("setUsers", data);
        } else {
          commit("setUsers", data);
        }
        commit("setLoading", false);
        store.dispatch("appState/stopLoading");
      },
      (error) => {
        // console.log(error);
        handleError(error);
        commit("setLoading", false);
      }
    );
  },
  async exportUsersList({ commit }, { type, searchString, status }) {
    var result = undefined;
    await userService.exportUsersList({ type, searchString, status }).then(
      (response) => {
        result = response;
      },
      (error) => {
        // console.log(error);
        handleError(error);
      }
    );
    const blob = await result.blob();
    const newBlob = new Blob([blob]);
    const blobUrl = window.URL.createObjectURL(newBlob);
    return blobUrl;
  },
  async exportUsersEnrollmentReport({ commit }, productVariantId = undefined) {
    var result = undefined;
    await userService.exportUsersEnrollmentReport(productVariantId).then(
      (response) => {
        result = response;
      },
      (error) => {
        // console.log(error);
        handleError(error);
      }
    );
    const contentDisposition = result.headers.get("content-disposition");
    const filename = contentDisposition.split("filename=")[1].split(";")[0];
    console.log(filename);

    const blob = await result.blob();

    const newBlob = new Blob([blob]);
    const blobUrl = window.URL.createObjectURL(newBlob);
    return { name: filename, url: blobUrl };
  },
  async fetchPaginatedUsersList(
    { commit },
    { type, pageSize, nextPageToken, searchString, status }
  ) {
    commit("setLoading", true);

    var result = undefined;
    await userService
      .fetchPaginatedUsersList({
        type,
        pageSize,
        nextPageToken,
        searchString,
        status,
      })
      .then(
        (response) => {
          result = response;
          var data = response.payload;
          if (type) {
            type.toLowerCase() == "student"
              ? nextPageToken
                ? commit("appendStudents", data)
                : commit("setStudents", data)
              : type.toLowerCase() == "trainer"
              ? nextPageToken
                ? commit("appendTrainers", data)
                : commit("setTrainers", data)
              : nextPageToken
              ? commit("appendUsers", data)
              : commit("setUsers", data);
          }
          if (nextPageToken) {
            commit("appendUsers", data);
          } else {
            commit("setUsers", data);
          }

          if (data == null || data.length <= 0) {
            commit("nextRecordNotAvailable", data);
          } else {
            commit("nextRecordAvailable", data);
          }

          commit("setLoading", false);
          store.dispatch("appState/stopLoading");
        },
        (error) => {
          // console.log(error);
          handleError(error);
          commit("setLoading", false);
        }
      );

    return result;
  },
  async fetchUserById({ commit }, id) {
    commit("setLoading", true);
    await userService.fetchUserById(id).then(
      (response) => {
        var data = response.payload;
        commit("addUser", data);

        commit("setLoading", false);
        store.dispatch("appState/stopLoading");
      },
      (error) => {
        // console.log(error);
        handleError(error);
        commit("setLoading", false);
      }
    );
  },
  async fetchUsersByProductVariant({ commit }, id) {
    commit("setLoading", true);
    await userService.fetchUsersByProductVariant(id).then(
      (response) => {
        var data = response.payload;
        commit("setUsersByProductVariant", { users: data, id });
        store.dispatch("appState/stopLoading");
        commit("setLoading", false);
      },
      (error) => {
        // console.log(error);
        handleError(error);
      }
    );
  },
  async createUser({ commit }, user) {
    commit("setLoading", true);
    const res = await userService.createUser(user).then(
      (response) => {
        var data = response.payload;
        commit("addUser", data);
        store.dispatch("appState/stopLoading");
        commit("setLoading", false);
        return data;
      },
      (error) => {
        // console.log(error);
        store.dispatch("appState/stopLoading");
        commit("setLoading", false);
		let _error = error.message;
        _error.user = error.user;
        return handleError(_error);
      }
    );
    return res;
  },
  async editUser({ commit }, user) {
    commit("setLoading", true);
    await userService.editUser(user).then(
      (response) => {
        var data = response.payload;
        commit("updateUser", data);
        store.dispatch("appState/stopLoading");
        commit("setLoading", false);
      },
      (error) => {
        // console.log(error);
        handleError(error);
        commit("setLoading", false);
      }
    );
  },
  async updateUserStatus({ commit }, user) {
    commit("setLoading", true);
    await userService.updateUserStatus(user).then(
      (response) => {
        // var data = response.payload;
        user.user.status = user.status;
        commit("updateUser", user.user);
        store.dispatch("appState/stopLoading");
        commit("setLoading", false);
      },
      (error) => {
        // console.log(error);
        handleError(error);
        commit("setLoading", false);
      }
    );
  },
  async deleteUser({ commit }, user) {
    commit("setLoading", true);
    await userService.deleteUser(user.id).then(
      (response) => {
        // var data = response.payload;
        commit("deleteUser", user);
        store.dispatch("appState/stopLoading");
        commit("setLoading", false);
      },
      (error) => {
        // console.log(error);
        handleError(error);
        commit("setLoading", false);
      }
    );
  },
  async enrollUserInProductVariant({ commit }, data) {
    commit("setLoading", true);
    await userService.enrollUserInProductVariant(data.requestObj).then(
      () => {
        // var data = response.payload;
        data.user.enrollmentStatus = "Allowed";
        commit("setUsersByProductVariant", {
          users: [data.user],
          id: data.requestObj.productVariantID,
        });
        store.dispatch("appState/stopLoading");
        commit("setLoading", false);
      },
      (error) => {
        handleError(error);
        // console.log(error);
        commit("setLoading", false);
      }
    );
  },
  async editUserErollmentInProductVariant({ commit }, data) {
    commit("setLoading", true);
    await userService.editUserErollmentInProductVariant(data).then(
      () => {
        if (data.status.toLowerCase() == "unenrolled") {
          commit("removeUsersByProductVariant", {
            user: data.userID,
            id: data.productVariantID,
          });
          actions.fetchUsersList({ commit });
        } else commit("updateUsersByProductVariant", data);
        store.dispatch("appState/stopLoading");
        commit("setLoading", false);
      },
      (error) => {
        handleError(error);
        // console.log(error);
        commit("setLoading", false);
      }
    );
  },
  async editUserSubscription({ commit }, data) {
    commit("setLoading", true);
    var res = await userService.editUserSubscription(data).then(
      () => {
        // commit("updateUsersByProductVariant", data);
        store.dispatch("appState/stopLoading");
        commit("setLoading", false);
      },
      (error) => {
        // console.log(error);
        commit("setLoading", false);
        return handleError(error);
      }
    );
    console.log(res, "r");
    return res;
  },
  async fetchUserDevices({ commit }, user) {
    commit("setLoading", true);
    const data = await userService.fetchUserDevices(user.username).then(
      (res) => {
        var data = res.payload;
        if (user.id) commit("setUserDevices", { data, id: user.id });
        store.dispatch("appState/stopLoading");
        commit("setLoading", false);
        return data;
      },
      (error) => {
        handleError(error);
        commit("setLoading", false);
      }
    );
    return data;
  },
  async resetUserDevices({ commit }, user) {
    commit("setLoading", true);
    const data = await userService.resetUserDevices(user.id).then(
      () => {
        commit("setUserDevices", { data: [], id: user.id });
        store.dispatch("appState/stopLoading");
        commit("setLoading", false);
        return true;
      },
      (error) => {
        handleError(error);
        commit("setLoading", false);
        return false;
      }
    );
    return data;
  },
  async resetUserDeviceById({ commit }, resetData) {
    commit("setLoading", true);
    const data = await userService
      .resetUserDeviceById({
        deviceID: resetData.deviceID,
        username: resetData.username,
      })
      .then(
        () => {
          if (resetData.user)
            commit("removeUserDeviceById", {
              deviceID: resetData.deviceID,
              userID: resetData.user.id,
            });
          store.dispatch("appState/stopLoading");
          commit("setLoading", false);
          return true;
        },
        (error) => {
          commit("setLoading", false);
          handleError(error);
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        commit("setLoading", false);
        handleError(error);
        return Promise.reject(error);
      });
    return data;
  },
  async fetchUserDeviceRestrictions({ commit }, user) {
    commit("setLoading", true);
    const data = await userService.fetchUserDeviceRestrictions(user.id).then(
      (res) => {
        var data = res.payload;
        commit("setDeviceRestrictions", { data: data, id: user.id });
        store.dispatch("appState/stopLoading");
        commit("setLoading", false);
        return data;
      },
      (error) => {
        handleError(error);
        commit("setLoading", false);
        return false;
      }
    );
    return data;
  },
  async setUserDeviceRestrictions({ commit }, restrictions) {
    commit("setLoading", true);
    const data = await userService.setUserDeviceRestrictions(restrictions).then(
      () => {
        // commit("setUserDevices", { data: [], id: user.id });
        store.dispatch("appState/stopLoading");
        commit("setLoading", false);
        return true;
      },
      (error) => {
        handleError(error);
        commit("setLoading", false);
        return false;
      }
    );
    return data;
  },
  async allUsersDevicesReset({ commit }, emails) {
    const data = await userService.allUsersDevicesReset(emails).then(
      (res) => {
        store.dispatch("appState/stopLoading");
        return { ok: true };
      },
      (error) => {
        //handleError(error);
        return error;
      }
    );
    return data;
  },

  async markUsersStatus({ commit }, requestData) {
    const data = await userService.markUsersStatus(requestData).then(
      (res) => {
        store.dispatch("appState/stopLoading");
        return { ok: true };
      },
      (error) => {
        //handleError(error);
        return error;
      }
    );
    return data;
  },
  async getUsersByEmails({ commit }, emails) {
    commit("setLoading", true);
    const _data = await userService.getUsersByEmails(emails).then(
      (response) => {
        var data = response.payload;
        commit("setUsersByEmails", { users: data });
        store.dispatch("appState/stopLoading");
        commit("setLoading", false);
        return data;
      },
      (error) => {
        let _error = { message: "No Users found" };
        // handleError(_error);
      }
    );
    return _data;
  },

  async generateVerificationID({ commit }, data) {
    commit("setLoading", true);
    const _data = await userService.generateVerificationID(data).then(
      (response) => {
        var data = response.payload;
        store.dispatch("appState/stopLoading");
        commit("setLoading", false);
        return data;
      },
      (error) => {
        return error;
        // handleError(_error);
      }
    );
    return _data;
  },

  async verifyUser({ commit }, data) {
    commit("setLoading", true);
    const _data = await userService.verifyUser(data).then(
      (response) => {
        var data = response;
        store.dispatch("appState/stopLoading");
        commit("setLoading", false);
        return data;
      },
      (error) => {
        commit("setLoading", false);

        return error;
        // handleError(_error);
      }
    );
    return _data;
  },
};

const mutations = {
  // updateUserProperty(updated) {
  // 	state.user = { ...state.user, ...updated };
  // },
  loginSuccess(state, user) {
    state.loggedIn = true;
    state.user = new User(user);
    localStorage.setItem("user", JSON.stringify(new User(user)));
    console.log("login successful");
  },
  loginFailure(state) {
    state.loggedIn = false;
    state.user = null;
    state.loading = false;
    console.log("login unsuccessful");
  },
  logout(state) {
    state.loggedIn = false;
    state.user = null;
  },
  userDataFetched(state, data) {
    console.log("userDataFetched", data);
    // state.loggedIn = true;
    console.log(new User(data));
    state.user = new User(data);
    localStorage.setItem("user", JSON.stringify(new User(data)));
  },
  setTrainers(state, data) {
    state.trainers = data;
  },
  appendTrainers(state, data) {
    state.trainers.concat(data);
  },
  setUsers(state, data) {
    state.allUsers = data;
  },
  appendUsers(state, data) {
    state.allUsers = state.allUsers.concat(data);
  },
  nextRecordNotAvailable(state, data) {
    state.isNextRecordAvailable = false;
  },
  nextRecordAvailable(state, data) {
    state.isNextRecordAvailable = true;
  },
  appendStudents(state, data) {
    state.allStudents.concat(data);
  },
  addUser(state, user) {
    console.log("addUser", user);
    if (user.type.toLowerCase() == "trainer") {
      mutations.addTrainer(user);
    }
    if (user.type.toLowerCase() == "student") {
      mutations.addStudent(user);
    }
    if (state.allUsers && state.allUsers.length > 0) {
      var found = state.allUsers.find((u) => u.id == user.id);
      if (!found) {
        state.allUsers = [...state.allUsers, user];
      } else {
        state.allUsers = [
          ...state.allUsers.map((u) => {
            if (u.id == user.id) {
              return user;
            } else return u;
          }),
        ];
      }
    } else {
      state.allUsers = [user];
    }
  },
  addTrainer(user) {
    if (state.trainers && state.trainers.length > 0) {
      var found = state.trainers.find((trainer) => trainer.id == user.id);
      if (!found) {
        state.trainers = [...state.trainers, user];
      } else {
        state.trainers = [
          ...state.trainers.map((trainer) => {
            if (trainer.id == user.id) {
              return user;
            } else return trainer;
          }),
        ];
      }
      console.log("addUserTrainer", user);
      // state.trainers = [...state.trainers, user];
    } else state.trainers = [user];
  },
  addStudent(user) {
    if (state.allStudents) {
      var found = state.allStudents.find((student) => student.id == user.id);
      if (!found) {
        state.allStudents = [...state.allStudents, user];
      } else {
        state.allStudents = [
          ...state.allStudents.map((student) => {
            if (student.id == user.id) {
              return user;
            } else return student;
          }),
        ];
      }
    } else state.allStudents = [user];
  },
  updateUser(state, user) {
    console.log("setting state user", user);
    state.allUsers = state.allUsers.map((u) => {
      if (u.id === user.id) {
        return user;
      }
      return u;
    });
    if (state.allStudents) {
      state.allStudents = state.allStudents.map((u) => {
        if (u.id === user.id) {
          return user;
        }
        return u;
      });
    }
    if (state.trainers) {
      state.trainers = state.trainers.map((u) => {
        if (u.id === user.id) {
          return user;
        }
        return u;
      });
    }
  },
  deleteUser(state, user) {
    console.log("setting state user", user);

    state.allUsers = state.allUsers.filter((u) => u.id != user.id);
    if (state.allStudents) {
      state.allStudents = state.allStudents.map((u) => u.id != user.id);
    }
  },
  setUsersByProductVariant(state, data) {
    if (state.usersByProductVariant[data.id]) {
      var unchanged = state.usersByProductVariant[data.id].filter((user) => {
        var found = data.users.find((u) => u.id == user.id);
        if (!found) {
          return user;
        }
      });
      console.log(unchanged);
      state.usersByProductVariant = {
        ...state.usersByProductVariant,
        [data.id]: [...unchanged, ...data.users],
      };
    } else
      state.usersByProductVariant = {
        ...state.usersByProductVariant,
        [data.id]: data.users,
      };
  },
  updateUsersByProductVariant(state, data) {
    if (state.usersByProductVariant[data.productVariantID])
      state.usersByProductVariant = {
        ...state.usersByProductVariant,
        [data.productVariantID]: state.usersByProductVariant[
          data.productVariantID
        ].map((u) => {
          if (u.id == data.userID) u.enrollmentStatus = data.status;
          return u;
        }),
      };
    else
      state.usersByProductVariant = {
        ...state.usersByProductVariant,
        [data.id]: data.users,
      };
  },
  removeUsersByProductVariant(state, data) {
    if (state.usersByProductVariant[data.id]) {
      var updateUsers = state.usersByProductVariant[data.id].filter(
        (user) => user.id != data.user
      );
      state.usersByProductVariant = {
        ...state.usersByProductVariant,
        [data.id]: updateUsers,
      };
    }
  },
  setUserDevices(state, deviceData) {
    console.log("setUserDevices", deviceData);
    if (deviceData.data)
      state.userDevices = {
        ...state.userDevices,
        [deviceData.id]: deviceData.data,
      };
    state.loading = false;
  },
  removeUserDeviceById(state, deviceData) {
    console.log("resetUserDeviceById", deviceData);
    // if (deviceData.data)
    state.userDevices = {
      ...state.userDevices,
      [deviceData.userID]: state.userDevices[deviceData.userID].filter(
        (device) => device.id != deviceData.deviceID
      ),
    };
    state.loading = false;
  },
  setDeviceRestrictions(state, restrictionData) {
    console.log("setDeviceRestrictions", restrictionData);
    if (restrictionData.data)
      state.userDeviceRestrictions = {
        ...state.userDeviceRestrictions,
        [restrictionData.id]: restrictionData.data,
      };
    state.loading = false;
  },
  setUsersByEmails(state, users) {
    state.emailUsers = users;
  },
  setLoading(state, data) {
    if (data) {
      console.log("users loading...");
    } else {
      console.log("users loading complete!");
    }
    state.loading = data;
  },
};

function handleError(error) {
  console.log({ error });

  if (error.errorCode == "149") {
    Vue.toasted.success("Email Verification Required", {
      theme: "outline",
      position: "bottom-center",
      duration: 5000,
    });
    return;
  } else if (error.errorCode == "150") {
    Vue.toasted.success("Phone Verification Required", {
      theme: "outline",
      position: "bottom-center",
      duration: 5000,
    });
    return;
  }

  Vue.toasted.error(error.message, {
    icon: "exclamation-triangle",
    theme: "outline",
    position: "bottom-center",
    duration: 3000,
  });
  return Promise.reject(error);
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
