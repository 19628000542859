import store from "../store/index";
import helpers from "../helpers/CookieHelpers";
import {
  FETCH_POST_PUT_PRODUCT_VARIANTS_API,
  FETCH_PRODUCT_VARIANTS_BY_CATEGORY_API,
  FETCH_PRODUCT_VARIANTS_BY_PRODUCT_API,
  ASSIGN_CONTENT_TO_PRODUCT_VARIANTS_API,
  PATCH_PRODUCT_VARIANT_SEQUENCE,
  PRODUCT_VARIANT_FEEDBACK_RATING,
  UNENROLL_USER_COURSE_SUBSCRIPTION,
  ENROLL_USERS_COURSES_SUBSCRIPTION,
  UNENROLL_USERS_COURSES_SUBSCRIPTION,
  PASTE_PRODUCT_VARIANT_ITEMS,
  PASTE_PRODUCT_VARIANTS,
  FETCH_PRODUCT_VARIANT_PROGRESS,
  PUT_RATING_API
} from "../constants/urls";
import ApiHelpers from "../helpers/ApiHelpers";

export const productVariantService = {
  fetchAllProductVariants,
  fetchProductVariantById,
  fetchProductVariantByCategoryId,
  fetchProductVariantByProductId,
  createProductVariant,
  assignContentToProductVariant,
  PasteProductVariantItems,
  updateProductVariant,
  deleteProductVariant,
  fetchProductVariantRating,
  editProductVariantSequence,
  createProductVariantFeedback,
  unenrollAllUsersCourses,
  enrollUsersSpecificCourses,
  updateUsersSpecificCoursesSubscription,
  PasteProductVariants,
  fetchProductVariantProgress,
  deleteStudentFeedback
};

async function fetchAllProductVariants(filters) {
  var queryString = "";
  if (filters && Object.entries(filters).length) {
    Object.entries(filters).forEach(([key, value]) => {
      if (queryString.length) {
        queryString += `&${key}=${value}`;
        return;
      }
      queryString += `?${key}=${value}`;
    });
  }
  store.dispatch("appState/startLoading");
  try {
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL +
        FETCH_POST_PUT_PRODUCT_VARIANTS_API +
        queryString,
      "GET",
      null,
      false
    ).then(handleResponse);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}
async function fetchProductVariantById(id) {
  store.dispatch("appState/startLoading");
  try {
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL +
        FETCH_POST_PUT_PRODUCT_VARIANTS_API +
        `/${id}`,
      "GET",
      null,
      false
    ).then(handleResponse);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}
async function fetchProductVariantByCategoryId(id) {
  store.dispatch("appState/startLoading");
  try {
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL +
        FETCH_PRODUCT_VARIANTS_BY_CATEGORY_API +
        `${id}`,
      "GET",
      null,
      false
    ).then(handleResponse);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}
async function fetchProductVariantByProductId(id) {
  store.dispatch("appState/startLoading");
  try {
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL +
        FETCH_PRODUCT_VARIANTS_BY_PRODUCT_API +
        `${id}`,
      "GET",
      null,
      false
    ).then(handleResponse);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}
async function fetchProductVariantRating(id) {
  store.dispatch("appState/startLoading");
  try {
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL +
        FETCH_POST_PUT_PRODUCT_VARIANTS_API +
        `/${id}/Rating`,
      "GET",
      null,
      false
    ).then(handleResponse);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}
async function assignContentToProductVariant(content) {
  store.dispatch("appState/startLoading");
  // productVariant.productId = id;
  var requestBody = JSON.stringify(content);
  try {
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + ASSIGN_CONTENT_TO_PRODUCT_VARIANTS_API,
      "POST",
      requestBody,
      false
    ).then(handleResponse);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}
async function PasteProductVariantItems(content) {
  store.dispatch("appState/startLoading");
  // productVariant.productId = id;
  var requestBody = JSON.stringify(content);
  try {
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + PASTE_PRODUCT_VARIANT_ITEMS,
      "POST",
      requestBody,
      false
    ).then(handleResponse);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}
async function PasteProductVariants(content) {
  store.dispatch("appState/startLoading");
  // productVariant.productId = id;
  var requestBody = JSON.stringify(content);
  try {
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + PASTE_PRODUCT_VARIANTS,
      "POST",
      requestBody,
      false
    ).then(handleResponse);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}
async function createProductVariant(productVariant) {
  store.dispatch("appState/startLoading");
  // productVariant.productId = id;
  var requestBody = JSON.stringify(productVariant);
  try {
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_POST_PUT_PRODUCT_VARIANTS_API,
      "POST",
      requestBody,
      false
    ).then(handleResponse);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}
async function createProductVariantFeedback(productVariantFeedbackData) {
  store.dispatch("appState/startLoading");
  try {
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + PRODUCT_VARIANT_FEEDBACK_RATING,
      "POST",
      productVariantFeedbackData,
      false
    ).then(handleResponse);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}
async function updateProductVariant(productVariant) {
  store.dispatch("appState/startLoading");
  // productVariant.productId = id;
  var requestBody = JSON.stringify(productVariant);
  try {
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + FETCH_POST_PUT_PRODUCT_VARIANTS_API,
      "PUT",
      requestBody,
      false
    ).then(handleResponse);
    store.dispatch("appState/stopLoading");
    return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}
async function deleteProductVariant(id) {
  store.dispatch("appState/startLoading");
  try {
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL +
        FETCH_POST_PUT_PRODUCT_VARIANTS_API +
        `?productVariantID=${id}`,
      "DELETE",
      null,
      false
    );
    store.dispatch("appState/stopLoading");
    // return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}
async function deleteStudentFeedback(ratingId) {
  store.dispatch("appState/startLoading");
  const url = store.state.appState.apiURL + PUT_RATING_API + `?RatingID=${ratingId}`;
  
  const response = await ApiHelpers.makeAPICall(
    url,
    "DELETE",
    null,
    false
  );
  return response;
}
async function editProductVariantSequence({ productVariant, id }) {
  store.dispatch("appState/startLoading");
  try {
    await ApiHelpers.makeAPICall(
      store.state.appState.apiURL + PATCH_PRODUCT_VARIANT_SEQUENCE + `/${id}`,
      "PATCH",
      JSON.stringify(productVariant),
      false
    ).then(handleResponse);
    store.dispatch("appState/stopLoading");
    // return body;
  } finally {
    store.dispatch("appState/stopLoading");
  }
}
async function unenrollAllUsersCourses(emails) {

  var _response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + UNENROLL_USER_COURSE_SUBSCRIPTION,
    "PATCH",
    JSON.stringify({ UserEmails: (emails || []).join(',') }),
    false
  ).then((response) => {
    if (!response.ok) {
      return handleResponse(response);
    } else {
      return { ok: true };
    }
  });
  return _response;
}
async function enrollUsersSpecificCourses(data) {
  var _response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + ENROLL_USERS_COURSES_SUBSCRIPTION,
    "POST",
    JSON.stringify(data),
    false
  ).then((response) => {
    if (!response.ok) {
      return handleResponse(response);
    } else {
      return { ok: true };
    }
  });
  return _response;
}
async function updateUsersSpecificCoursesSubscription(data) {
  var _response = await ApiHelpers.makeAPICall(
    store.state.appState.apiURL + UNENROLL_USERS_COURSES_SUBSCRIPTION,
    "PATCH",
    JSON.stringify(data),
    false
  ).then((response) => {
    if (!response.ok) {
      return handleResponse(response);
    } else {
      return { ok: true };
    }
  });
  return _response;
}
async function fetchProductVariantProgress(id=null) {
  try {
    let body = await ApiHelpers.makeAPICall(
      store.state.appState.apiURL +
      FETCH_PRODUCT_VARIANT_PROGRESS +
        `${(id?('?productvariantId='+id):'')}`,
      "GET",
      null,
      false
    ).then(handleResponse);
    return body;
  } finally {
  }
}

function handleResponse(response) {
  console.log("handling response", response);
  if (!response.ok) {
    // if (response.status == 401) {
    // 	store.dispatch("user/regenerateUserToken");
    // 	return;
    // }
    return Promise.reject(response);
  } else {
    return response.json();
  }
}
