<template>
	<div class="body" >
		<div :class="{'body-content': true, 'd-none': !isTableOfContentVisible}">
			<b-tabs v-model="currentActiveTab" nav-class="main-tabs nav nav-tabs" nav-wrapper-class="main-tabs-row"
				content-class="eb-content-wrapper">
				<b-tab @click="changeTab('tableofcontents')" title="Table of Contents" :active="currentActiveTab==0">
					<ProductContentChapters class="tab-body"></ProductContentChapters>
				</b-tab>

				<b-tab @click="changeTab('highlights')" title="Highlights" :active="currentActiveTab ==1">
					<ProductContentChaptersHighlights class="tab-body" :active="currentActiveTab == 1" 
					    :productVariantID="productVariantID" />
				</b-tab>

				<b-tab @click="changeTab('notes')" title="Notes" :active="currentActiveTab == 2">
					<ProductContentChaptersNotes
					class="tab-body"
					:active="currentActiveTab == 2" 
					:productVariantID="productVariantID" />
				</b-tab>
			</b-tabs>

			<div class="close-icon" @click="closeTableOfContent">
               	 <i class="fa fa-times"></i> 
            </div>
		</div>
	</div>

</template>

<script>
import { mapState, mapActions } from "vuex";
import { ProductVariantStatus } from "../../constants/productVariant";
import ProductContentChapters from "../ProductContentDashboard/ProductContentChapters.vue";
import ProductContentClasses from "../ProductContentDashboard/ProductContentClasses.vue"
import ProductContentChaptersConfidence from "../ProductContentDashboard/ProductContentChaptersConfidence.vue"
import ProductContentChaptersBookmarks from "../ProductContentDashboard/ProductContentChaptersBookmarks.vue"
import ProductContentChaptersHighlights from "../ProductContentDashboard/ProductContentChaptersHighlights.vue"
import ProductContentChaptersNotes from "../ProductContentDashboard/ProductContentChaptersNotes.vue";

export default {
	name: "EbookContentSection",
	data() {
		return {
			productVariantID: null,
			selectedProductVariant: null,
			currentActiveTab: 0
		};
	},
	components: {
    ProductContentChapters,
    ProductContentClasses,
    ProductContentChaptersConfidence,
    ProductContentChaptersBookmarks,
    ProductContentChaptersHighlights,
    ProductContentChaptersNotes
},
	methods: {
		...mapActions("productVariant", ["getProductVariantById"]),
		getProductVariant: function () {
			var myProducts =
				(this.user && this.user.productVariants
					? this.user.productVariants.filter(
						(productVariant) =>
							productVariant.status != ProductVariantStatus.DRAFT
					)
					: []);
			this.selectedProductVariant = myProducts.find(x => x.id == this.productVariantID);
			if(this.selectedProductVariant)
			{
				window.document.title=this.content[this.contentId].title;					
			}
			
		},
		changeTab: function (tab) {
			var oldRouteQuery = this.$route.query;
			this.$router.push({ query: { tab } }).catch(() => { });
			var selfRouteQuery = { tab };
			Object.keys(oldRouteQuery).forEach(query => {
				if (query.toLowerCase() != 'tab')
					selfRouteQuery[query] = oldRouteQuery[query];
			});
			this.$router.push({ query: selfRouteQuery }).catch(() => { });

		},

		closeTableOfContent() {
			var params = { ...this.$route.params };
			var queries = { ...this.$route.query };
			queries["tableOfContent"] = false;
			this.$router
				.push({ params, query: queries })
				.catch((error) => { });
		},
	},
	computed: {
		...mapState("user", ["user"]),
		...mapState("productVariant", ["productVariants"]),
		
		isTableOfContentVisible() {
			return (this.$route.query.tableOfContent + "") === 'true';
		},

	},
	watch: {
		productVariants:function(){
			if (this.productVariantID && this.productVariant && this.productVariants.items[this.productVariantID]) {
				this.selectedProductVariant=  this.productVariants.items[this.productVariantID];
			}
		},
		selectedProductVariant:function(){
			if(this.selectedProductVariant)
			{
				window.document.title=this.selectedProductVariant.title;					
			}
		},
		$route(to, from) {
			if (this.$route.query.tab) {
				if (this.$route.query.tab.toLowerCase() == "tableofcontents") {
					this.currentActiveTab = 0;
				}
				// else if (this.$route.query.tab.toLowerCase() == "confidencelevel") {
				// 	this.currentActiveTab = 1;
				// }
				else if (this.$route.query.tab.toLowerCase() == "notes") {
					this.currentActiveTab = 2;
				}
				// else if (this.$route.query.tab.toLowerCase() == "bookmarks") {
				// 	this.currentActiveTab = 2;
				// }
				else if (this.$route.query.tab.toLowerCase() == "highlights") {
					this.currentActiveTab = 1;
				}
			}
			
			if (this.$route.params.id && this.productVariantID != this.$route.params.id) {
				this.productVariantID = this.$route.params.id;
				this.getProductVariant();
			}

		}
	},
	created() {
		if (this.$route.params.id && this.productVariantID != this.$route.params.id) {
			this.productVariantID = this.$route.params.id;
			this.getProductVariant();
		}
		if (this.$route.query.tab) {
			if (this.$route.query.tab.toLowerCase() == "tableofcontents") {
				this.currentActiveTab = 0;
			}
			// else if (this.$route.query.tab.toLowerCase() == "confidencelevel") {
			// 	this.currentActiveTab = 1;
			// }
			else if (this.$route.query.tab.toLowerCase() == "notes") {
				this.currentActiveTab = 2;
			}
			// else if (this.$route.query.tab.toLowerCase() == "bookmarks") {
			// 	this.currentActiveTab = 2;
			// }
			else if (this.$route.query.tab.toLowerCase() == "highlights") {
				this.currentActiveTab = 1;
			}

		}
	},
	mounted() {
	}
};
</script>

<style scoped>

.ebook-digital .body-content {
	position: relative; 
}

.ebook-digital .tab-body {
  max-height: calc(100vh - var(--header-height) - 60px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.ebook-digital .close-icon {
    position: absolute;
    top: 15px;
    right: 20px; 
    cursor: pointer;
    color: #333; 
    z-index: 8; 
}

.ebook-digital .close-icon i {
    font-size: 18px; 
}

.sidebar-nav .nav-group {
	position: relative;
	transition: background .15s ease-in-out;
}

.sidebar-nav {
	position: relative;
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 0;
	margin-bottom: 0;
	overflow-x: hidden;
	overflow-y: auto;
	list-style: none;
	padding: 0 20px;
}

.sidebar-nav .nav-item {
	margin-bottom: 4px;
}



.sidebar-nav .active .nav-link,
.sidebar-nav .nav-link:hover {
	color: var(--primary-color);
}

.sidebar-nav .active .nav-link {
	border-color: var(--primary-color);
}

.nav-sidebar .nav-link p {
	display: inline;
	margin: 0;
	white-space: normal;
}

.sidebar-nav .nav-item .nav-icon {
	margin-left: 0.05rem;
	font-size: 1rem;
	margin-right: 0.2rem;
	text-align: center;
	width: 1.6rem;
	color: var(--icon-color);
}

.sidebar-nav .active .nav-icon {
	color: var(--primary-color);
}

.sidebar-progress {
	padding: 20px;
	font-size: 13px;
	border-bottom: solid 1px var(--border-color);
}

.progress-val {
	font-weight: 600;
}

.sidebar-progress .progress {
	height: 7px;
	margin: 0px;
}


.sidebar-lecture {
	padding: 10px 0 0;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
	margin-bottom: 15px;
}

.sidebar-lecture .current-course {
	white-space: normal;
	background-position: right 20px top 10px;
	padding: 5px 20px;
	border: none;
	padding-right: 40px;
	cursor: pointer;
	box-shadow: none !important;
}

.sidebar-lecture .select2-container--default .select2-selection--single {
	border-radius: 6px;
	border: none;
	height: auto;
}

.sidebar-lecture .select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 22px;
	padding-left: 0;
	font-size: 14px;
	line-height: 20px;
	padding-left: 20px;
	font-size: 13px;
	white-space: normal;
	overflow: auto;
	padding-bottom: 10px;
	padding-right: 25px;
}

.select2-results__option {
	font-size: 13px;
}

.sidebar-lecture-dd .select2-dropdown {
	border-color: var(--border-color);
	border-radius: 0;
}

.sidebar-lecture .select2-container--default .select2-selection--single .select2-selection__arrow {
	right: 5px;
}

.sidebar-lecture .select2-container--default .select2-selection--single .select2-selection__arrow b {
	background-image: url("../../assets/images/arrow-bottom.svg");
	background-repeat: no-repeat;
	border: none;
	right: 15px;
	width: 14px;
	height: 14px;
}

.vs__clear {
	display: none;
}

.select-head {
	color: var(--primary-color);
	font-weight: bold;
}

@media screen and (max-width: 768px) {
.ebook-digital .tab-body {
  overflow-y: scroll;
}
}


</style>
