<template>
  <div class="ai-assistant">
    <div class="header">
      <h3 class="modal-heading">AI Assistant</h3>
      <a href="#" @click="onPrompt()" class="float-icon ai-icon">
        <img src="../../assets/images/artificial-intelligence-ai-icon.svg">
      </a>
      <a href="#" @click="onClose()" class="float-icon close-icon">
        <img src="@/assets/images/cancel-icon.svg">
      </a>
    </div>


    <div :class="['message-container']" ref="messageContainer">
      <div v-for="(message, index) in messages" :key="index" class="message" :class="{'bot-message': message.sender.toLowerCase() === 'bot', 'user-message': message.sender.toLowerCase() === 'user' }">

       <div v-if="message.sender.toLowerCase()=='user'" class="message-inner"> 
          <!-- if user has image -->
          <div class="ai-message-icon border-0" v-if="user"><img :src="user.profilePicture"></div> 
          <div class="ai-message-icon border-0 " style="background: black; color:white" v-else><span>{{ user.firstName[0] }}</span></div> 

          <!-- if user has no image -->
          <!-- <div class="ai-message-icon">A</div> -->
          <!-- <div class="ai-message-icon">{{ message.sender+':' }}</div>           -->
          
          <div class="ai-message-box"><span v-html="message.text"></span></div>            
       </div>

       <div v-else-if="message.sender.toLowerCase()=='bot'" class="message-inner">
          <!-- {{ message.sender+':' }} -->
          <div class="ai-message-icon"><img src="@/assets/images/ai-icon-red.svg"></div>      
          <div class="ai-message-box"><span v-html="message.text"></span></div>
       </div>      

      </div>
      <div v-if="isLoading" class="mb-2">
        <span class="loader spinner-border m-1"></span>
      </div>
    </div>

    <div class=".ai-assistant-footer">
      <div class="input-container">
        <textarea :disabled="isLoading"  type="text" v-model="userInput" @keyup.enter="sendMessage" placeholder="Type your query.."></textarea>
        <button :disabled="isLoading" @click="sendMessage">
          <img src="@/assets/images/ai-submit-icon.svg">
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';

export default {
  name: 'AIAssistant',
  props: {
    prompt: String,
    url: String
  },
  data() {
    return {
      messages: [],
      userInput: '',
      isLoading:false
    };
  },
  watch: {
    prompt(newPrompt, oldPrompt) {
      if (newPrompt && newPrompt !== oldPrompt) {
        this.userInput+=newPrompt;
        $(() => {
          $(".input-container textarea").focus();
        });
        // this.sendMessageRequest({ sender: 'User', text: newPrompt })
      }
    }
  },
  computed:{    
		...mapState("user", ["user"]),
  },
  methods: {
    onPrompt() {
      this.$emit('onPrompt')
    },
    onClose() {
      this.$emit('onClose')
    },

    async fetchMessages() {
      debugger
      try {
        const response = await fetch(this.url);
        const newMessageString = await response.json();
        const newMessage = { sender: 'Bot', text: newMessageString }
        this.messages.push(newMessage);

      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    },
    async sendMessage() {
      if (this.userInput.trim() !== '') {
        const newMessage = { sender: 'User', text: this.userInput.trim() }
        this.sendMessageRequest(newMessage);
        
      }
    },
    async sendMessageRequest(newMessage){
      try {
        // Push the new message to the messages array
        this.messages.push(newMessage);
        
        // Clear user input and show the loading state
        this.userInput = '';
        this.isLoading = true;
        this.$nextTick(() => {
          this.scrollToLast();
        });

        // Prepare the payload
        const payload = JSON.stringify({ "prompt": newMessage.text });

        // Make the fetch request
        const response = await fetch(`${this.url}/generate`, {
          headers: { "Content-Type": "application/json" },
          method: 'POST',
          body: payload,
        });

        // Check if the response is ok
        if (!response.ok) {
          console.error('Error sending message:', response.status, response.statusText);
          this.isLoading = false;
          return;
        }

        const data = await response.json();

        // Hide loading state and add the bot's response
        this.isLoading = false;
        this.messages.push({ text: data.response.replace(/\n/g, "<br/>"), sender: 'Bot' });

        // Scroll to the last message
        this.$nextTick(() => {
          this.scrollToLast();
        });

      } catch (error) {
        console.error('Error sending message:', error);
        this.isLoading = false;
      }
    },

    scrollToLast() {
      this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight;
    }
  },
  created() {
    debugger
    this.fetchMessages();
  }
};
</script>

<style scoped>
.ai-assistant:root{
  --ai-red: #f14962;
}
.ai-assistant {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: -webkit-left;
}

.header {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10%;
  padding: 0 20px;
}

.main-content {
  flex: 1;
}

.message-container {
  overflow-y: auto;
  flex: 8;
  padding: 0 20px;
}

.message {
  margin-bottom: 15px;
}
.message-inner{
  display: flex;
  column-gap: 10px;
}
.user-message .message-inner{
  padding: 0 2px 0 36px;
}
.bot-message .message-inner{
  padding: 0 40px 0 0px;
}
.ai-message-icon{
  width: 46px;
  height: 46px;
  line-height: 46px;
  border-radius: 50%;
  border: solid 1px var(--border-color);
  background-color: white;
  text-align: center;
  flex-shrink: 0;
  font-size: 22px;
}
.bot-message .ai-message-icon{
  line-height: 43px;
}
.bot-message .ai-message-icon img{
  max-width:100%;
  width:24px;
}
.user-message .ai-message-icon img{
  border-radius: 50%;
  max-width:100%;
  width:44px;
}

.ai-message-box{
  padding: 11px 12px;
  background-color: white;
  position: relative;
}

.bot-message .ai-message-box{
  border-radius: 0 6px 6px 6px;
  border: solid 1px var(--border-color);
}
.bot-message .ai-message-box::before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 4px solid transparent;
    border-right: 5px solid var(--border-color);
    border-top: 5px solid var(--border-color);
    border-bottom: 6px solid transparent;
    left: -9px;
    top: -1px;
    z-index: 5;
}
.bot-message .ai-message-box:after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 4px solid transparent;
    border-right: 4px solid #fff;
    border-top: 4px solid #fff;
    border-bottom: 6px solid transparent;
    left: -7px;
    top: 0px;
    z-index: 6;
}


.user-message .ai-message-box{
  border-radius: 6px;
  padding: 12px;
  background-color: #f14962;
  color: white;
}
.user-message .ai-message-box{
  border-radius: 0 6px 6px 6px;
}
.user-message .ai-message-box::before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 4px solid transparent;
    border-right: 4px solid #f14962;
    border-top: 4px solid #f14962;
    border-bottom: 8px solid transparent;
    left: -6px;
    top: 0px;
}


.ai-assistant-footer {
  height: 20%;
  padding: 0 20px;
  margin-bottom: 30px;
}

.input-container {
  display: flex;
  position: relative;
  column-gap: normal;
  padding: 20px 35px 20px 20px;
}

.input-container textarea {
  flex: 1;
  padding: 8px 60px 8px 8px;
  border-radius: 4px;
  border: 1px solid #f14962;
  height: 52px;
  resize: none;
}

.input-container button {
  padding: 8px 12px;
  border: none;
  background-color: #f14962;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  margin-left: -50px;
}
.input-container button img{
  width:18px;
}

.ai-icon {
  margin-left: auto;
}

.close-icon {
  margin-left: 10px;
}
</style>