<template>
	<li class="eb-list-item" @click="handleClick()" role="button">
		<div class="eb-item-img">

			<a class="thumbnail">
				<img :src="thumbnailPath" :alt="data.product.friendlyTitle" :class="`${isThumbnailError ? '' : ''}`" @error="() => {
					isThumbnailError = true;
				}
					" />
				<div v-if="directEnrollmentProductVariants && productVariantsExpiresAt" class="centered">
					<div class="expires-date-main-div">
						<svg v-if="!isProductVariantExpired" class="clock-or-error-expires-icon" width="19" height="19"
							viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M9.59901 0.738281C11.3335 0.738494 13.0288 1.25347 14.4704 2.21797C15.9119 3.18247 17.0348 4.55311 17.6967 6.15627C18.3587 7.75943 18.5299 9.52299 18.1888 11.2236C17.8477 12.9241 17.0095 14.4852 15.7805 15.709C14.5515 16.9329 12.9868 17.7644 11.2849 18.0984C9.58285 18.4323 7.82004 18.2536 6.21969 17.5848C4.61934 16.9161 3.25347 15.7875 2.29507 14.3419C1.33668 12.8962 0.828885 11.1987 0.836011 9.46428C0.848946 7.14758 1.77751 4.93001 3.41915 3.2953C5.06079 1.66059 7.28228 0.741413 9.59901 0.738281ZM1.99901 9.49628C1.99782 10.9999 2.4427 12.4701 3.27734 13.7209C4.11198 14.9716 5.29888 15.9466 6.68784 16.5226C8.07681 17.0986 9.60541 17.2496 11.0802 16.9565C12.555 16.6635 13.9098 15.9395 14.973 14.8763C16.0363 13.8131 16.7602 12.4583 17.0533 10.9835C17.3463 9.50868 17.1953 7.98008 16.6193 6.59111C16.0434 5.20215 15.0683 4.01525 13.8176 3.18061C12.5669 2.34597 11.0967 1.90109 9.59301 1.90228C7.58148 1.91018 5.65461 2.71283 4.23233 4.1353C2.81004 5.55777 2.00765 7.48475 2.00001 9.49628"
								fill="#1A1818" />
							<path
								d="M1.99901 9.49628C1.99782 10.9999 2.4427 12.4701 3.27734 13.7209C4.11198 14.9716 5.29888 15.9466 6.68784 16.5226C8.07681 17.0986 9.60541 17.2496 11.0802 16.9565C12.555 16.6635 13.9098 15.9395 14.973 14.8763C16.0363 13.8131 16.7602 12.4583 17.0533 10.9835C17.3463 9.50868 17.1953 7.98008 16.6193 6.59111C16.0434 5.20215 15.0683 4.01525 13.8176 3.18061C12.5669 2.34597 11.0967 1.90109 9.59301 1.90228C7.58148 1.91018 5.65461 2.71283 4.23233 4.1353C2.81004 5.55777 2.00765 7.48475 2.00001 9.49628M9.59901 0.738281C11.3335 0.738494 13.0288 1.25347 14.4704 2.21797C15.9119 3.18247 17.0348 4.55311 17.6967 6.15627C18.3587 7.75943 18.5299 9.52299 18.1888 11.2236C17.8477 12.9241 17.0095 14.4852 15.7805 15.709C14.5515 16.9329 12.9869 17.7644 11.2849 18.0984C9.58285 18.4323 7.82004 18.2536 6.21969 17.5848C4.61934 16.9161 3.25347 15.7875 2.29507 14.3419C1.33668 12.8962 0.828885 11.1987 0.836011 9.46428C0.848946 7.14758 1.77751 4.93001 3.41915 3.2953C5.06079 1.66059 7.28228 0.741413 9.59901 0.738281Z"
								stroke="black" stroke-width="0.5" stroke-linecap="round" />
							<path
								d="M8.57578 11.6014C8.56478 11.4704 8.55078 11.3854 8.55078 11.3014C8.55078 9.2394 8.55078 7.17706 8.55078 5.11439C8.55078 4.62739 8.75078 4.3674 9.10778 4.3324C9.18713 4.31999 9.26816 4.32364 9.34608 4.34313C9.42399 4.36262 9.49719 4.39756 9.56136 4.44587C9.62552 4.49417 9.67933 4.55487 9.7196 4.62435C9.75987 4.69384 9.78577 4.77071 9.79578 4.85039C9.81522 4.98481 9.82325 5.12063 9.81979 5.2564C9.81979 6.78973 9.81979 8.32106 9.81979 9.85039V10.3384H10.2528C11.4528 10.3384 12.6528 10.3384 13.8458 10.3384C13.9293 10.3316 14.0133 10.3417 14.0928 10.368C14.1723 10.3944 14.2457 10.4365 14.3086 10.4918C14.3715 10.5472 14.4226 10.6146 14.4589 10.6901C14.4952 10.7657 14.5159 10.8477 14.5198 10.9314C14.5231 11.0184 14.5085 11.1052 14.4771 11.1864C14.4456 11.2676 14.3979 11.3415 14.3368 11.4036C14.2758 11.4657 14.2027 11.5146 14.122 11.5474C14.0414 11.5803 13.9548 11.5962 13.8678 11.5944C12.1148 11.6054 10.3628 11.5944 8.57378 11.5944"
								fill="#1A1818" />
							<path
								d="M8.57578 11.6014C8.56478 11.4704 8.55078 11.3854 8.55078 11.3014C8.55078 9.23939 8.55078 7.17706 8.55078 5.11439C8.55078 4.62739 8.75078 4.3674 9.10778 4.3324C9.18713 4.31999 9.26817 4.32364 9.34608 4.34313C9.42399 4.36262 9.49719 4.39756 9.56136 4.44587C9.62552 4.49417 9.67933 4.55487 9.7196 4.62435C9.75987 4.69384 9.78577 4.77071 9.79578 4.85039C9.81522 4.98481 9.82325 5.12063 9.81979 5.2564C9.81979 6.78973 9.81979 8.32106 9.81979 9.85039V10.3384H10.2528C11.4528 10.3384 12.6528 10.3384 13.8458 10.3384C13.9293 10.3316 14.0133 10.3417 14.0928 10.368C14.1723 10.3944 14.2457 10.4365 14.3086 10.4918C14.3715 10.5472 14.4226 10.6146 14.4589 10.6901C14.4952 10.7657 14.5159 10.8477 14.5198 10.9314C14.5231 11.0184 14.5085 11.1052 14.4771 11.1864C14.4456 11.2676 14.3979 11.3415 14.3368 11.4036C14.2758 11.4657 14.2027 11.5146 14.122 11.5474C14.0414 11.5803 13.9548 11.5962 13.8678 11.5944C12.1148 11.6054 10.3628 11.5944 8.57378 11.5944"
								stroke="black" stroke-width="0.5" stroke-linecap="round" />
						</svg>
						<svg v-else class="clock-or-error-expires-icon" width="17" height="14" viewBox="0 0 17 14"
							fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M8.06715 13.8901C5.91215 13.8901 3.75715 13.8841 1.60215 13.8901C1.3232 13.9158 1.04268 13.8619 0.793127 13.7346C0.543571 13.6074 0.335193 13.412 0.192148 13.1711C0.0487986 12.9255 -0.020053 12.6435 -0.00602713 12.3594C0.00799874 12.0753 0.104297 11.8015 0.271148 11.5711C2.43381 7.99046 4.59248 4.41313 6.74715 0.839127C6.86436 0.588486 7.05061 0.376463 7.28405 0.227924C7.5175 0.0793844 7.78845 0.000488281 8.06515 0.000488281C8.34184 0.000488281 8.6128 0.0793844 8.84624 0.227924C9.07969 0.376463 9.26593 0.588486 9.38315 0.839127C11.5431 4.41379 13.7008 7.98946 15.8561 11.5661C16.0225 11.7948 16.1193 12.0666 16.1351 12.3489C16.151 12.6313 16.0851 12.9122 15.9454 13.158C15.8057 13.4039 15.5981 13.6042 15.3474 13.7351C15.0968 13.8661 14.8137 13.9219 14.5321 13.8961C12.3771 13.8861 10.2221 13.8961 8.06715 13.8961"
								fill="#FF0000" />
							<path
								d="M7.21584 5.1084C7.21584 4.8534 7.21584 4.5974 7.21584 4.3424C7.22284 3.8424 7.51585 3.5744 8.05585 3.5694C8.59585 3.5644 8.91984 3.84241 8.90884 4.32641C8.88384 5.44941 8.83885 6.5714 8.80185 7.6944C8.78718 8.14107 8.77184 8.58741 8.75584 9.03341C8.74084 9.43341 8.49384 9.6524 8.04684 9.6504C7.59984 9.6484 7.38885 9.45041 7.37385 9.05041C7.32385 7.7364 7.27385 6.42274 7.22385 5.1094H7.21484"
								fill="white" />
							<path
								d="M8.06187 12.2813C7.94368 12.2781 7.82727 12.2517 7.71928 12.2036C7.6113 12.1554 7.51385 12.0865 7.4325 12.0007C7.35116 11.9149 7.28751 11.8139 7.24519 11.7035C7.20288 11.5931 7.18272 11.4755 7.18587 11.3573C7.18902 11.2391 7.21542 11.1227 7.26356 11.0147C7.31171 10.9067 7.38064 10.8093 7.46645 10.7279C7.55225 10.6466 7.65323 10.5829 7.76363 10.5406C7.87402 10.4983 7.99168 10.4781 8.10987 10.4813C8.34857 10.4876 8.57495 10.5886 8.73923 10.7619C8.90352 10.9351 8.99224 11.1666 8.98587 11.4053C8.9795 11.644 8.87858 11.8704 8.7053 12.0347C8.53202 12.1989 8.30056 12.2876 8.06187 12.2813Z"
								fill="white" />
						</svg>


						<div class="expiry-date-text">
							Expires At:
						</div>
						<div class="actual-expiry-date">
							{{ formattedDate }}
						</div>
						<!-- <h1>{{ data.enrollmentType }}</h1> -->
					</div>
				</div>

			</a>
		</div>
		<div class="eb-item-desc">
			<h4 :id="'sb-batch-' + data.id" class="eb-item-title">{{ data.product.title }} - {{ data.title }}</h4>
			<b-tooltip :target="'sb-batch-' + data.id" triggers="hover">
				<b>{{ data.product.title }}</b><br />
				{{ data.title }}
			</b-tooltip>
			<div class="eb-item-instructor d-flex" v-if="productTrainers(data.properties.trainers).length > 0">

				<div class="tutor-images">
					<div class="tutor-image" v-for="t in productTrainers(data.properties.trainers).slice(0, 2)" :key="t.id">
						<img v-if="t.profilePicture" style="object-fit: contain;" class="ls-is-cached lazyloaded"
							data-src="../../assets/images/user_img_placeholder.jpg" alt="" :src="t.profilePicture" />
						<!-- <div > -->
						<div v-else class="user-avatar">
							<span>{{ t.firstName[0] }}</span>
						</div>
						<!-- </div> -->
					</div>

				</div>
				<span>
					{{ productTrainers(data.properties.trainers)[0].firstName }}
					{{ productTrainers(data.properties.trainers)[0].lastName }}
					<span v-if="productTrainers(data.properties.trainers).length > 1">{{
						`+${productTrainers(data.properties.trainers).length - 1}
											trainer${productTrainers(data.properties.trainers).length > 2 ? "s" : ""
							}`
					}}
					</span>
				</span>
			</div>
			<div v-else style="min-height: 40px;"></div>
			<div class="eb-item-rating-bar">
				<div class="eb-rating-stars">
					<span
						:class="'star ' + (data.ratings.value && parseInt(data.ratings.value) >= 1 ? 'checked' : '')">★</span>
					<span
						:class="'star ' + (data.ratings.value && parseInt(data.ratings.value) >= 2 ? 'checked' : '')">★</span>
					<span
						:class="'star ' + (data.ratings.value && parseInt(data.ratings.value) >= 3 ? 'checked' : '')">★</span>
					<span
						:class="'star ' + (data.ratings.value && parseInt(data.ratings.value) >= 4 ? 'checked' : '')">★</span>
					<span
						:class="'star ' + (data.ratings.value && parseInt(data.ratings.value) >= 5 ? 'checked' : '')">★</span>

				</div>
				<div class="eb-rating-counts">
					<span class="eb-rating-avg font-bold">{{ data.ratings.value ? data.ratings.value : 0 }}.0</span>
					<span class="eb-rating-reviews">({{ data.ratings.numberOfRatings }})</span>
				</div>
			</div>
			<div v-if="isEbookTheme || isEbookDigitalTheme" class="eb-item-progress">
				<div class="eb-item-progress-label mb-1">
					<span id="progressVal">{{ progress }}</span>%
				</div>
				<div class="progress mb-1">
					<div class="progress-bar" role="progressbar" :style="`width: ${progress}%`" aria-valuenow="25"
						aria-valuemin="0" aria-valuemax="100"></div>
				</div>
			</div>
		</div>
	</li>
</template>
<script>
import Swal from "sweetalert2";
import formatter from "../../helpers/FormatHelpers";
import { mapActions, mapState } from "vuex";
import { parseForURL } from "../../helpers/FormatHelpers";
import {
	ProductVariantStatus,
	ProductVariantSubscriptionStatus,
} from "../../constants/productVariant";

export default {
	name: "UserDashboardProductsListItem",
	data() {
		return {
			// trainersList: null,
			// purchased: false,
			isThumbnailError: false,
			ProductVariantStatus,
			ProductVariantSubscriptionStatus,
		};
	},
	props: {
		data: Object,
		// purchased: Boolean,
		isAdmin: Boolean,
	},
	methods: {
		...mapActions("user", ["fetchTrainersList"]),
		parseCurrency(amount, currency, decimal) {
			return formatter.toCurrencyString(amount, currency, decimal);
		},
		handleClick(e) {
			if (
				this.isProductVariantBlocked ||
				this.isProductVariantEnded ||
				this.isProductVariantExpired
			) {

				Swal.fire({
					title: this.isProductVariantBlocked
						? "Course Blocked!"
						: this.isProductVariantEnded
							? "Course ended!"
							: this.isProductVariantExpired && this.data.enrollmentType && this.data.enrollmentType.toLowerCase() == "directenrollment"
								? "Course Access Expired!"
								: this.isProductVariantExpired
									? "Payments due!"
									: "",
					text: this.isProductVariantBlocked
						? "Your course has been blocked. Please contact admin for more information"
						: this.isProductVariantEnded
							? "This course has ended, you can no longer view its contents"
							: this.isProductVariantExpired && this.data.enrollmentType && this.data.enrollmentType.toLowerCase() == "directenrollment"
								? "Your course access has been expired. Please contact admin for more information "
								: this.isProductVariantExpired
									? "You have pending unpaid invoices for this course. Please clear your dues to access this course "
									: "",
					icon: "error",
					type: "error",
					preConfirm: this.isProductVariantExpired && this.data.enrollmentType.toLowerCase() == "pricingplan"
						? () => {
							this.$router.push("/user/invoices");
						}
						: null,
					confirmButtonText:
						this.isProductVariantExpired && this.data.enrollmentType.toLowerCase() == "pricingplan"
							? "View Invoices"
							: "OK",
					showCancelButton: this.isProductVariantExpired,
				});
				return;
			}
	
			this.$router.push(this.productVariantURL);
		},
		productTrainers(trainers) {
			if (trainers) {
				var trainerList = JSON.parse(trainers);
				if (trainerList && trainerList.length > 0) {
					var self = this;
					return trainerList.map(function (val, ind, arr) {
						return self.trainers ? self.trainers.find(x => x.id == val) : null;
					}).filter(x => x);
				}
			}
			return [];
		},

		validateThemeRoute(themeName){
			console.log("Theme Name", themeName)
			return this.purchased && this.data && this.data.properties && this.data.properties.contentViewTheme && this.data.properties.contentViewTheme.toLowerCase() === themeName.toLowerCase();
		}
	},
	created() {
	},
	computed: {
		...mapState("user", ["user", "trainers"]),
		...mapState("productVariant", ["productVariantsProgress"]),

		purchased() {
			if (
				this.user &&
				((this.user.type && this.user.type.toLowerCase() == "student") ||
					(this.user.role && this.user.role.toLowerCase() == "student")) &&
				this.user.productVariants.length > 0
			) {
				var found = this.user.productVariants.find((p) => p.id == this.data.id);
				return found ? true : false;
			}
		},

		getProductDetailsUrl() {
			if (this.validateThemeRoute('ebookdigital')) {
				return "/ebook/dashboard/chapters/";
			} else if (this.validateThemeRoute('ebook')) {
				return "/product/dashboard/chapters/";
			} else if (this.purchased) {
				return "/product/content/";
			}else {
				return "/product/details/";
			}
		},
		progress() {
			if (this.productVariantsProgress && this.productVariantsProgress[this.data.id]) {
				var progressOB = this.productVariantsProgress[this.data.id];
				return Math.round((progressOB.completedTotalContent / progressOB.totalContent) * 100);
			}
			return 0;
		},
		thumbnailPath() {
			if (this.isThumbnailError) {
				console.log("thumbnail error");
				return require(`@/assets/images/image-error.png`);
			} else {
				if (this.data.thumbnailPath) {
					var path = !this.data.thumbnailPath.includes("http")
						? require(`@/${this.data.product.thumbnailPath}`)
						: this.data.thumbnailPath;
					return path;
				} else return require(`@/${this.data.product.thumbnailPath}`);
			}
		},
		productTrainersList() {
			var trainerList = [];
			if (
				this.data.properties &&
				this.data.properties.trainers &&
				this.trainers
			) {
				var list = JSON.parse(this.data.properties.trainers);
				// console.log(list);
				list.forEach((trainerID) => {
					var found = this.trainers.find((t) => t.id == trainerID);
					if (found) {
						trainerList.push(found);
					}
				});
			}
			// console.log(trainerList);
			return trainerList;
		},
		productVariantURL() {
			return this.isAdmin
				? "course/" + this.data.id + "/content"
				: this.getProductDetailsUrl +
				this.data.id;
		},
		isProductVariantBlocked() {
			return (
				this.data.subscriptionStatus == ProductVariantSubscriptionStatus.BLOCKED
			);
		},
		isProductVariantEnded() {
			return this.data.endDate
				? new Date(this.data.endDate) < new Date()
				: false;
		},
		isProductVariantExpired() {
			var endDate = this.data.endDate;
			var expiresAt = this.data.expiresAt;	

			var date ;
			if(expiresAt)
			{
				date = new Date(expiresAt);
			}
			if((endDate && expiresAt && new Date(endDate)<new Date(expiresAt)) || (!expiresAt && endDate))
			{
				date = new Date(endDate);
			}

			return date
				? date < new Date()
				: false;
		},

		extraProperties() {
			return this.data && this.data.properties ? this.data.properties : null;
		},
		contentViewTheme() {
			const properties = this.extraProperties;
			return properties ? properties.contentViewTheme : null;
		},
		isEbookTheme() {
			const contentViewTheme = this.contentViewTheme;
			return contentViewTheme && contentViewTheme.toLowerCase() === "ebook";
		},
		isEbookDigitalTheme() {
			const contentViewTheme = this.contentViewTheme;
			return contentViewTheme && contentViewTheme.toLowerCase() === "ebookdigital";
		},

		formattedDate: function () {

			var endDate = this.data.endDate;
			var expiresAt = this.data.expiresAt;			
			var date = new Date(expiresAt);
			if((endDate && expiresAt && new Date(endDate)<new Date(expiresAt)) || (!expiresAt && endDate))
			{
				date = new Date(endDate);
			}
			const dateString = date.toLocaleDateString();
			const updatedDate = new Date(dateString);

			const day = updatedDate.getDate();
			const month = updatedDate.getMonth() + 1;
			const year = updatedDate.getFullYear();
			return `${day}/${month}/${year}`;
		},
		directEnrollmentProductVariants() {
			return this.data.enrollmentType.toLowerCase() === "directenrollment";
		},
		productVariantsExpiresAt() {
			return this.data.expiresAt != null;
		},

	},
};
</script>

<style scoped>
.tutor-images {
	display: flex;
	flex-direction: row;
}

.tutor-image:first-of-type {
	margin: 0;
}

.tutor-image img {
	margin: 0;
}

.tutor-image {
	margin-left: -0.55rem;
	margin-right: 0;
}

.tutor-image .user-avatar {
	border: 1px solid #fff;
	flex-shrink: 0;
}

.tutor-image:last-of-type .user-avatar {
	margin-right: 0;
}

.tutor-image .user-avatar.extra {
	background-color: #666666;
	color: #ffffff;
	padding: 0 !important;
}

.tutor-images {
	margin-right: 10px;
}

.eb-list-item .eb-item-instructor img {
	margin-right: 0px;
}

/* For Thumbnail STack item */
.eb-item-img .thumbnail {
	position: relative;
}

.centered {
	position: absolute;
	top: 20%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.expires-date-main-div {
	width: 186px;
	height: 31px;
	top: 2843px;
	left: 30543.05px;
	background-color: #FFFFFF;
	border-radius: 15px;
}

.clock-or-error-expires-icon {
	width: 20px;
	height: 20px;
	position: absolute;
	top: 5px;
	left: 5px;
	border-radius: 8px;
}

.expiry-date-text {
	color: #000;
	font-family: Circular Std;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	position: absolute;
	top: 7px;
	left: 28px;
}

.actual-expiry-date {
	color: #F00;
	font-family: Circular Std;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	position: absolute;
	top: 7px;
	left: 111px;
}</style>
